import React from 'react';
import { Input, Slider, ToggleContainer } from './style';

type ToggleProps = {
  value: boolean
  onChange: (value: boolean) => void
}
const Toggle = ({ value, onChange }: ToggleProps) => {
  const toggleCheck = () => onChange(!value);

  return (
    <ToggleContainer>
      <Input type="checkbox" checked={value} onChange={toggleCheck} />
      <Slider onClick={toggleCheck} />
    </ToggleContainer>
  );
};

export default Toggle;
