import { useCallback, useMemo, useState } from "react";
import { useConnectors } from "wagmi";
import { utils } from "ethers";
import { coinbaseWallet, metaMask, walletConnect } from "wagmi/connectors";

import {
  LogoCoinbase,
  LogoMetamask,
  LogoWalletConnect,
} from "../../../../assets/images/iconComponents";
import { PROJECT_NAME } from "../../../../config";
import { getWalletIcon, getWalletName, HEADER_MODAL_STATUS, STATIC_URLS, useWallet } from "../../../../utils";
import { TextLink } from "../../../../utils/styles/text";

import * as Styles from "./style";
import { useAppDispatch } from "../../../../hooks";
import { globalSetHeaderModal } from "../../../../store";
import { useAnalytics } from "../../../../hooks/useAnalytics";
import { setImpersonatedAccounts } from "../../../../utils/impersonator";
import type { Address } from "viem";

const WalletOptions = () => {
  const { track } = useAnalytics();
  const { connect } = useWallet();
  const dispatch = useAppDispatch();
  const connectors = useConnectors();

  const [impersonatedAccount, setImpersonatedAccount] = useState<string>('');
  const injectedConnectors = useMemo(() => connectors.filter(c => c.type === "injected" && c.id !== "injected"),[connectors]);
  const hasInjectedConnector = useCallback((name: string) => injectedConnectors.some((c) => c.name.toLowerCase().includes(name.toLowerCase())),[injectedConnectors]);
  const impersonator = connectors.find(i => i.id === 'impersonator')

  const handleWalletConnect = useCallback(async (walletType: string) => {
    try {
      track('start-connecting-wallet', { walletType });
      await connect(walletType);
      dispatch(globalSetHeaderModal(HEADER_MODAL_STATUS.closed))
    } catch (e) {
      console.error(e)
    }
  }, [connect, dispatch, track]);

  const handleImpersonatedAccount = useCallback(() => {
    if (impersonatedAccount && utils.isAddress(impersonatedAccount) && impersonator) {
      setImpersonatedAccounts(impersonatedAccount as Address)
      handleWalletConnect(impersonator.id);
    }
  }, [handleWalletConnect, impersonatedAccount, impersonator])

  return (
    <Styles.WalletOptionsContainer data-testid="walletOptions">
      <Styles.WalletHeader>Connect a Wallet</Styles.WalletHeader>
      <Styles.WalletSubHeader>
        Please choose your wallet from the options below.
      </Styles.WalletSubHeader>
      <Styles.WalletOptionsList>
        {injectedConnectors.map((connector) => (
          <Styles.WalletOptionsListItem
            key={connector.id}
            onClick={() => handleWalletConnect(connector.id)}
          >
            {getWalletName(connector.id)}
            <Styles.WalletLogo>
              {getWalletIcon(connector.id)}
            </Styles.WalletLogo>
          </Styles.WalletOptionsListItem>
        ))}
        {!hasInjectedConnector('metamask') && (
          <Styles.WalletOptionsListItem
            onClick={() => handleWalletConnect(metaMask.type)}
          >
            Metamask
            <Styles.WalletLogo>
              <LogoMetamask />
            </Styles.WalletLogo>
          </Styles.WalletOptionsListItem>
        )}
        <Styles.WalletOptionsListItem
          onClick={() => handleWalletConnect(walletConnect.type)}
        >
          Wallet Connect
          <Styles.WalletLogo>
            <LogoWalletConnect />
          </Styles.WalletLogo>
        </Styles.WalletOptionsListItem>
        {!hasInjectedConnector('coinbase') && (
          <Styles.WalletOptionsListItem
            onClick={() => handleWalletConnect(coinbaseWallet.type)}
          >
            Coinbase
            <Styles.WalletLogo>
              <LogoCoinbase />
            </Styles.WalletLogo>
          </Styles.WalletOptionsListItem>
        )}
        {impersonator && (
          <Styles.WalletOptionsListItem onClick={handleImpersonatedAccount}>
            <Styles.ImpersonatorInput
              $error={Boolean(impersonatedAccount) && !utils.isAddress(impersonatedAccount)}
              placeholder="Ethereum address (read only)"
              type="text"
              value={impersonatedAccount}
              onChange={e => setImpersonatedAccount(e.target.value)}
              onClick={e => e.stopPropagation()}
              onKeyDown={e => e.key === 'Enter' && handleImpersonatedAccount()}
            />
            <Styles.WalletLogo>{getWalletIcon(impersonator.id)}</Styles.WalletLogo>
          </Styles.WalletOptionsListItem>
        )}
      </Styles.WalletOptionsList>
      <Styles.CheckBoxContainer>
        <Styles.CheckBoxContainerText>
          By connecting, I accept the {PROJECT_NAME}{" "}
          <TextLink href={STATIC_URLS.tandc} target="_blank" rel="noreferrer noopener">Terms and Conditions</TextLink>{" "}
        </Styles.CheckBoxContainerText>
      </Styles.CheckBoxContainer>
    </Styles.WalletOptionsContainer>
  );
};

export default WalletOptions;
