import { createAsyncThunk } from "@reduxjs/toolkit";
import { userDelete, userVaultClear } from "../../../store";

export const disconnectUser = createAsyncThunk(
  "user/disconnect",
  async (data, thunkApi) => {
    try {
      thunkApi.dispatch(userDelete())
      thunkApi.dispatch(userVaultClear())
    } catch (e: any) {
      return thunkApi.rejectWithValue(e);
    }
  }
);
