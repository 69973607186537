import {
  ThemeStatus100,
  ThemeStatus200,
  ThemeStatus300,
  ThemeStatus400,
  ThemeStatus500,
  ThemeStatus600,
} from "../../../../../utils";

const BASE_SCALE = 100;

export const getChartValues = (
  borrowLimit: number,
  liquidation: number,
  balance: number
) => {
  if (!balance || !liquidation || balance <= 0 || borrowLimit <= 0) {
    return [0, 0, 0];
  }

  const overallPercentage = (balance / liquidation) * BASE_SCALE;
  const borrowingLimitLiquidationRatio =
    (borrowLimit / liquidation) * BASE_SCALE;
  const liquidationPart = BASE_SCALE - borrowingLimitLiquidationRatio;

  const borrowLimitPercentage =
    (overallPercentage / borrowingLimitLiquidationRatio) * BASE_SCALE;
  const liquidationPercentage =
    ((overallPercentage - borrowingLimitLiquidationRatio) / liquidationPart) *
    BASE_SCALE;

  return [borrowLimitPercentage, liquidationPercentage, overallPercentage];
};

const colors: Record<string | number, string> = {
  30: ThemeStatus100,
  45: ThemeStatus200,
  60: ThemeStatus300,
  75: ThemeStatus400,
  80: ThemeStatus500,
  default: ThemeStatus600,
};

export const getChartColor = (overallPercentage: number): [string, string] => {
  for (const key in colors) {
    if (overallPercentage <= Number(key)) {
      return [colors[key], colors[key]];
    }
  }
  return [colors["80"], colors.default];
};

export const getLiquidationAmount = (
  loan: number,
  liquidationThreshold: number,
  collateralInETH: number
): number => {
  if (collateralInETH === 0 || liquidationThreshold === 0) {
    return 0;
  }
  return loan / (liquidationThreshold * collateralInETH)
};
