import { createAsyncThunk } from "@reduxjs/toolkit";
import { optInUpdate } from "../../../store";
import { userOptInTimestamp } from "../../../config";
import { OptInStatus } from "./index";

export const optIn = createAsyncThunk(
  "user/optin",
  async (data, thunkApi) => {
    try {
      const status: OptInStatus = {
        status: true,
        optInTimestamp: Date.now()
      };
      localStorage.setItem(userOptInTimestamp, JSON.stringify(status));
      thunkApi.dispatch(optInUpdate(status));
    } catch (e: any) {
      return thunkApi.rejectWithValue(e);
    }
  }
);

export const optOut = createAsyncThunk(
  "user/optout",
  async (data, thunkApi) => {
    try {
      const status: OptInStatus = {
        status: false,
        optOutTimestamp: Date.now()
      };
      localStorage.setItem(userOptInTimestamp, JSON.stringify(status));
      thunkApi.dispatch(optInUpdate(status));
    } catch (e: any) {
      return thunkApi.rejectWithValue(e);
    }
  }
);
