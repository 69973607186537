import { useContext, useMemo } from "react";
import AnalyticsContext from "./analytics.context";
import { CombinedAnalytics, DoNotTrack } from "./analytics";
import { useAppSelector } from "../useAppSelector";
import { optIn, optOut } from "../../store/user/actions"
import { useAppDispatch } from "../useAppDispatch";
import { HotjarAnalytics, MixpanelAnalytics, SentryAnalytics } from "./adapters";

/**
 * This hook is meant to be agnostic of the analytics tool we use.
 * The current implementation uses Mixpanel
 */
export const useAnalytics = () => {
  return useContext(AnalyticsContext)
};

export const useOptIn = () => {
  const dispatch = useAppDispatch();
  const analyticsOptIn = useAppSelector((state) => state.user?.analyticsOptIn);
  const isOptedIn = useMemo(() => analyticsOptIn?.status === true, [analyticsOptIn])
  const needsToOptIn = useMemo(() => analyticsOptIn === null, [analyticsOptIn])
  const analyticsInstance = useMemo(() =>
    isOptedIn
      ? new CombinedAnalytics([
          new MixpanelAnalytics(),
          new HotjarAnalytics(),
          new SentryAnalytics()
        ])
      : new DoNotTrack(),
    [isOptedIn]
  );

  return {
    analyticsInstance,
    isOptedIn,
    needsToOptIn,
    optIn: () => dispatch(optIn()),
    optOut: () => dispatch(optOut())
  }
}
