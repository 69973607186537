import styled from "styled-components";

import { AnimationFadeIn } from "./animation.styles";
import { GridVars } from "./grid.styles";
import { Text } from "./text";
import { colors, ThemeGray700, ThemePalette } from "./theme.styles";

type Color = keyof typeof colors

export const Box = styled.div`
  ${AnimationFadeIn}
  border-radius: 8px;
  background: ${ThemeGray700};
  padding: ${GridVars.padding.p3};
`;

export const BoxHeader = styled(Text)<{ color?: Color }>`
  display: flex;
  align-items: center;
  margin-bottom: ${GridVars.margin.m1};

  span {
    margin-left: ${GridVars.margin.m1};
    color: ${ThemePalette};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  img {
    margin-left: ${GridVars.margin.m1};
    position: relative;
    top: -1px;
    cursor: pointer;
  }
`;

BoxHeader.defaultProps = {
  color: 'purpleAlpha'
}

export const BoxHeaderSquare = styled.div<{ color?: Color }>`
  width: ${GridVars.margin.m1};
  height: ${GridVars.margin.m1};
  background-color: ${ThemePalette};
  position: relative;
  top: -1px;
`;


BoxHeaderSquare.defaultProps = {
  color: 'purpleAlpha'
}
