interface TransactionStatusMessagesInterface {
  header: string;
  message: string;
}

export const transactionStatusCodes: Record<
  number,
  TransactionStatusMessagesInterface
> = {
  "-32700": {
    header: "Input Error",
    message:
      "Invalid JSON was received by the server. An error occurred on the server while parsing the JSON text.",
  },
  "-32600": {
    header: "Input Error",
    message: "The JSON sent is not a valid Request object.",
  },
  "-32601": {
    header: "Input Error",
    message: "The method does not exist / is not available.",
  },
  "-32602": {
    header: "Input Error",
    message: "Invalid method parameter(s).",
  },
  "-32603": {
    header: "Internal Error",
    message: "Internal JSON-RPC error.",
  },
  "-32000": {
    header: "Input Error",
    message: "Invalid input received",
  },
  "-32001": {
    header: "Resource Error",
    message: "Resource not found.",
  },
  "-32002": {
    header: "Resource Error",
    message: "Resource unavailable.",
  },
  "-32003": {
    header: "Transaction Rejected",
    message: "User Denied Transaction Signature.",
  },
  "-32004": {
    header: "Input Error",
    message: "The method does not exist / is not available.",
  },
  "-32005": {
    header: "Request Limit",
    message: "Request limit exceeded.",
  },
  "4001": {
    header: "Transaction Rejected",
    message: "User rejected the request.",
  },
  "4100": {
    header: "Authorisation Error",
    message:
      "The requested account and/or method has not been authorized by the user.",
  },
  "4200": {
    header: "Provider Issue",
    message: "The requested method is not supported by this Ethereum provider.",
  },
  "4900": {
    header: "Provider Issue",
    message: "The provider is disconnected from all chains.",
  },
  "4901": {
    header: "Provider Issue",
    message: "The provider is disconnected from the specified chain.",
  },
};
