import { ReactElement } from "react";
import { metaMask, walletConnect, coinbaseWallet } from "wagmi/connectors";
import { FallbackProvider } from "@morpho-labs/ethers-fallback-provider/lib/FallbackProvider";
import type { Client, Chain, Transport, Account } from 'viem'
import { getConnectors, switchChain } from '@wagmi/core'
import { providers, utils, BigNumberish, constants } from 'ethers'

import { Connector } from "wagmi";

import {
  CoinbaseOption,
  MetaMaskOption,
  WalletConnectOption,
  ReadOnlyOption
} from "../assets/images/iconComponents";
import * as store from "../store";
import { NETWORKS } from "../utils";
import { wagmiConfig } from "../config";
import { LinkButton } from "./styles/button";
import { mainnet } from "wagmi/chains";
import { captureException } from "@sentry/react";
import { impersonator } from "./impersonator";
import numbro from "numbro";

export const getConnector = (connectorId: string): Connector | undefined => {
  const connectors = getConnectors(wagmiConfig);
  return connectors.find(c => c.id === connectorId);
}

/**
 * Get name of the wallet
 * @function getWalletName
 * @param {number} connectorId - a number that denotes the type of wallet
 * @returns {string} the name of the wallet or an empty string if incorrect number
 */
export const getWalletName = (connectorId: string): string => {
  const connector = getConnector(connectorId);
  if (typeof connector === 'undefined') {
    return "";
  }
  if (connector.name) {
    return connector.name
  }
  if (connector.type === 'injected') {
    return "Browser Wallet";
  }
  return ""
};

/**
 * @function getWalletIcon
 * @param {number} connectorId - an ID of the current connector
 * @returns {string} the image source url of the wallet icon or an empty string if incorrect ID
 */
export const getWalletIcon = (connectorId: string): ReactElement => {
  const connector = getConnector(connectorId);
  if (typeof connector === 'undefined') {
    return <></>;
  }
  if (connector.icon) {
    return <img src={connector.icon} alt={connector.name || 'Unknown wallet'} />;
  }

  switch (connector.type) {
    case metaMask.type:
      return <MetaMaskOption/>;
    case walletConnect.type:
      return <WalletConnectOption/>;
    case coinbaseWallet.type:
      return <CoinbaseOption/>;
    case impersonator.type:
      return <ReadOnlyOption/>;
    default:
      return <></>;
  }
}

export const isNetworkSupported = (chainId: number): boolean => {
  return (
    chainId === NETWORKS.localhost ||
    chainId === NETWORKS.mainnet ||
    chainId === NETWORKS.mainnetfork
  );
};

export const sendNotificationIfNetworkUnsupported = (chainId: number) => {
  if (!isNetworkSupported(chainId)) {
    store.default.dispatch(
      store.toastAdd({
        id: 'connected-to-wrong-network',
        message: <span>You are connected to an unsupported network. <LinkButton onClick={() => switchChain(wagmiConfig, { chainId: mainnet.id }).catch(captureException)}>Switch to Mainnet</LinkButton>.</span>,
        type: "error",
      })
    );
  } else {
    store.default.dispatch(store.toastRemove('connected-to-wrong-network'))
  }
};

export const getEtherScanLink = (): string => {
  return "https://etherscan.io/";
};

export const copyTextToClipboard = (value: string): void => {
  const el = document.createElement("textarea");
  el.value = value;
  el.setAttribute("readonly", "");
  el.style.position = "absolute";
  el.style.left = "-9999px";
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
};

export const percentageFormatter = (value: string) => {
  const floatValue = parseFloat(value);
  if (floatValue == null || isNaN(floatValue) || !isFinite(floatValue)) {
    return "0%";
  }
  if (floatValue > 1000) {
    return "> 100%";
  }

  return numbro(floatValue).format({ output: 'percent', mantissa: 2, optionalMantissa: true });
};

export const hexToRgb = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16),
      ]
    : [0, 0, 255];
};

export const fillStringTemplate = function (template: string, vars: any) {
  let result = template;
  for (const key in vars) {
    result = result.replace(`{{${key}}}`, vars[key]);
  }
  return result;
};

export const limitDecimals = (amount: BigNumberish, maxDecimals?: number) => {
  let amountStr = amount.toString();
  if (maxDecimals === undefined) {
    return amountStr;
  }
  if (maxDecimals === 0) {
    return amountStr.split(".")[0];
  }
  const dotIndex = amountStr.indexOf(".");
  if (dotIndex !== -1) {
    let decimals = amountStr.length - dotIndex - 1;
    if (decimals > maxDecimals) {
      amountStr = amountStr.substr(0, amountStr.length - (decimals - maxDecimals));
    }
  }
  return amountStr;
};

export const parseValue = (value: BigNumberish | undefined | null, decimals: number = 18) => {
  if (!value) {
    return constants.Zero;
  }
  value = limitDecimals(value, decimals);
  try {
    return utils.parseUnits(value, decimals);
  } catch (e) {
    console.error(`Couldn't parse value ${value} with ${decimals} decimals`, e);
    return constants.Zero;
  }
};

export function walletClientToSigner(walletClient: Client<Transport, Chain, Account>, chain?: Chain) {
  const { account, chain: clientChain, transport } = walletClient
  const providerChain = clientChain || chain
  const network = {
    chainId: providerChain.id,
    name: providerChain.name,
    ensAddress: providerChain.contracts?.ensRegistry?.address,
  }
  const provider = new providers.Web3Provider(transport, network);
  return provider.getSigner(account.address)
}

export function publicClientToProvider(publicClient: Client<Transport, Chain>) {
  const { chain, transport } = publicClient
  const network = {
    chainId: chain.id,
    name: chain.name,
    ensAddress: chain.contracts?.ensRegistry?.address,
  }
  if (transport.type === 'fallback') {
    return new FallbackProvider(
      (transport.transports as ReturnType<Transport>[])
        .filter(({value}) => value?.url)
        .map(({ value}) => {
          return new providers.JsonRpcProvider(value?.url, network)
        },
      )
    )
  }
  return new providers.Web3Provider(transport, network)
}

export const isDevelopment = () => {
  return process.env.NODE_ENV !== "production"
}
