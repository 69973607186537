import {
  createSlice,
  createEntityAdapter,
  PayloadAction,
} from "@reduxjs/toolkit";
import { Toast, ToastTypes } from "../../models";
import { v4 as uuidv4 } from "uuid";
import { ReactNode } from "react";

const toastAdapter = createEntityAdapter<Toast>({
  selectId: (toast) => toast.id,
});

const toastSlice = createSlice({
  name: "toast",
  initialState: toastAdapter.getInitialState(),
  reducers: {
    toastAdd(state, action: PayloadAction<Partial<Toast>>) {
      if (isDuplicate(action.payload.message || "", selectAllToasts(state))) {
        return;
      }
      toastAdapter.addOne(state, {
        id: action.payload.id || uuidv4(),
        message: action.payload.message,
        type: action.payload.type as ToastTypes,
      } as Toast);
    },
    toastRemove: toastAdapter.removeOne,
  },
});

const isDuplicate = (message: ReactNode, toasts: Toast[]) =>
  toasts.some((toast) => toast.message === message);

// Selectors
const { selectAll, selectById } = toastAdapter.getSelectors();
export const selectAllToasts = selectAll;
export const selectToast = selectById;

export default toastSlice;
