import React from "react";
import { ModalProps } from "../../../../modules/dashboard/modals";

import { BorrowTab } from "./tabs/borrow.tab";
import { RepayTab } from "./tabs/repay.tab";
import TabsModal from "../../../../components/tabs-modal";

const tabs: Record<string, string> = {
  borrow: "Borrow",
  repay: "Repay",
};

const TabsMap = {
  [tabs.borrow]: <BorrowTab />,
  [tabs.repay]: <RepayTab />,
}

export const BorrowRepaymentModal = ({ isOpen, onClose }: ModalProps) => {
  return (
    <TabsModal tabs={tabs} defaultTab={tabs.borrow} show={isOpen} onClose={onClose} testId="borrow-repay-modal">
      {(activeTab) => TabsMap[activeTab]}
    </TabsModal>
  );
};
