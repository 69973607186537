import React from "react";
import { useCallback, useMemo, useState } from "react";

import { CustomModal, TokenInput } from "../../../../components";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import {
  UserVaultModel,
  VaultModel, VaultStats,
} from "../../../../models";
import {
  BarChart,
  StatisticsRow,
} from "../../../../modules/dashboard/modals/components";
import { ModalProps } from "../../../../modules/dashboard/modals/interfaces";
import * as Styles from "../../../../modules/dashboard/modals/styles";
import {
  borrowInputValidator,
  depositInputValidator,
} from "../../../../modules/dashboard/modals/validators";
import { depositAndBorrow } from "../../../../store/userVault/actions";
import {
  ModalHeader,
  ModalHeaderTab,
} from "../../../../utils/styles/modal.styles";
import {UserPosition, useUserPosition} from "../../../../hooks/useUserPosition";
import { getLiquidationAmount } from "../components/bar-chart/bar-chart.logic";
import { useUser, useUserVaultInfo, useVault, useVaultStats } from "../../../../hooks/useAppSelector";
import ReadOnlyWarning from "../components/read-only-warning";
import { formatUnits } from "ethers/lib/utils";
import { BigNumber, constants } from "ethers";
import { parseValue } from "../../../../utils";
import { ValidatorFunction } from "../../../../components/token-input/token-input";

export const TakeLoanModal = ({ isOpen, onClose }: ModalProps) => {
  const dispatch = useAppDispatch();

  const { data: vault} = useVault();
  const { selectedVault } = useAppSelector((state) => state.global);
  const { data: vaultStats } = useVaultStats(vault as VaultModel);
  const { data: { isReadOnly } = {} } = useUser();


  const {
    supplyBasePrice,
    borrowToUsdRatio,
    supplyThreshold,
    userMinDepositLimit,
    userMaxDepositLimit,
    vaultDepositLimit,
    liquidationThreshold,
    supplySymbol,
    borrowSymbol,
    borrowDecimals,
    supplyDecimals,
    supplyPrecision,
    borrowPrecision
  } = vault as VaultModel || {}

  const { totalSupply: vaultTotalSupply } = vaultStats as VaultStats || {}

  const { data: vaultInfo} = useUserVaultInfo()

  const {
    supplyTokenAmount: supplyTokenAmountNumber,
    supplyTokenAmountBn: supplyTokenAmount,
    collateralTokenAmount: collateralTokenAmountNumber,
    collateralTokenAmountBn: collateralTokenAmount,
    debtTokenAmount: debtTokenAmountNumber,
    debtTokenAmountBn: debtTokenAmount,
  } = vaultInfo as UserVaultModel || {}

  const { data: userPosition } = useUserPosition();
  const {
    net: { netApy },
    current: { borrowLimit, borrowingCapacity },
    projected: { monthlyRepayments }
  } = userPosition as UserPosition;
  const [isDepositTouched, setIsDepositTouched] = useState(false);
  const [isBorrowTouched, setIsBorrowTouched] = useState(false);
  const isTouched = useMemo(() => isBorrowTouched || isDepositTouched, [isDepositTouched, isBorrowTouched]);
  const [isValid, setIsValid] = useState(false);

  const [borrow, setBorrow] = useState(constants.Zero);
  const [deposit, setDeposit] = useState(constants.Zero);
  const newDebtAmount = useMemo(() => debtTokenAmount ? debtTokenAmount.add(borrow) : constants.Zero, [debtTokenAmount, borrow]);
  const newCollateralAmount = useMemo(() => collateralTokenAmount ? collateralTokenAmount.add(deposit) : constants.Zero, [collateralTokenAmount, deposit]);
  const { data: futureUserPosition } = useUserPosition(newDebtAmount, newCollateralAmount);
  const {
    net: { netApy: futureNetAPY },
    current: {
      borrowLimit: newBorrowLimit,
      borrowingCapacity: futureBorrowingCapacityPercentage,
      liquidationLimit: newLiquidationLimit
    },
    projected: { monthlyRepayments: futureSmartRepayment },
  } = futureUserPosition as UserPosition;


  const takeLoanHandler = useCallback(() => dispatch(depositAndBorrow({ deposit, borrow, selectedVault })), [dispatch, deposit, borrow, selectedVault]);

  const handleDepositChange = useCallback((value?: BigNumber) => {
    setIsDepositTouched(!!value);
    setDeposit(value || constants.Zero);
  }, []);

  const handleBorrowChange = useCallback((value?: BigNumber) => {
    setIsBorrowTouched(!!value);
    setBorrow(value || constants.Zero);
  }, []);

  const depositLimit = useMemo(
    () => userMaxDepositLimit - collateralTokenAmountNumber,
    [userMaxDepositLimit, collateralTokenAmountNumber]
  );

  const borrowLimitBn = useMemo(() => parseValue(borrowLimit, borrowDecimals), [borrowLimit, borrowDecimals])
  const maxBorrowAmount = useMemo(() => {
    const maxBorrow = borrowLimitBn.sub(debtTokenAmount);
    return maxBorrow.gt(0) ? maxBorrow : constants.Zero;
  }, [borrowLimitBn, debtTokenAmount])

  const borrowValidator = useCallback<ValidatorFunction>(
    (value) => {
      const valueBn = parseValue(value, borrowDecimals);
      const max = parseValue(newBorrowLimit, borrowDecimals);
      return borrowInputValidator(valueBn.mul(parseValue(borrowToUsdRatio)).div(constants.WeiPerEther), max)
    },
    [newBorrowLimit, borrowToUsdRatio, borrowDecimals]
  );

  const depositValidator = useCallback(
    (value?: string) => {
      const error = depositInputValidator(
        supplySymbol,
        collateralTokenAmountNumber,
        Number(value),
        supplyTokenAmountNumber,
        depositLimit,
        userMinDepositLimit,
        vaultTotalSupply,
        vaultDepositLimit,
        selectedVault
      );
      setIsValid(!error);
      return error;
    },
    [supplySymbol, collateralTokenAmountNumber, supplyTokenAmountNumber, depositLimit, userMinDepositLimit, vaultTotalSupply, vaultDepositLimit, selectedVault]
  );

  const liquidationPrice = useCallback((collateral: number) => {
    return getLiquidationAmount(+formatUnits(newDebtAmount, borrowDecimals), liquidationThreshold, collateral) * borrowToUsdRatio;
  }, [newDebtAmount, borrowDecimals, liquidationThreshold, borrowToUsdRatio]);

  const isTakeLoanButtonDisabled = useMemo(() => isReadOnly || !isValid || (deposit.lte(0) && borrow.lte(0)), [borrow, deposit, isReadOnly, isValid])

  return (
    <CustomModal data-testid="take-loan-modal" show={isOpen} onClose={onClose}>
      <ModalHeader data-testid="take-loan-modal-header">
        <ModalHeaderTab $active={true}>
          Take Loan
        </ModalHeaderTab>
      </ModalHeader>
      <Styles.AmountContainer>
        <Styles.InputWrapper data-testid="collateral-token-input">
          <TokenInput
            tokenName={supplySymbol}
            name={supplySymbol + "Amount"}
            label={"Deposit Amount"}
            validator={depositValidator}
            currencyExchangeRate={supplyBasePrice * borrowToUsdRatio}
            onValueChange={handleDepositChange}
            maxValue={supplyTokenAmount}
            precision={supplyPrecision}
          />
        </Styles.InputWrapper>
        <Styles.InputWrapper data-testid="borrow-token-input">
          <TokenInput
            data-testid="debt-token-input"
            tokenName={borrowSymbol}
            name={borrowSymbol + "Amount"}
            maxValue={maxBorrowAmount}
            balanceLabel="borrow limit"
            decimals={borrowDecimals}
            precision={borrowPrecision}
            label={"Borrow Amount"}
            currencyExchangeRate={borrowToUsdRatio}
            validator={borrowValidator}
            onValueChange={handleBorrowChange}
          />
        </Styles.InputWrapper>
      </Styles.AmountContainer>
      <Styles.StatisticsContainer>
        <BarChart
          to={futureBorrowingCapacityPercentage}
          from={borrowingCapacity}
          borrowLimit={newBorrowLimit}
          liquidation={newLiquidationLimit}
          collateral={+formatUnits(newCollateralAmount, supplyDecimals)}
          balance={
            +formatUnits(borrow.gt(0) ? borrow.add(debtTokenAmount || constants.Zero) : debtTokenAmount, borrowDecimals)
          }
          liquidationThreshold={liquidationThreshold}
          supplyThreshold={supplyThreshold}
          borrowToUsdRatio={borrowToUsdRatio}
          borrowName={borrowSymbol + " "}
          supplySymbol={supplySymbol}
        />
        <StatisticsRow
          touched={isTouched}
          label={"Deposit amount"}
          from={collateralTokenAmountNumber}
          to={+formatUnits(newCollateralAmount, supplyDecimals)}
          prefix={supplySymbol + " "}
        />
        <StatisticsRow
          touched={isTouched}
          label={"Borrow Amount"}
          from={debtTokenAmountNumber}
          to={+formatUnits(newDebtAmount, borrowDecimals)}
          prefix={borrowSymbol + " "}
        />
        <StatisticsRow
          touched={isTouched}
          label={"Borrow limit"}
          from={borrowLimit}
          to={newBorrowLimit}
          prefix={borrowSymbol + " "}
        />
        <StatisticsRow
          touched={isTouched}
          label={"Liquidation price"}
          from={liquidationPrice(collateralTokenAmountNumber)}
          to={liquidationPrice(+formatUnits(newCollateralAmount, supplyDecimals))}
          prefix={`${supplySymbol}: $`}
        />
        <Styles.StatisticsRowDivider />
        <StatisticsRow
          touched={isTouched}
          label={"Estimated Monthly Repayments"}
          from={monthlyRepayments || 0}
          to={futureSmartRepayment}
          prefix={borrowSymbol + " "}
        />
        <StatisticsRow
          touched={isTouched}
          label={"Net APY"}
          from={netApy || 0}
          to={futureNetAPY}
          postfix={"%"}
          prefix={""}
        />
        <Styles.TakeLoanBtn
          onClick={takeLoanHandler}
          disabled={isTakeLoanButtonDisabled}
          data-testid="take-loan-action-btn"
        >
          {getButtonLabel(deposit, borrow)}
        </Styles.TakeLoanBtn>
        <ReadOnlyWarning />
      </Styles.StatisticsContainer>
    </CustomModal>
  );
};

const getButtonLabel = (deposit: BigNumber, borrow: BigNumber): string => {
  if (deposit.gt(0) && borrow.eq(0)) {
    return "Deposit";
  }
  if (deposit.eq(0) && borrow.gt(0)) {
    return "Borrow";
  }

  return "Take Loan";
};
