import React, { ReactElement, ReactText } from "react";

import * as Styles from "./style";

type BannerActionTypes = "primary" | "secondary";

interface BannerActionInterface {
  size: number;
  title: string;
  action: () => void;
  disabled?: boolean;
  type: BannerActionTypes;
}

interface BannerProps {
  header: string;
  subHeader: ReactElement | ReactText;
  actions: BannerActionInterface[];
}

const Banner = ({ header, subHeader, actions }: BannerProps) => {
  return (
    <Styles.Banner data-testid="start-loan-banner">
      <Styles.BannerRotatedBackground>
        <Styles.BannerBackground>
          <Styles.BannerContent>
            <Styles.BannerHeader>{header}</Styles.BannerHeader>
            <Styles.BannerSubHeader>{subHeader}</Styles.BannerSubHeader>
            <Styles.BannerActions>
              {actions.map((action, index) => (
                <React.Fragment
                  key={`BannerButton${index}${action.title.replace(" ", "_")}`}
                >
                  {renderButtonBasedByType(action, action.type)}
                </React.Fragment>
              ))}
            </Styles.BannerActions>
          </Styles.BannerContent>
        </Styles.BannerBackground>
      </Styles.BannerRotatedBackground>
    </Styles.Banner>
  );
};

const renderButtonBasedByType = (
  action: BannerActionInterface,
  type: BannerActionTypes
) => {
  switch (type) {
    case "primary":
      return (
        <Styles.BannerButtonContainer>
          <Styles.MainActionBtn
            data-testid="start-loan-banner-button-primary"
            disabled={action.disabled}
            onClick={action.action}
          >
            {action.title}
          </Styles.MainActionBtn>
        </Styles.BannerButtonContainer>
      );
    case "secondary":
      return (
        <Styles.BannerButtonContainer>
          <Styles.SecondaryActionBtn
            data-testid="start-loan-banner-button-secondary"
            disabled={action.disabled}
            onClick={action.action}
          >
            {action.title}
          </Styles.SecondaryActionBtn>
        </Styles.BannerButtonContainer>
      );
    default:
      return <></>;
  }
};

export default Banner;
