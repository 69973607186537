import styled from "styled-components";
import { TextHeader2, TextParagraph, Text } from "../../../utils/styles/text";
import {Box, GridVars, ThemeSecondaryText} from "../../../utils";

export const TransactionContainer = styled(Box)`
  padding: 48px 60px 89px 60px;

  @media (max-width: 600px) {
    padding: 48px 10px 89px 10px;
  }

  overflow: hidden;
`;

export const TransactionHeader = styled(TextHeader2)`
  font-size: 1.6rem;
  text-align: center;
`;

export const TransactionDesc = styled(TextParagraph)`
  text-align: center;
`;

export const IconContainer = styled(Text)`
  margin-top: 78px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: -34px;
  padding-top: ${GridVars.padding.p2};
`

export const ShowMore = styled.button`
  display: flex;
  color: ${ThemeSecondaryText};
  text-decoration: dashed underline;
  background: none;
  border: none;
`

export const ErrorDetails = styled(Text)`
  display: block;
  width: 100%;
  margin-top: ${GridVars.margin.m1};
`;
