import * as React from "react";
import { SVGProps } from "react";
const SvgExternalLink = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>External Link</title>
    <path
      d="M15.284 0a.694.694 0 0 0-.077.007h-4.081a.696.696 0 1 0 0 1.39h2.493L6.462 8.556a.695.695 0 1 0 .983.983l7.158-7.157v2.493a.695.695 0 1 0 1.39 0V.791a.696.696 0 0 0-.709-.79ZM1.391 2.788C.63 2.788 0 3.42 0 4.18v10.43C0 15.37.63 16 1.39 16h10.431c.76 0 1.391-.63 1.391-1.39V5.862l-1.39 1.39v7.356H1.39V4.18H8.746l1.391-1.39H1.391Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgExternalLink;
