import React from "react";
import { VaultsGrid, VaultsPage } from "./styles";
import { Vault } from "./components";
import { useVaults } from "../../hooks/useAppSelector";

const Vaults = () => {
  const { data: vaults } = useVaults();

  return (
    <VaultsPage>
      <VaultsGrid>
        {vaults.map((vault, index) => <Vault key={vault.id} model={vault} canToggle={vaults.length > 1} startExpanded={index === 0} />)}
      </VaultsGrid>
    </VaultsPage>
  );
};

export default Vaults;
