import styled from "styled-components";
import {
  ThemePrimary,
  ThemeGray800,
  ThemeGray900,
  ElementHR,
  ThemePrimaryText,
  ThemeSecondaryText,
} from "../../../utils";
import { GridVars } from "../../../utils";
import { TextLabel, TextValue } from '../../../utils/styles/text'
import { Button } from "../../../utils/styles/button";

export const TabWrapper = styled.div`
  width: auto;
`;

export const AmountContainer = styled.div`
  padding: 24px;
  border-radius: 0;
  background: ${ThemeGray800};
`;

export const InputWrapper = styled.div`
  &:nth-child(2) {
    margin-top: 24px;
  }
`;

export const StatisticsContainer = styled.div`
  background: ${ThemePrimary};
  background: ${ThemeGray900};
  padding: ${GridVars.padding.p3};
  border-radius: 0 0 8px 8px;
`;

export const StatisticsRow = styled.div`
  margin-top: ${GridVars.margin.m2};
  display: flex;
  justify-content: space-between;
`;

export const StatisticsRowLabel = styled(TextLabel)`
  width: 100%;
  color: ${ThemePrimaryText};
`;

export const StatisticsRowValues = styled(TextValue)`
  gap: 15px;
  display: flex;
  align-items: center;
`;

export const StatisticsRowValuesFrom = styled.div`
  span {
    color: ${ThemeSecondaryText};
  }
`;

export const StatisticsRowValuesTo = styled.div`
  span {
    color: ${ThemePrimaryText};
  }
`;

export const StatisticsRowDivider = styled(ElementHR)``;

export const TakeLoanBtn = styled(Button).attrs({
  $fluid: true
})`
  margin-top: ${GridVars.margin.m3};
`;

export const NoWrap = styled.span`
  white-space: nowrap;
`;
