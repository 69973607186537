import {
  createSlice,
  PayloadAction,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import { VaultStats } from "../../models";
import { fetchVaultStats } from "./actions";

const vaultStatsAdapter = createEntityAdapter<VaultStats>({
  selectId: (vault) => vault.id,
});

const vaultStatsSlice = createSlice({
  name: "vaultStats",
  initialState: vaultStatsAdapter.getInitialState({
    loading: false
  }),
  reducers: {

  },
  extraReducers(builder) {
    builder
      .addCase(fetchVaultStats.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        fetchVaultStats.fulfilled,
        (state, action: PayloadAction<VaultStats>) => {
          vaultStatsAdapter.setOne(state, action.payload);
          state.loading = false;
        }
      )
      .addCase(
        fetchVaultStats.rejected,
        (state) => {
          state.loading = false;
        }
      )
  },
});

// Selectors
const { selectById } = vaultStatsAdapter.getSelectors();
export const selectVaultStats = selectById;

export default vaultStatsSlice;
