import * as React from "react";
import { SVGProps } from "react";
const SvgLogoUsdc = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 193 193"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>Logo USDC</title>
    <path
      d="M96.5 193c53.477 0 96.5-43.023 96.5-96.5S149.977 0 96.5 0 0 43.023 0 96.5 43.023 193 96.5 193Z"
      fill="#2775CA"
    />
    <path
      d="M123.038 111.779c0-14.073-8.444-18.898-25.332-20.908-12.062-1.609-14.475-4.825-14.475-10.455s4.021-9.247 12.063-9.247c7.237 0 11.258 2.412 13.269 8.443.402 1.207 1.608 2.01 2.814 2.01h6.433c1.609 0 2.815-1.206 2.815-2.814v-.402c-1.609-8.846-8.846-15.681-18.094-16.485v-9.65c0-1.609-1.206-2.815-3.216-3.217h-6.031c-1.609 0-2.815 1.206-3.218 3.217v9.248c-12.062 1.608-19.701 9.65-19.701 19.702 0 13.269 8.041 18.495 24.929 20.506 11.258 2.01 14.877 4.423 14.877 10.857 0 6.433-5.63 10.856-13.269 10.856-10.454 0-14.073-4.424-15.28-10.455-.4-1.608-1.607-2.413-2.813-2.413h-6.836c-1.608 0-2.814 1.207-2.814 2.815v.403c1.607 10.051 8.041 17.289 21.31 19.3v9.65c0 1.607 1.206 2.814 3.216 3.216h6.031c1.609 0 2.815-1.206 3.218-3.216v-9.65c12.062-2.011 20.104-10.455 20.104-21.311Z"
      fill="#fff"
    />
    <path
      d="M75.994 153.998c-31.363-11.258-47.446-46.239-35.785-77.2 6.03-16.888 19.3-29.754 35.785-35.785 1.608-.804 2.412-2.01 2.412-4.021v-5.63c0-1.608-.804-2.814-2.412-3.215-.403 0-1.207 0-1.609.4C36.187 40.61 15.28 81.223 27.341 119.42c7.238 22.516 24.528 39.806 47.044 47.044 1.609.804 3.217 0 3.619-1.609.402-.401.402-.804.402-1.608v-5.629c0-1.207-1.206-2.814-2.412-3.619Zm42.621-125.45c-1.609-.804-3.217 0-3.619 1.609-.402.402-.402.803-.402 1.608v5.63c0 1.608 1.206 3.215 2.412 4.02 31.363 11.258 47.446 46.24 35.785 77.2-6.031 16.888-19.3 29.754-35.785 35.785-1.608.804-2.412 2.01-2.412 4.021v5.629c0 1.609.804 2.815 2.412 3.217.403 0 1.207 0 1.609-.402 38.198-12.062 59.106-52.673 47.044-90.87-7.238-22.92-24.93-40.21-47.044-47.447Z"
      fill="#fff"
    />
  </svg>
);
export default SvgLogoUsdc;
