import { InfoPopover } from "../../../../components";
import * as PopoverStyles from "../../../../components/custom-popover/styles";
import { useAppSelector } from "../../../../hooks";
import { VaultModel } from "../../../../models";
import { selectUserVault } from "../../../../store/userVault/userVault.slice";
import { selectVault } from "../../../../store/vault/vault.slice";

import { BorrowingCapacityChart } from "./borrowing-capacity-chart";
import * as Styles from "./style";
import {UserPosition, useUserPosition} from "../../../../hooks/useUserPosition";

export const BorrowingCapacity = () => {
  const vaultStore = useAppSelector((state) => state.vault);
  const userVaultStore = useAppSelector((state) => state.userVault);
  const { selectedVault } = useAppSelector((state) => state.global);

  const {
    supplyThreshold,
    liquidationThreshold,
    supplySymbol,
    borrowSymbol,
  }: VaultModel = selectVault(vaultStore, selectedVault) || ({} as VaultModel);
  const {
    debtTokenAmount,
    collateralTokenAmount,
  }: any = selectUserVault(userVaultStore, selectedVault) || {};

  const { data: userPosition } = useUserPosition();
  const {
    current: { borrowLimit, liquidationLimit },
  } = userPosition as UserPosition;

  return (
    <Styles.BorrowingCapacityContainer>
      <Styles.BorrowingCapacityTitle>
        <Styles.BoxHeader color="pinkAlpha">
          <Styles.BoxHeaderSquare color="pinkAlpha" />
          <span>Loan Health</span>
          <InfoPopover>
            <PopoverStyles.Paragraph>
              Your Borrow Limit determines how much you can borrow.
            </PopoverStyles.Paragraph>
            <PopoverStyles.Paragraph>
              If you exceed your maximum Borrowing Capacity, your position may
              be subject to liquidation.
            </PopoverStyles.Paragraph>
          </InfoPopover>
        </Styles.BoxHeader>
      </Styles.BorrowingCapacityTitle>
      <Styles.BorrowingCapacityDescription>
        <Styles.BorrowingCapacitySubHeader>
          Your Borrow Limit indicates how much you can borrow. It is
          calculated based on the current value of your collateral and will
          adjust as the price fluctuates.
        </Styles.BorrowingCapacitySubHeader>
      </Styles.BorrowingCapacityDescription>
      <Styles.ChartWrapper>
        <BorrowingCapacityChart
          balance={debtTokenAmount}
          borrowLimit={borrowLimit}
          liquidation={liquidationLimit}
          liquidationThreshold={liquidationThreshold}
          collateralInETH={collateralTokenAmount}
          supplyThreshold={supplyThreshold || 0}
          supplyTokenName={supplySymbol}
          borrowTokenName={borrowSymbol}
        />
      </Styles.ChartWrapper>
    </Styles.BorrowingCapacityContainer>
  );
};
