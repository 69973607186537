import React from "react";
import { useCallback, useState } from "react";

import { Checkbox } from "../../../../components/checkbox";
import { TextLink } from "../../../../utils/styles/text";
import { PROJECT_NAME, riskWarningToken } from "../../../../config";
import { ModalProps } from "../../../../modules/dashboard/modals/interfaces";
import { ElementHR, STATIC_URLS } from "../../../../utils";
import * as ModalStyles from "../../../../utils/styles/modal.styles";

import * as Styles from "./styles";
import { CustomModal } from "../../../../components";
import { useOptIn } from "../../../../hooks/useAnalytics";

export const RISK_APPROVAL_TIMEOUT = 30 * 86400 * 1000 // 30 days

export const RiskWarningModal = ({ isOpen, onClose }: ModalProps) => {
  const { optIn } = useOptIn();
  const close = useCallback(() => {
    const timeStamp = String(new Date().valueOf());
    localStorage.setItem(riskWarningToken, timeStamp);
    optIn()
    onClose();
  }, [onClose, optIn]);

  const [allow, setAllow] = useState<boolean>(false);

  return (
    <CustomModal show={isOpen}>
      <ModalStyles.ModalContent data-testid="risk-warning-modal">
        <Styles.RiskWarningModalContent>
          <Styles.RiskWarningHeader>Risk Warning</Styles.RiskWarningHeader>
          <Styles.RiskWarningContent>
            {PROJECT_NAME} is an experimental protocol and you may lose all of
            your assets due to one of many reasons such as a bug, hack or an
            exploit.
          </Styles.RiskWarningContent>
          <Styles.RiskWarningContent>
            Please read our full{" "}
            <TextLink href={STATIC_URLS.tandc} target="_blank" rel="noreferrer noopener">Terms and Conditions</TextLink> and
            proceed with extreme caution.
          </Styles.RiskWarningContent>
          <ElementHR />
          <Styles.RiskWarningCheckboxWrapper>
            <Styles.RiskWarningCheckboxItem>
              <Checkbox
                value={allow}
                onChange={(checked) => setAllow(checked)}
              />
            </Styles.RiskWarningCheckboxItem>
            <Styles.RiskWarningCheckboxItem onClick={() => setAllow(!allow)}>
              By checking this box and proceeding, you confirm that you
              irrevocably accept our Terms and Conditions and confirm that you
              fully understand the risks.
            </Styles.RiskWarningCheckboxItem>
          </Styles.RiskWarningCheckboxWrapper>
          <ElementHR />
          <Styles.ConfirmButtonContainer>
            <Styles.CookieConsentText>
              We use cookies to improve your experience and
              analyze traffic, these don't link to user data.
            </Styles.CookieConsentText>
            <Styles.RiskWarningConfirmButton onClick={close} disabled={!allow}>
              Confirm
            </Styles.RiskWarningConfirmButton>
          </Styles.ConfirmButtonContainer>
        </Styles.RiskWarningModalContent>
      </ModalStyles.ModalContent>
    </CustomModal>
  );
};
