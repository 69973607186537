import * as Styles from "./style";

const NotFound = () => {
  return (
    <Styles.NotFoundContainer data-testid="notFound">
      <Styles.NotFoundTitle>Page Not Found</Styles.NotFoundTitle>
      <Styles.NotFoundText>
        Oops! We couldn't find what you were looking for.
      </Styles.NotFoundText>
    </Styles.NotFoundContainer>
  );
};

export default NotFound;
