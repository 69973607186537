import { useCallback, useMemo } from "react";

import Banner from "../../../../components/banner";
import { PROJECT_NAME } from "../../../../config";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { TakeLoanModal } from "../../../../modules/dashboard/modals/take-loan/take-loan.modal";
import { usePopupManager } from "react-popup-manager";
import { globalSetHeaderModal } from "../../../../store";
import { selectUser } from "../../../../store/user/user.slice";
import { selectUserVault } from "../../../../store/userVault/userVault.slice";
import { HEADER_MODAL_STATUS } from "../../../../utils";
import { TextLink, TextParagraph } from "../../../../utils/styles/text"
import DepositWithdrawModal from "../../modals/deposit-withdraw";

export const StartLoanBanner = () => {
  const dispatch = useAppDispatch();
  const popupManager = usePopupManager();

  const userStore = useAppSelector((state) => state.user);
  const userVaultStore = useAppSelector((state) => state.userVault);
  const { selectedVault } = useAppSelector((state) => state.global);

  const { walletConnected }: any = selectUser(userStore) || {};
  const { isActionsByAllowlistBlocked }: any =
    selectUserVault(userVaultStore, selectedVault) || {};

  const openTakeLoanPopup = useCallback(() => {
    popupManager.open<any>(TakeLoanModal, {
      title: "my modal",
      onClose: () => {},
    });
  }, [popupManager]);

  const openConnectWalletModal = useCallback(
    () => dispatch(globalSetHeaderModal(HEADER_MODAL_STATUS.connectWallet)),
    [dispatch]
  );

  const openDepositPopup = useCallback(() => {
    popupManager.open<any>(DepositWithdrawModal, {
      title: "DepositWithdrawModal",
      onClose: () => {},
    });
  }, [popupManager]);

  const ctaCallback = useMemo(
    () => (walletConnected ? openTakeLoanPopup : openConnectWalletModal),
    [walletConnected, openTakeLoanPopup, openConnectWalletModal]
  );

  return (
    <Banner
      header={`Welcome to ${PROJECT_NAME}`}
      data-testid="start-loan-banner"
      subHeader={
        /* TODO: Use components for below? */
        <>
          <TextParagraph>
            {PROJECT_NAME} takes care of your collateralized loan and ensures
            that you always receive the lowest available interest rate. We also
            put your unused borrowing capacity to work to generate yield, which
            is used to automatically repay your debt.
          </TextParagraph>
          <TextParagraph>
            See <TextLink href="https://docs.altitude.fi/faq">FAQ</TextLink> for more information.
          </TextParagraph>
          {/* <TextParagraph>You can take out a new loan or migrate an existing loan from Compound or AAVE.</TextParagraph> */}
        </>
      }
      actions={[
        {
          size: 6,
          type: "primary",
          title: "Take a New Loan",
          disabled: isActionsByAllowlistBlocked,
          action: ctaCallback,
        },
        {
          size: 6,
          type: "secondary",
          title: "Deposit",
          disabled: isActionsByAllowlistBlocked,
          action: openDepositPopup,
        },
      ]}
    />
  );
};
