import React from "react";
import { useState } from "react";

import { Popover, PopoverPosition } from "react-tiny-popover";

import * as Styles from "./styles";
import { AnimationDurationMs } from "../../utils";

interface InfoPopoverProps {
  maxWidth?: string;
  marginTop?: string;
  marginLeft?: string;
  positions?: PopoverPosition[];
  button: React.ReactNode;
  children: React.ReactNode;
}

export const CustomPopover = ({
  children,
  button,
  maxWidth = "350px",
  marginTop = "0px",
  marginLeft = "0px",
  positions = ["top", "bottom"],
}: InfoPopoverProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [contentVisible, setContentVisible] = useState<boolean>(false);

  const handleClose = () => {
    setContentVisible(false);
    setTimeout(() => {
      setIsOpen(false);
    }, AnimationDurationMs.short); // Duration of fadeOut animation
  };

  const handleOpen = () => {
    setIsOpen(true);
    setContentVisible(true);
  };

  return (
    <Popover
      padding={10}
      align="start"
      containerStyle={{ maxWidth, marginTop, marginLeft }}
      isOpen={isOpen}
      positions={positions}
      onClickOutside={handleClose}
      content={<Styles.PopoverContainer $show={contentVisible} >{children}</Styles.PopoverContainer>}
    >
      <Styles.PopoverButton
        onClick={isOpen ? handleClose : handleOpen}
      >
        {button}
      </Styles.PopoverButton>
    </Popover>
  );
};
