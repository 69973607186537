import numbro from "numbro";

interface MoneyFormatterLogicProps {
  prefix?: string;
  postfix?: string;
  value: number | string;
}

const moneyFormatterLogic = ({
  prefix = "",
  value,
  postfix = "",
}: MoneyFormatterLogicProps) => {
  if (value == null) {
    return "0.00";
  }

  const number = Number(value);
  if (isNaN(number)) {
    return "N/A";
  }
  if (number > 99999) {
    const format = `${prefix}0.00a${postfix}`;
    return numbro(number).format(format);
  }
  return `${prefix}${numbro(number.toFixed(2)).format('0,0.00')}${postfix}`;
};

export default moneyFormatterLogic;
