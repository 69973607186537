import styled from "styled-components";
import {
  Box,
  BoxHeader,
  BoxHeaderSquare,
  GridVars,
} from "../../../../utils";
import { Button } from "../../../../utils/styles/button";
import { Text, TextLabel, TextLabelProps, TextValue } from "../../../../utils/styles/text";

export const UserLoanStatContainer = styled(Box)`
  grid-area: userLoanStats;
  display: grid;
`;

export const UserStatBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: ${GridVars.gap};
  position: relative;

  @media (max-width: ${GridVars.breakpoint.sm}) {
    flex-direction: column;
    grid-template-columns: 1fr;
    grid-row-gap: ${GridVars.margin.m0};
  }
`;

export const UserStatBoxItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: ${GridVars.breakpoint.sm}) {
    flex-direction: row;
    align-items: end;

    &:first-child {
      margin-top: ${GridVars.margin.m4};
      margin-bottom: ${GridVars.margin.m4};
    }
  }
`;

export const UserStatBoxValue = styled.div`
  display: flex;

  @media (max-width: ${GridVars.breakpoint.xs}px) {
    margin-top: 0;
  }
`;

export const UserStatBoxToken = styled(Text)`
  display: flex;
  align-items: center;
`;

export const Projection = styled.span`
  margin-right: ${GridVars.margin.m1};
`;

export const UserStatBoxTokenLabel = styled(TextLabel).attrs<TextLabelProps>({ size: 'large' })``;

export const UserStatBoxTokenAmount = styled(TextValue).attrs<TextLabelProps>({ size: 'large' })`
  padding-left: 0;
  width: 100%;
`;

export const UserStatBoxValueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: ${GridVars.breakpoint.sm}) {
    display: flex;
    justify-content: space-between;
  }
`;

export const UpdateButtonWrapper = styled.div`
  margin-top: ${GridVars.margin.m4};

  @media (max-width: ${GridVars.breakpoint.sm}) {
    margin-top: 0;
  }
`;

export const UpdateButton = styled(Button).attrs({
  $variant: 'secondary',
  $fluid: true
})`
  max-width: 156px;
  font-size: 0.8rem;
  height: 36px;

  @media (max-width: ${GridVars.breakpoint.sm}) {
    max-width: 110px;
    width: 110px;
    font-size: 0.7rem;
  }
`;

export { BoxHeader, BoxHeaderSquare };
