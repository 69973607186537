import React, { useState } from "react";
import { CustomModal } from "../index";
import { ModalHeader } from "../../modules/dashboard/modals/components";
import { AnimationDurationMs } from "../../utils";
import { ModalContent, ModalContentTab } from "../../utils/styles/modal.styles";
import { CustomModalProps } from "../custom-modal/custom-modal";

interface TabsModalProps extends Omit<CustomModalProps, 'children'> {
  children: (activeTab: string) => React.ReactNode;
  tabs: any;
  defaultTab: string
}
const TabsModal = ({ children, tabs, defaultTab, ...rest }: TabsModalProps) => {
  const [activeTab, setActiveTab] = useState<string>(defaultTab);
  const [isAnimating, setIsAnimating] = useState<boolean>(false);

  return (
    <CustomModal {...rest}>
      <ModalHeader tabs={tabs} onTabChanged={(tab) => {
        setActiveTab(tab)
        setIsAnimating(true)
        setTimeout(() => setIsAnimating(false), AnimationDurationMs.short)
      }} />
      <ModalContent>
        <ModalContentTab $animating={isAnimating}>
          {children(activeTab)}
        </ModalContentTab>
      </ModalContent>
    </CustomModal>
  );
};
export default TabsModal;
