import { createAsyncThunk } from "@reduxjs/toolkit";
import * as store from "../../../store";
import { selectUser } from "../../../store/user/user.slice";
import { selectVault } from "../../../store/vault/vault.slice";
import { TRANSACTION_STEPS } from "../../../utils";
import { sdk } from "../../../services/sdk-provider";

import { fetchUserVaultData } from "./fetchUserVaults";
import { TokenName, VaultName } from "@altitude-fi/altitude-v1-sdk/dist/types";
import { approveSpending, isNativeToken } from "../../../services/contracts/base";
import {BigNumber} from "ethers";

interface RepayPayload {
  repay: BigNumber;
  selectedVault: VaultName;
}

export const repay = createAsyncThunk<void, RepayPayload>(
  "userVault/repay",
  async ({ repay, selectedVault }: RepayPayload, thunkApi) => {
    const { vault, user } = store.default.getState();
    const { userAddress }: any = selectUser(user);
    const { borrowName, borrowSymbol }: any =
      selectVault(vault, selectedVault) || {};

    const txSendArgs: any = {
      from: userAddress,
    };

    const vaultContract = sdk.contracts.vaults[selectedVault].vault
    const tokenContract = sdk.contracts.tokens[borrowName as TokenName]
    try {
      if (isNativeToken(borrowSymbol)) {
        txSendArgs.value = repay;
      } else {
        await approveSpending(tokenContract, userAddress, vaultContract.address, repay);
      }
    } catch (e: any) {
      return thunkApi.rejectWithValue(e);
    }

    store.userVaultSetTransactionMode(TRANSACTION_STEPS.processing);
    try {
      await vaultContract.repay(
        repay,
        userAddress,
        txSendArgs
      );
    } catch (e: any) {
      return thunkApi.rejectWithValue(e);
    }

    thunkApi.dispatch(fetchUserVaultData({ selectedVault }));
  }
);
