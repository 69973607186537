import { ReactElement } from "react";

import {
  IconAltitude,
  SvgLogoAave,
  SvgLogoCompound,
  SvgLogoConvexFinance,
  LogoDai, LogoEth, LogoUsdc, LogoWbtc, LogoWstEth,
} from "../assets/images/iconComponents";

type options = { width: string; height: string };
export type Icon = "aave" | "convex" | "compound";

export const iconToSvg = (icon: Icon, options: options): ReactElement => {
  switch (icon) {
    case "convex":
      return (
        <SvgLogoConvexFinance width={options.width} height={options.height} />
      );
    case "aave":
      return <SvgLogoAave width={options.width} height={options.height} />;
    case "compound":
      return <SvgLogoCompound width={options.width} height={options.height} />;
    default:
      return <IconAltitude width={options.width} height={options.height} />;
  }
};

export const TokenLogoMapping: Record<string, JSX.Element> = {
  ETH: <LogoEth />,
  WSTETH: <LogoWstEth />,
  USDC: <LogoUsdc />,
  WBTC: <LogoWbtc />,
  DAI: <LogoDai />,
};
