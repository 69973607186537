import styled from "styled-components";
import { GridVars } from "./grid.styles";

export const LayoutSpaceBetweenCol = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`

export const LayoutSpaceBetweenRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
`

export const LayoutInlineFlexRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: ${GridVars.padding.p1};
`

export const LayoutFlexStart = styled.div`
    justify-content: flex-start;
`