import React from "react";
import styled from "styled-components";
import { GridVars, ThemeWarning } from "../../../../utils";
import { useUser } from "../../../../hooks/useAppSelector";

const ReadOnlyText = styled.div`
  margin-top: ${GridVars.margin.m1};
  text-align: center;
  font-size: 10px;
  color: ${ThemeWarning};
`

const ReadOnlyWarning = () => {
  const { data: { isReadOnly } = {} } = useUser();
  if (!isReadOnly) {
    return '';
  }

  return (
    <ReadOnlyText>Read-only mode. To perform transactions, please connect your wallet.</ReadOnlyText>
  )
};

export default ReadOnlyWarning;
