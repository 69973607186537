import { createAsyncThunk } from "@reduxjs/toolkit";
import Big from "big.js";
import { VaultHarvestModel } from "../../models";
import { getBlocksInfo } from "../../services/contracts/base";
import * as store from "../../store";
import { selectVault } from "../../store/vault/vault.slice";
import { VaultName } from "@altitude-fi/altitude-v1-sdk/dist/types";
import { sdk } from "../../services/sdk-provider";
import { isDevelopment } from "../../utils";
import { captureException } from "@sentry/react";

export const fetchVaultHarvestData = createAsyncThunk<
  VaultHarvestModel[],
  { selectedVault: VaultName }
>(
  "vaultHarvest/fetchVaultHarvest",
  async ({ selectedVault }: { selectedVault: VaultName }, thunkApi) => {
    try {
      const vaultHarvestSelected = await getVaultHarvest(selectedVault);

      return [vaultHarvestSelected];
    } catch (error: any) {

      const message = error.message;
      if (message !== "not enough harvests") {
        if (!isDevelopment()) {
          captureException(error)
        } else {
          thunkApi.dispatch(
            store.toastAdd({
              message: "Error fetching data",
              type: "error",
            })
          );
        }
      }
      return thunkApi.rejectWithValue(message);
    }
  }
);

const getVaultHarvest = async (vaultType: VaultName): Promise<VaultHarvestModel> => {
  const { vault} = store.default.getState();
  let { harvestsCount, vaultDecimals } = selectVault(vault, vaultType) || {};
  harvestsCount = harvestsCount || 0;

  if (harvestsCount < 3) {
    throw new Error("not enough harvests");
  }

  const multiVault = sdk.multi.vaults[vaultType];
  const [totalSnapshots, totalSupply, calcNewIndex] = await sdk.getMultiData([
    multiVault.vault.totalSnapshots(),
    multiVault.supplyToken.totalSupply(),
    multiVault.supplyToken.calcNewIndex(),
  ]);


  const [latestSnapshot, prevSnapshot] = await sdk.getMultiData([
    multiVault.vault.getSnapshot(totalSnapshots.toNumber() - 1),
    multiVault.vault.getSnapshot(totalSnapshots.toNumber() - 2),
  ]);


  const [latestHarvestValue, previousHarvestValue] = await sdk.getMultiData([
    multiVault.vault.getHarvest(harvestsCount - 1),
    multiVault.vault.getHarvest(harvestsCount - 2),
  ]);

  const [latestHarvestCirculatingSupply, previousHarvestCirculatingSupply] = [
    totalSupply
      .mul(latestSnapshot.supplyIndex)
      .div(calcNewIndex),
    totalSupply
      .mul(prevSnapshot.supplyIndex)
      .div(calcNewIndex),
  ]

  const blockInfos = await getBlocksInfo([
    previousHarvestValue.blockNumber.toNumber(),
    latestHarvestValue.blockNumber.toNumber(),
  ]);

  Big.DP = 0;
  const previousHarvest = {
    ...previousHarvestValue,
    supplyIndex: prevSnapshot.supplyIndex,
    borrowIndex: prevSnapshot.borrowIndex,
    vaultSupplyInBase: Big(previousHarvestCirculatingSupply)
      .times(Big(previousHarvestValue.price))
      .div(Big(vaultDecimals.supply))
      .toString(),
    datetime: Number(blockInfos[0]?.timestamp) * 1000,
  };
  const latestHarvest = {
    ...latestHarvestValue,
    supplyIndex: latestSnapshot.supplyIndex,
    borrowIndex: latestSnapshot.borrowIndex,
    vaultSupplyInBase: Big(latestHarvestCirculatingSupply)
      .times(Big(latestHarvestValue.price))
      .div(Big(vaultDecimals.supply))
      .toString(),
    datetime: Number(blockInfos[1]?.timestamp) * 1000,
  };

  const res: VaultHarvestModel = {
    id: vaultType,
    previousHarvest,
    latestHarvest,
  };

  return res;
};
