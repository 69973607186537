import React from "react";
import { ArrowRight } from "../../../../assets/images/iconComponents";
import { MoneyFormatter, PercentageFormatter } from "../../../../components";
import * as Styles from "../../../../modules/dashboard/modals/styles";

interface StatisticsRowProps {
  prefix?: string;
  postfix?: string;
  label: string;
  from: number;
  to: number;
  touched: boolean;
  forceRenderTo?: boolean;
}

export const StatisticsRow = ({
  postfix = "",
  prefix = "$",
  label,
  from,
  to,
  touched,
  forceRenderTo = false,
}: StatisticsRowProps) => {
  return (
    <Styles.StatisticsRow>
      <Styles.StatisticsRowLabel>{label}</Styles.StatisticsRowLabel>
      <Styles.StatisticsRowValues>
        <Styles.StatisticsRowValuesFrom>
          {formatter(from, prefix || postfix)}
          <span>{postfix}</span>
        </Styles.StatisticsRowValuesFrom>
        {((to !== from && touched) || forceRenderTo) && (
          <>
            <ArrowRight />
            <Styles.StatisticsRowValuesTo>
              {formatter(to, prefix || postfix)}
              <span>{postfix}</span>
            </Styles.StatisticsRowValuesTo>
          </>
        )}
      </Styles.StatisticsRowValues>
    </Styles.StatisticsRow>
  );
};

const formatter = (value: number, sign: string) => {
  switch (sign) {
    case "%":
      return (
        <span>
          <PercentageFormatter percentage={value} fractionDigits={2} />
        </span>
      );
    case "$":
    default:
      return <MoneyFormatter value={value} prefix={sign} />;
  }
};
