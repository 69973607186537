import styled from "styled-components";
import {
  ThemeGray500,
  GridVars,
  ThemePrimaryText,
} from "../../utils";
import {Button} from "../../utils/styles/button";
import {AnimationFadeIn, AnimationFadeOut, AnimationDuration} from "../../utils/styles/animation.styles";

interface DropDownMenuWrapperProps {
  readonly $show: boolean;
}

export const DropDownMenuIcon = styled.div`
  color: ${ThemePrimaryText};
  display: block;
  font-size: 2.4rem;
  position: absolute;
  top: -${GridVars.padding.p1};
  right: -${GridVars.padding.p1};
  cursor: pointer;
`;

export const DropDownMenuWrapper = styled.div<DropDownMenuWrapperProps>`
  gap: ${GridVars.margin.m1};
  display: flex;
  flex-direction: column;
  padding: ${GridVars.padding.p1};
  position: absolute;

  right: 0;
  top: ${GridVars.padding.p3};

  border-radius: 4px;
  background: ${ThemeGray500};
  box-shadow: 0 4px 4px 0 #00000040;

  visibility: ${(props) => (props.$show ? "visible" : "hidden")};
  transition-property: visibility;
  transition-delay:  ${(props) => (props.$show ? "0s" : AnimationDuration.short)};
  transition-duration: 0s;
  ${props => props.$show ? AnimationFadeIn: AnimationFadeOut};
`;

export const DropDownMenuItem = styled(Button).attrs({
  $variant: 'menu',
  $size: 'compact'
})`

`;

