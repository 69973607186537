import { createAsyncThunk } from "@reduxjs/toolkit";
import { UserVaultModel } from "../../../models";
import { isExistingVault } from "../../../services/contracts/base";
import { toastAdd, toastRemove } from "../../../store";
import * as store from "../../../store";
import { selectUser } from "../../../store/user/user.slice";
import { selectVault } from "../../../store/vault/vault.slice";

import { sdk } from "../../../services/sdk-provider";
import { TokenName, VaultName } from "@altitude-fi/altitude-v1-sdk/dist/types";
import { isDevelopment } from "../../../utils";
import { captureException } from "@sentry/react";

export const fetchUserVaultData = createAsyncThunk<
  UserVaultModel[],
  { selectedVault: VaultName }
>(
  "userVault/fetchUserVaults",
  async ({ selectedVault }: { selectedVault: VaultName }, thunkApi) => {
    try {
      const userVaultSelected = await getUserVaultData(selectedVault);
      if (userVaultSelected.ethAmount === 0) {
        thunkApi.dispatch(
          toastAdd({
            id: "no-eth-in-wallet",
            message: "User connected but no Ether in wallet to pay for gas",
            type: "warn",
          })
        );
      } else {
        thunkApi.dispatch(toastRemove("no-eth-in-wallet"));
      }
      if (userVaultSelected.isActionsByAllowlistBlocked) {
        thunkApi.dispatch(
          toastAdd({
            id: "address-not-in-allow-list",
            message: "Address connected is not in the allow list",
            type: "warn",
          })
        );
      } else {
        thunkApi.dispatch(toastRemove("address-not-in-allow-list"));
      }

      thunkApi.dispatch(toastRemove('error-fetching-user-vault-data'))
      return [userVaultSelected];
    } catch (error: any) {
      console.log(error)
      if (!isDevelopment()) {
        captureException(error)
      } else {
        thunkApi.dispatch(
          toastAdd({
            id: 'error-fetching-user-vault-data',
            message: "Error fetching data",
            type: "error",
          })
        );
      }
      const message = error.message;
      return thunkApi.rejectWithValue(message);
    }
  }
);

const getUserVaultData = async (vaultType: VaultName): Promise<UserVaultModel> => {
  const { user, vault } = store.default.getState();
  const { userAddress } = selectUser(user) || {};
  const { supplyName, borrowName, supplyDecimals, borrowDecimals } =
    selectVault(vault, vaultType) || {};

  isExistingVault(vaultType);

  const vaultContracts = sdk.multi.vaults[vaultType]
  const [
    ethAmountBn,
    supplyTokenAmountBn,
    borrowTokenAmountBn,
    collateralTokenAmountBn,
    debtTokenAmountBn,
    harvestable,
    allowList,
    claimableRewardsValue,
  ] = await sdk.getMultiData([
    sdk.getProvider().getBalance(userAddress),
    sdk.multi.tokens[supplyName as TokenName].balanceOf(userAddress),
    sdk.multi.tokens[borrowName as TokenName].balanceOf(userAddress),
    vaultContracts.supplyToken.balanceOf(userAddress),
    vaultContracts.borrowToken.balanceOf(userAddress),
    vaultContracts.vault.getUserHarvest(userAddress),
    vaultContracts.ingressControl.allowList(userAddress),
    vaultContracts.vault.claimableRewards(userAddress),
  ]);

  const ethAmount = sdk.utils.convertFromWei(ethAmountBn, 18);
  const supplyTokenAmount = sdk.utils.convertFromWei(supplyTokenAmountBn, supplyDecimals);
  const collateralTokenAmount: any = sdk.utils.convertFromWei(
    collateralTokenAmountBn,
    supplyDecimals
  );
  const borrowTokenAmount = sdk.utils.convertFromWei(borrowTokenAmountBn, borrowDecimals);
  const debtTokenAmount = sdk.utils.convertFromWei(debtTokenAmountBn, borrowDecimals);

  const claimableEarnings = sdk.utils.convertFromWei(harvestable.claimableEarnings, borrowDecimals);
  const claimableRewards = sdk.utils.convertFromWei(claimableRewardsValue, borrowDecimals);

  return {
    id: vaultType,

    ethAmount,
    supplyTokenAmount,
    borrowTokenAmount,
    collateralTokenAmount,
    debtTokenAmount,

    ethAmountBn,
    supplyTokenAmountBn,
    borrowTokenAmountBn,
    collateralTokenAmountBn,
    debtTokenAmountBn,

    claimableEarnings,
    claimableRewards,
    harvestJoiningBlock: harvestable.harvestJoiningBlock.toNumber(),
    isActionsByAllowlistBlocked: !allowList,
  };
};
