import styled from "styled-components";

export const GridVars = {
  gap: "16px",
  modalWidth: "450px",
  margin: {
    m0: "0px",
    m1: "8px",
    m2: "16px",
    m3: "24px",
    m4: "36px",
    m5: "48px",
  },
  padding: {
    p0: "0px",
    p1: "8px",
    p2: "16px",
    p3: "24px",
    p4: "36px",
    p5: "48px",
  },
  breakpoint: {
    xxl: "1400px",
    xl: "1200px",
    lg: "992px",
    md: "768px",
    sm: "576px",
    xs: "356px",
    xxs: "0px",
  },
};

export const Grid = styled.div`
  display: grid;
  grid-gap: ${GridVars.gap};
  max-width: ${GridVars.breakpoint.xxl};
`;
