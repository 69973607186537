import React from "react";

import { InfoPopover, moneyFormatterLogic } from "../../../../../components";
import * as Styles from "../../../../../modules/dashboard/components/borrowing-capacity/style";

import { BorrowingCapacityPopoverPopoverElement } from "./popover.element";

interface CapturedValueBoxProps {
  caption: string;
  value: number;
  x: number;
  y: number;
  valuePrefix?: string;
  popupMessage?: string;
  popupLeft?: number;
}

const CapturedValueBox = ({
  caption,
  value,
  x,
  y,
  valuePrefix = "",
  popupMessage = "",
  popupLeft = 0,
}: CapturedValueBoxProps) => {
  const formattedValue = moneyFormatterLogic({
    prefix: "",
    value,
  });

  return (
    <svg x={x} y={y} overflow="visible">
      <Styles.CapturedValueCaption y={10}>
        {caption}
      </Styles.CapturedValueCaption>
      {!!popupMessage && (
        <foreignObject width={29} height={23} x={popupLeft} y={0}>
          <Styles.BorrowingCapacityPopover>
            <InfoPopover>
              <BorrowingCapacityPopoverPopoverElement message={popupMessage} />
            </InfoPopover>
          </Styles.BorrowingCapacityPopover>
        </foreignObject>
      )}
      <Styles.CapturedValueValue y={28}>
        {valuePrefix}
        {formattedValue}
      </Styles.CapturedValueValue>
    </svg>
  );
};

interface CapturedBoxStickProps {
  x: number;
  y: number;
  value: number;
  caption: string;
  valuePrefix?: string;
  lineY: number;
  lineLength: number;
  popupMessage?: string;
  popupLeft?: number;
  stopColor: number[];
  visible: boolean;
}

export const CapturedBoxStick = ({
  x,
  y,
  value,
  lineY,
  caption,
  lineLength,
  valuePrefix,
  stopColor,
  popupMessage,
  popupLeft,
  visible,
}: CapturedBoxStickProps) => (
  <Styles.CapturedBoxStick
    id={`capturedBoxStick_${x}_${y}`}
    x={x - lineLength}
    y={y}
    height="30"
    fill="none"
    overflow="visible"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      display: visible ? "block" : "none",
    }}
  >
    <defs>
      <linearGradient
        id={`stickGradient_${x}_${y}`}
        x1="0"
        y1="0"
        x2={lineLength}
        y2="0"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor={`rgba(${stopColor[0]}, ${stopColor[1]}, ${stopColor[2]}, 0)`}
          offset="0"
        />
        <stop
          stopColor={`rgba(${stopColor[0]}, ${stopColor[1]}, ${stopColor[2]}, 1)`}
          offset="1"
        />
      </linearGradient>
    </defs>
    <rect
      x={0}
      y={lineY}
      width={lineLength}
      height="2"
      rx="1"
      fill={`url(#stickGradient_${x}_${y})`}
      radius="10"
    />

    <CapturedValueBox
      popupMessage={popupMessage}
      popupLeft={popupLeft}
      valuePrefix={valuePrefix}
      caption={caption}
      value={value}
      x={lineLength + 8}
      y={0}
    />
  </Styles.CapturedBoxStick>
);
