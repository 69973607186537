import {
  CopyIcon,
  ExternalLink,
} from "../../../../assets/images/iconComponents";
import CustomPopover from "../../../../components/custom-popover";
import * as PopoverStyles from "../../../../components/custom-popover/styles";
import { useAppSelector } from "../../../../hooks";
import { selectUser } from "../../../../store/user/user.slice";
import {
  copyTextToClipboard,
  ElementInlineIcon,
  getEtherScanLink,
  getWalletIcon,
  getWalletName,
  useWallet,
} from "../../../../utils";

import * as Styles from "./style";

const SelectedWallet = () => {
  const userStore = useAppSelector((state) => state.user);
  const { disconnect } = useWallet();
  const { userAddress, selectedWallet } = selectUser(userStore) || {};

  return (
    <Styles.SelectedContainer data-testid="selectedWallet">
      <Styles.SelectedHeader>Your Wallet</Styles.SelectedHeader>
      <Styles.WalletName>
        Connected with {getWalletName(selectedWallet)}
      </Styles.WalletName>
      <Styles.AddressContainer>
        <Styles.UserAddress>
          {`${userAddress.slice(0, 21)}...${userAddress.slice(-3)}`}
        </Styles.UserAddress>
        {getWalletIcon(selectedWallet)}
      </Styles.AddressContainer>
      <Styles.AddressActionContainer>
        <Styles.AddressActionItem
          onClick={() =>
            window.open(`${getEtherScanLink()}address/${userAddress}`, "_blank")
          }
        >
          <ElementInlineIcon>
            <ExternalLink />
          </ElementInlineIcon>
          View on Etherscan
        </Styles.AddressActionItem>

        <CustomPopover
          marginTop="0px"
          button={
            <Styles.AddressActionItem
              onClick={() => copyTextToClipboard(userAddress)}
            >
              <ElementInlineIcon>
                <CopyIcon />
              </ElementInlineIcon>
              Copy Address
            </Styles.AddressActionItem>
          }
        >
          <PopoverStyles.Paragraph>{`Copied!`}</PopoverStyles.Paragraph>
        </CustomPopover>
      </Styles.AddressActionContainer>
      <Styles.DisconnectBtn
        data-testid="disconnect-wallet"
        onClick={disconnect}
      >
        Disconnect Wallet
      </Styles.DisconnectBtn>
    </Styles.SelectedContainer>
  );
};

export default SelectedWallet;
