import styled from "styled-components";

import { GridVars } from "./grid.styles";
import { ThemePrimaryText } from "./theme.styles";

export const ElementHR = styled.hr`
  color: ${ThemePrimaryText};
  opacity: 0.1;
  margin: ${GridVars.margin.m3} 0;
`;

export const ElementInlineIcon = styled.div`
  margin: 0 4px;
  display: inherit;
`;
