import {
  createSlice,
  PayloadAction,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import { DateTime } from "luxon";
import { VaultModel } from "../../models";

import { fetchVaultsSupplyBasePrice, fetchVaultsData } from "./actions";
import { VaultName } from "@altitude-fi/altitude-v1-sdk/dist/types";
import { isDevelopment } from "../../utils";

const vaultAdapter = createEntityAdapter<VaultModel>({
  selectId: (vault) => vault.id,
});

const vaultSlice = createSlice({
  name: "vault",
  initialState: vaultAdapter.getInitialState({
    vaultLoading: false
  }),
  reducers: {
    vaultSet: vaultAdapter.setOne,
    vaultUpdate: vaultAdapter.updateOne,
    vaultReceived(state, action) {
      vaultAdapter.setAll(state, action.payload.vault);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchVaultsData.pending, (state, action) => {
        state.vaultLoading = true;
      })
      .addCase(
        fetchVaultsData.fulfilled,
        (state, action: PayloadAction<VaultModel[]>) => {
          vaultAdapter.setAll(state, action.payload);
          state.vaultLoading = false;
        }
      )
      .addCase(
        fetchVaultsData.rejected,
        (state, action: PayloadAction<any>) => {
          state.vaultLoading = false;
        }
      )
      .addCase(
        fetchVaultsSupplyBasePrice.fulfilled,
        (
          state,
          action: PayloadAction<
            Array<{
              vaultType: VaultName;
              price: number;
              borrowToUsdRatio: number;
            }>
          >
        ) => {
          let devPrice = 0;
          if (isDevelopment()) {
            devPrice = DateTime.now().second;
          }
          for (const res of action.payload) {
            vaultAdapter.updateOne(state, {
              payload: {
                id: res.vaultType,
                changes: {
                  supplyBasePrice: res.price + devPrice,
                  borrowToUsdRatio: res.borrowToUsdRatio,
                },
              },
              type: action.type,
            });
          }
        }
      );
  },
});

// Selectors
const { selectAll, selectById } = vaultAdapter.getSelectors();
export const selectAllVaults = selectAll;
export const selectVault = selectById;

export default vaultSlice;
