import styled from "styled-components";
import { GridVars, Grid } from "../../utils";
import { Page } from "../../utils/styles/page.styles";

interface DashboardGridProps {
  readonly $banner?: boolean;
}

export const DashboardNavPlaceholder = styled.div`
  height: 47px;
  position: fixed;
`;

export const DashboardPage = styled(Page)``;

export const DashboardGrid = styled(Grid)<DashboardGridProps>`
  ${(props) =>
    props.$banner
      ? `
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, 0.1fr);
    grid-template-areas:
      "banner banner smartRepayments"
      "borrowingCapacity userStats smartRepayments"
      "borrowingCapacity userLoanStats smartRepayments"
      "vaultComposition vaultComposition smartRepayments";
  `
      : `
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 0.1fr);
    grid-template-areas:
      "borrowingCapacity userStats smartRepayments"
      "borrowingCapacity userLoanStats smartRepayments"
      "vaultComposition vaultComposition smartRepayments";
  `}

  @media (max-width: ${GridVars.breakpoint.xl}) {
    ${(props) =>
      props.$banner
        ? `
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(4, 0.1fr);
      grid-template-areas:
        "banner banner"
        "borrowingCapacity userStats"
        "borrowingCapacity userLoanStats"
        "smartRepayments vaultComposition";
    `
        : `
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(3, 0.1fr);
      grid-template-areas:
        "borrowingCapacity userStats"
        "borrowingCapacity userLoanStats"
        "smartRepayments vaultComposition";
    `}
  }

  @media (max-width: ${GridVars.breakpoint.lg}) {
    ${(props) =>
      props.$banner
        ? `
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(6, 0.1fr);
      grid-template-areas:
        "banner"
        "borrowingCapacity"
        "userStats "
        "userLoanStats"
        "smartRepayments "
        "vaultComposition ";
    `
        : `
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(5, 0.1fr);
      grid-template-areas:
        "borrowingCapacity"
        "userStats "
        "userLoanStats"
        "smartRepayments "
        "vaultComposition ";
    `}
  }
`;
