import * as Styles from "../../modules/dashboard/modals/styles";
import { NumericFormat } from "react-number-format";

import moneyFormatterLogic from "./money-formatter.logic";

interface MoneyFormatterProps {
  className?: string;
  prefix?: string;
  value: number;
}

const MoneyFormatter = ({ className, prefix, value }: MoneyFormatterProps) => {
  const formattedValue = moneyFormatterLogic({
    prefix,
    value,
  });
  if (value > 99999 || isNaN(value)) {
    return <span className={"money-formatter"}>{formattedValue}</span>;
  }

  return (
    <Styles.NoWrap className={className}>
      <NumericFormat
        className={"money-formatter"}
        thousandSeparator={true}
        displayType={"text"}
        decimalScale={2}
        prefix={prefix}
        value={formattedValue}
      />
    </Styles.NoWrap>
  );
};

export default MoneyFormatter;
