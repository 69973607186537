import React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";

import { TokenInput } from "../../../../../components";
import { useAppDispatch } from "../../../../../hooks";
import { useAppSelector } from "../../../../../hooks";
import { UserVaultModel } from "../../../../../models";
import { VaultModel } from "../../../../../models/vault";
import {
  BarChart,
  ModalStyles,
  StatisticsRow,
  withdrawInputValidator,
} from "../../../../../modules/dashboard/modals";
import { withdraw } from "../../../../../store/userVault/actions";
import { selectUserVault } from "../../../../../store/userVault/userVault.slice";
import { selectVault } from "../../../../../store/vault/vault.slice";
import { selectUser } from "../../../../../store/user/user.slice";
import { BLOCK_TIME, parseValue } from "../../../../../utils";
import { useDebounce } from "../../../../../hooks/useDebounce";
import { useAccount } from "wagmi";
import { BigNumber, constants, utils } from "ethers";
import { UserPosition, useUserPosition } from "../../../../../hooks/useUserPosition";
import { useSdk } from "../../../../../services/sdk-provider";
import { getLiquidationAmount } from "../../components/bar-chart/bar-chart.logic";
import { useUser } from "../../../../../hooks/useAppSelector";
import ReadOnlyWarning from "../../components/read-only-warning";
import { formatUnits, parseEther, parseUnits } from "ethers/lib/utils";
import { ValidatorFunction } from "../../../../../components/token-input/token-input";

// This margin is used to calculate additional withdrawal amount in %.
const OVER_WITHDRAW_MARGIN = parseEther('0.0000002');
const WITHDRAW_MARGIN = parseEther('0.00004');

export const WithdrawTab = () => {
  const dispatch = useAppDispatch();

  const user = useAppSelector((state) => state.user);
  const vaultStore = useAppSelector((state) => state.vault);
  const userVaultStore = useAppSelector((state) => state.userVault);
  const { selectedVault } = useAppSelector((state) => state.global);
  const { data: { isReadOnly } = {} } = useUser();

  const {
    id: vaultId,
    supplyBasePrice,
    supplyThreshold,
    supplyThresholdBn,
    supplyBasePriceBn,
    borrowToUsdRatio,
    liquidationThreshold,
    supplySymbol,
    borrowSymbol,
    supplyDecimals,
    supplyPrecision,
    borrowDecimals
  }: VaultModel = selectVault(vaultStore, selectedVault) || ({} as VaultModel);
  const {
    collateralTokenAmount: collateralTokenAmountNumber,
    collateralTokenAmountBn: collateralTokenAmount,
    debtTokenAmount: debtTokenAmountNumber,
    debtTokenAmountBn: debtTokenAmount,
  }: UserVaultModel =
    selectUserVault(userVaultStore, selectedVault) || ({} as UserVaultModel);
  const { userAddress }: any = selectUser(user);
  const { data: userPosition } = useUserPosition();
  const {
    net: { netApy },
    current: { borrowLimit, borrowingCapacity},
    projected: { monthlyRepayments,  }
  } = userPosition as UserPosition;

  const [withdrawAmount, setWithdrawAmount] = useState<BigNumber>(constants.Zero);
  const [withdrawFeeFactor, setWithdrawFeeFactor] = useState<BigNumber>(constants.Zero)
  const [withdrawFeeAmount, setWithdrawFeeAmount] = useState<BigNumber>(constants.Zero)
  const [withdrawFeeExpiresAfter, setWithdrawFeeExpiresAfter] = useState<BigNumber>(constants.Zero);
  const [isTouched, setIsTouched] = useState(false);
  const debouncedWithdrawAmount = useDebounce<BigNumber>(withdrawAmount, 500);
  const { chain } = useAccount();
  const { sdk } = useSdk();
  const newCollateralAmount = useMemo(() => {
    const newAmount = collateralTokenAmount.sub(debouncedWithdrawAmount);
    if (newAmount.lte(0)) {
      return constants.Zero;
    }
    return newAmount;
  }, [debouncedWithdrawAmount, collateralTokenAmount]);

  const { data: futureUserPosition} = useUserPosition(debtTokenAmount, newCollateralAmount);
  const {
    net: { netApy: futureNetAPY },
    current: {
      liquidationLimit: newLiquidationLimit,
      borrowLimit: newBorrowLimit,
      borrowingCapacity: futureBorrowingCapacityPercentage,
    },
    projected: { monthlyRepayments: futureMonthlyRepayment,  }
  } = futureUserPosition as UserPosition;

  const allowedWithdrawAmountBn = useMemo(() => {
    if (debtTokenAmount.eq(0)) {
      const addition = collateralTokenAmount.mul(OVER_WITHDRAW_MARGIN).div(constants.WeiPerEther);
      return collateralTokenAmount.add(addition);
    } else {
      const keepAmountInToken = debtTokenAmount
        .mul(constants.WeiPerEther)
        .div(supplyThresholdBn)
        .mul(parseUnits('1', borrowDecimals))
        .div(supplyBasePriceBn);
      const keepAmount = formatUnits(keepAmountInToken, borrowDecimals);
      const keepAmountInDecimals = parseUnits(keepAmount, supplyDecimals);
      const allowedAmount = collateralTokenAmount.sub(keepAmountInDecimals);
      const marginAmount = allowedAmount.mul(WITHDRAW_MARGIN).div(constants.WeiPerEther);
      console.log(marginAmount.toString(), allowedAmount.toString())
      const allowedAmountWithMargin = allowedAmount.sub(marginAmount);

      return allowedAmountWithMargin.gt(0) ? allowedAmountWithMargin : constants.Zero;
    }
  }, [debtTokenAmount, supplyThresholdBn, supplyBasePriceBn, collateralTokenAmount, supplyDecimals, borrowDecimals]);

  const calcWithdrawFee = useCallback(async (amount: BigNumber) => {
    if (!amount || amount.eq(0)) {
      setWithdrawFeeAmount(constants.Zero)
      setWithdrawFeeExpiresAfter(constants.Zero)
      return
    }
    const [{ withdrawFee, feeExpiresAfter }, withdrawFeeFactor] = await sdk.getMultiData([
      sdk.multi.vaults[vaultId].vault.calcWithdrawFee(userAddress, amount),
      sdk.multi.vaults[vaultId].vault.withdrawFeeFactor(),
    ]);
    setWithdrawFeeFactor(withdrawFeeFactor)
    setWithdrawFeeAmount(withdrawFee)
    setWithdrawFeeExpiresAfter(feeExpiresAfter)
  }, [userAddress, vaultId, sdk]);

  useEffect(() => {
    calcWithdrawFee(debouncedWithdrawAmount)
  }, [debouncedWithdrawAmount, calcWithdrawFee]);

  const withdrawFeeWarning = useMemo(() => {
    if (withdrawFeeAmount.eq(0) || !chain) {
      return '';
    }
    const feeFactorPercentage = sdk.utils.convertFromWei(withdrawFeeFactor.mul(100).toString(), 18);
    const expiresInMs = withdrawFeeExpiresAfter.mul(BLOCK_TIME[chain.id]).mul(1000);
    const formattedDate = expiresInMs.gt(0) ? new Date(new Date().getTime() + expiresInMs.toNumber()).toLocaleDateString() : '';
    return `The withdraw is a subject to a fee of ${utils.formatUnits(withdrawFeeAmount, supplyDecimals)} ${supplySymbol} (${feeFactorPercentage}%) until ${formattedDate}`;
  }, [withdrawFeeAmount, chain, sdk.utils, withdrawFeeFactor, withdrawFeeExpiresAfter, supplyDecimals, supplySymbol])

  const withdrawHandler = useCallback(() => {
    // To ensure the user doesn't have dust funds we sligltly increase the withdrawal amount.
    dispatch(withdraw({ withdraw: withdrawAmount, selectedVault }));
  }, [dispatch, selectedVault, withdrawAmount]);

  const onWithdrawAmountChangeHandler = useCallback((value?: BigNumber) => {
    setIsTouched(!!value);
    setWithdrawAmount(value || constants.Zero);
  }, []);

  const [isValid, setIsValid] = useState(false);
  const withdrawValidator = useCallback<ValidatorFunction>(
    (value) => {
      const valueBn = parseValue(value, supplyDecimals);
      const validatorResult = withdrawInputValidator(
        valueBn,
        allowedWithdrawAmountBn,
        selectedVault,
        supplyDecimals
      );
      setIsValid(!validatorResult);
      return validatorResult;
    },
    [allowedWithdrawAmountBn, supplyDecimals, selectedVault]
  );

  const liquidationPrice = useCallback((collateral: number) => {
      return getLiquidationAmount(debtTokenAmountNumber, liquidationThreshold, collateral) * borrowToUsdRatio;
  }, [debtTokenAmountNumber, liquidationThreshold, borrowToUsdRatio]);

  const isWithdrawButtonDisabled = useMemo(() => isReadOnly || !isValid || withdrawAmount.lte(0), [withdrawAmount, isReadOnly, isValid])

  return (
    <>
      <ModalStyles.AmountContainer data-testid="withdraw-amount-input">
        <TokenInput
          tokenName={supplySymbol}
          name={supplySymbol + "Amount"}
          label="Withdraw Amount"
          warning={withdrawFeeWarning}
          validator={withdrawValidator}
          maxValue={allowedWithdrawAmountBn}
          precision={supplyPrecision}
          balanceLabel="collateral"
          currencyExchangeRate={supplyBasePrice * borrowToUsdRatio}
          onValueChange={onWithdrawAmountChangeHandler}
        />
      </ModalStyles.AmountContainer>
      <ModalStyles.StatisticsContainer>
        <BarChart
          to={futureBorrowingCapacityPercentage}
          from={borrowingCapacity}
          borrowLimit={newBorrowLimit}
          balance={debtTokenAmountNumber}
          collateral={+formatUnits(newCollateralAmount, supplyDecimals)}
          liquidation={newLiquidationLimit}
          liquidationThreshold={liquidationThreshold}
          supplyThreshold={supplyThreshold}
          borrowToUsdRatio={borrowToUsdRatio}
          borrowName={borrowSymbol + " "}
          supplySymbol={supplySymbol}
        />
        <StatisticsRow
          touched={isTouched}
          label="Deposit amount"
          from={collateralTokenAmountNumber}
          to={+formatUnits(newCollateralAmount, supplyDecimals)}
          prefix={supplySymbol + " "}
        />
        <StatisticsRow
          touched={isTouched}
          label="Borrow limit"
          from={borrowLimit}
          to={newBorrowLimit}
          prefix={borrowSymbol + " "}
        />
        <StatisticsRow
          touched={isTouched}
          label="Liquidation price"
          from={liquidationPrice(collateralTokenAmountNumber)}
          to={liquidationPrice(+formatUnits(newCollateralAmount, supplyDecimals))}
          prefix={`${supplySymbol}: $`}
        />
        <ModalStyles.StatisticsRowDivider />
        <StatisticsRow
          touched={isTouched}
          label="Estimated Monthly Repayments"
          from={monthlyRepayments || 0}
          to={futureMonthlyRepayment}
          prefix={borrowSymbol + " "}
        />
        <StatisticsRow
          touched={isTouched}
          label="Net APY"
          from={netApy || 0}
          to={futureNetAPY}
          postfix={"%"}
          prefix={""}
        />
        <ModalStyles.TakeLoanBtn
          onClick={withdrawHandler}
          disabled={isWithdrawButtonDisabled}
          data-testid="withdraw-action-btn"
        >
          Withdraw
        </ModalStyles.TakeLoanBtn>
        <ReadOnlyWarning />
      </ModalStyles.StatisticsContainer>
    </>
  );
};
