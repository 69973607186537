import Big from "big.js";
import { Event } from "ethers";

export const rateToApy = (rate: number, ratesPerYear: number) => {
  // APY = ((((Rate / ETH Mantissa * Blocks Per Day + 1) ^ Days Per Year)) - 1) * 100
  return Math.sign(rate) * (Math.pow(1 + Math.abs(rate), ratesPerYear) - 1);
};


export const getEstimatedAverages = (harvestA: any, harvestB: any, events: Event[], decimals: any) => {
  /**
   * Calculate the average supply, user borrows, active assets and actual active assets
   * for the period between harvestA and harvestB
   * @param harvestA - Harvest data from the start of the period
   * @param harvestB - Harvest data from the end of the period
   * @param events - Events that occurred during the period
   * @param decimals - Decimals for the vault
   * @returns - Object containing the average supply, user borrows, active assets and actual active assets
   * @dev TODO: This function should moved to the backend
  */
  try {

    // Calculate average thresholds for the period.
    // @dev Changes will be infrequent and small in size, so we can assume the average
    const divertThreshold = Big(harvestA.divertEarningsThreshold)
      .add(harvestB.divertEarningsThreshold)
      .div(2);
    const activeThreshold = Big(harvestA.activeAssetsThreshold)
      .add(harvestB.activeAssetsThreshold)
      .div(2);

    // Parse events into a new array

    const tmpEvents = JSON.parse(JSON.stringify(events));
    // To enable calculations until the end of the harvest, add a new event at the end
    tmpEvents.push({
      blockNumber: Number(harvestB.blockNumber),
      event: "AnswerUpdated",
      returnValues: {
        current: Big(decimals.supply * decimals.borrow)
          .div(harvestB.price)
          .toFixed(0),
      },
    });

    // Set initial values of the previous event
    let previousEvent = {
      supply: Big(harvestA.vaultSupplyInBase).times(decimals.supply).div(harvestA.price), // supply = vaultSupplyInBase / price
      userBorrows: Big(harvestA.vaultSupplyInBase) // userBorrows = vaultSupplyInBase * activeAssetsThreshold - vaultActiveAssets
        .times(Big(harvestA.activeAssetsThreshold).div(decimals.supply))
        .minus(harvestA.vaultActiveAssets),
      price: Big(decimals.supply * decimals.borrow).div(harvestA.price), // price (converted to ccorret direction)
      blockNumber: Big(harvestA.blockNumber),
    };

    // Set initial totals
    let totals = {
      supplyInBase: Big(harvestA.vaultSupplyInBase),
      userBorrows: previousEvent.userBorrows,
      // Amount that can be deployed for farming.
      // activeAssets = vaultSupplyInBase * activeAssetsThreshold - userBorrows
      activeAssets: Big(harvestA.vaultSupplyInBase)
        .times(activeThreshold)
        .div(decimals.supply)
        .minus(previousEvent.userBorrows),
      // Amount used in distribution calculations.
      // actualActiveAssets = vaultSupplyInBase * divertEarningsThreshold - userBorrows
      actualActiveAssets: Big(harvestA.vaultSupplyInBase)
        .times(divertThreshold)
        .div(decimals.supply)
        .minus(previousEvent.userBorrows),
      blocks: Big(0),
    };

    // Loop over each event and calculate the averages in each period
    let event;
    for (let i = 0; i < tmpEvents.length; i++) {

      // Reset the event details
      event = { ...previousEvent };
      event.blockNumber = Big(tmpEvents[i].blockNumber);
      const blocksElapsed = event.blockNumber.minus(previousEvent.blockNumber);

      // Update event details based on the event type
      switch (tmpEvents[i].event) {
        case "AnswerUpdated":
          event.price = Big(tmpEvents[i].returnValues.current);
          break;
        case "Deposit":
          event.supply = event.supply.add(tmpEvents[i].returnValues.amount);
          break;
        case "Withdraw":
          event.supply = event.supply.sub(tmpEvents[i].returnValues.amount);
          break;
        case "Borrow":
          event.userBorrows = event.userBorrows.add(tmpEvents[i].returnValues.amount);
          break;
        case "Repay":
          event.userBorrows = event.userBorrows.sub(tmpEvents[i].returnValues.amount);
          break;
        // TODO: To confirm how we should treat claimable rewards
        // case('ClaimedRewards'):
        //     event.userBorrows = event.userBorrows.sub(tmpEvents[i].returnValues.amount)
        //     console.log('Withdraw', event.userBorrows.toFixed(5))
        //     break
      }

      // Calculate averages for the period between two events
      const averageSupply = previousEvent.supply.add(event.supply).div(2);
      const averageSupplyInBase = averageSupply.times(decimals.borrow).div(event.price);
      const averageUserBorrows = previousEvent.userBorrows.add(event.userBorrows).div(2);
      const averageActiveAssets = averageSupplyInBase
        .times(activeThreshold)
        .div(decimals.supply)
        .minus(averageUserBorrows);
      const averageActualActiveAssets = averageSupplyInBase
        .times(divertThreshold)
        .div(decimals.supply)
        .minus(averageUserBorrows);

      // Add averages to the totals
      totals = {
        supplyInBase: totals.supplyInBase.add(averageSupplyInBase.times(blocksElapsed)),
        userBorrows: totals.userBorrows.add(averageUserBorrows.times(blocksElapsed)),
        activeAssets: totals.activeAssets.add(averageActiveAssets.times(blocksElapsed)),
        actualActiveAssets: totals.actualActiveAssets.add(
          averageActualActiveAssets.times(blocksElapsed)
        ),
        blocks: totals.blocks.add(blocksElapsed),
      };

      // Update the previous event
      previousEvent = { ...event };
    }

    // Calculate averages for the period between harvestA and harvestB
    const averages = {
      averageSupplyInBase: totals.supplyInBase.div(totals.blocks).div(decimals.borrow).toNumber(),
      averageUserBorrows: totals.userBorrows.div(totals.blocks).div(decimals.borrow).toNumber(),
      averageActiveAssets: totals.activeAssets.div(totals.blocks).div(decimals.borrow).toNumber(),
      averageActualActiveAssets: totals.actualActiveAssets
        .div(totals.blocks)
        .div(decimals.borrow)
        .toNumber(),
    };
    return averages;
  } catch (e) {
    console.error(e);

    // TODO we could consider a simplified fallback here
    // e.g. ( harvestB.vaultSupplyInBase - harvestA.vaultSupplyInBase ) / 2 = averageVaultSupplyInBase
    return {
      averageSupplyInBase: 0,
      averageUserBorrows: 0,
      averageActiveAssets: 0,
      averageActualActiveAssets: 0,
    }

  }
};

const parseHarvest = (harvest: any, vaultDecimals: any) => {
  /**
   * Normalise the harvest data
   * @param harvest - Harvest data
   * @param vaultDecimals - Decimals for the vault
   * @returns - Object containing the normalised harvest data
  */
  return {
    divertEarningsThreshold: Big(harvest.divertEarningsThreshold)
      .div(vaultDecimals.supply)
      .toNumber(),
    activeAssetsThreshold: Big(harvest.activeAssetsThreshold).div(vaultDecimals.supply).toNumber(),
    datetime: harvest.datetime,
    supplyIndex: Big(harvest.supplyIndex).div(vaultDecimals.supply).toNumber(),
    borrowIndex: Big(harvest.borrowIndex).div(vaultDecimals.borrow).toNumber(),
    farmEarnings: Big(harvest.farmEarnings)
      .add(Big(harvest.farmLoss))
      .div(vaultDecimals.borrow)
      .toNumber()
  };
};

export const getLoanStats = (
  baselineSupplyInBase: number,
  baselineBorrowsInBase: number,
  harvestA: any,
  harvestB: any,
  events: any,
  vaultDecimals: any
) => {
  /** Calculate stats for users loan
   * @param baselineSupplyInBase - The users supply at the start of the period
   * @param baselineBorrowsInBase - The users borrows at the start of the period
   * @param harvestA - Harvest data from the start of the period
   * @param harvestB - Harvest data from the end of the period
   * @param events - Events that occurred during the period
   * @param vaultDecimals - Decimals for the vault
   */

  // Normalise the incoming harvest data
  const previousHarvest = parseHarvest(harvestA, vaultDecimals);
  const latestHarvest = parseHarvest(harvestB, vaultDecimals);

  // Set some default parameters
  // TODO: Consider fixing harvestDuration
  const millisecondsPerYear = 31536000000;
  const minHarvestDuration = 86400000;
  const maxYears = 10;
  const apyLimit = 100; // Value to sanity check APYs against
  const targetLtv = latestHarvest.divertEarningsThreshold; // TODO: Confirm this is correct
  const liquidationLtv = latestHarvest.activeAssetsThreshold; // TODO: Confirm this is correct
  const averages = getEstimatedAverages(harvestA, harvestB, events, vaultDecimals);

  // Set baseline figure, but if baseline supply equals 0, then use vault averages
  // Baseline figures are used as the starting point for position calculations
  // We use the vault averages if the baseline is 0 to allow us to present stats for new users
  baselineSupplyInBase = baselineSupplyInBase > 0 ? baselineSupplyInBase : averages.averageSupplyInBase;
  baselineBorrowsInBase = baselineSupplyInBase > 0 ? baselineBorrowsInBase : averages.averageUserBorrows;
  let supplyInBase = baselineSupplyInBase;
  let borrowsInBase = baselineBorrowsInBase;

  // Calculate the number of harvests per year and the maximum number of loops
  const harvestDuration = latestHarvest.datetime - previousHarvest.datetime;
  const harvestsPerYear = millisecondsPerYear / Math.max(harvestDuration, 1);
  const harvestsPerYearFloor = Math.floor(harvestsPerYear);
  let maxLoopCount = harvestsPerYear * maxYears;

  // Calculate rates; interest earnings/costs in the previous harvest cycle
  const supplyRate = latestHarvest.supplyIndex / previousHarvest.supplyIndex - 1;
  const borrowRate = latestHarvest.borrowIndex / previousHarvest.borrowIndex - 1;
  // farmRate represents how much was earned in the previous harvest cycle, this factors in cost of borrowing
  // farmEarnings = farm revenue - (borrow interest)
  const farmRate = latestHarvest.farmEarnings / averages.averageActiveAssets;
  // farmRateActual represents how much was earned in the previous harvest cycle, this excludes cost of borrowing
  const farmRateActual = farmRate + borrowRate;

  // Set initial values
  let monthlyLoanPayments = 0, // Monthly loan payments
    netApy = 0, // Net APY represents the APY on the users' overall position ( assets - liabilities )
    loanApy = 0, // Loan APY represents the APY on the users' loan
    totalNetChange = 0, // Total net change in the users' position
    totalLoanChange = 0, // Total loan change in the users' position
    repaidIn = -1, // Time in milliseconds until the loan is repaid
    smartRepayment = 0; // Amount of the loan that is repaid in each harvest

  // Loop over multiple harvests to calculate the impact on the users position over time
  // We use a loop to allow us to calculate the impact of the loan over multiple harvests
  // This allows us to account for the impact of compounding interest and reduced loan size
  for (let i = 0; i < maxLoopCount; i++) {

    // Calculate users active assets in this simulated harvest
    const userLtv = borrowsInBase / supplyInBase;
    let userActiveAssets = supplyInBase * liquidationLtv - borrowsInBase;

    // Calculate interest for the user in this simulated harvest
    const supplyInterest = supplyInBase * supplyRate;
    const borrowInterest = borrowsInBase * borrowRate;
    const farmChanges = userActiveAssets * farmRate;

    // As they aren't activitly contributing, we don't awared farm earnings to users who's LTV is above the target LTV
    // Allocate rewards to users only if their LTV is below target or above the liquidationLtv
    if (userLtv <= targetLtv) {
      smartRepayment = farmChanges;
    } else {
      smartRepayment = 0;
    }

    // Calculate impact on user positions in this harvest
    const loanChange = borrowInterest - smartRepayment;
    const netChange = supplyInterest + loanChange;
    supplyInBase += supplyInterest;
    borrowsInBase += loanChange;
    totalLoanChange += loanChange;
    totalNetChange += netChange;

    // Calculate the loanApy and netApy at key points
    if (i === 0) {
      // In the first loop, calculate monthlyLoanPayments and netApy
      monthlyLoanPayments = (loanChange * harvestsPerYear) / 12;
      loanApy = rateToApy(loanChange / baselineBorrowsInBase, harvestsPerYear);
      netApy = rateToApy(
        netChange / (baselineSupplyInBase - baselineBorrowsInBase),
        harvestsPerYear
      );
    } else if (i === harvestsPerYearFloor - 1) {
      // Every year, increase precision by updating APY calculations
      // Note: if the loan is repaid before the end of the year, this will not be reached
      loanApy = totalLoanChange / baselineBorrowsInBase;
      netApy = totalNetChange / (baselineSupplyInBase - baselineBorrowsInBase);
    }

    if (borrowsInBase < 0) {
      // Exit the loop once the users loan is fully repaid
      repaidIn = (i / harvestsPerYear) * millisecondsPerYear;
      break;
    }

    if (monthlyLoanPayments > 0 && i >= harvestsPerYearFloor - 1) {
      // Exit the loop after one year if the users' loan isn't auto repaying
      // This is a fairly common scenario for users who have a relatively large loan
      repaidIn = -1;
      break;
    }
  }

  let data = {
    supplyApy: rateToApy(supplyRate, harvestsPerYear) * 100, // supplyApy is the APY on the users' collateral
    borrowApy: rateToApy(borrowRate, harvestsPerYear) * 100, // borrowApy is the APY on the users' borrows
    farmApy: rateToApy(farmRate, harvestsPerYear) * 100, // farmApy is the APY on the users' farm earnings
    farmApyActual: rateToApy(farmRateActual, harvestsPerYear) * 100, // farmApyActual is the APY on the users' farm earnings, excluding cost of borrowing
    loanApy: loanApy * 100, // loanApy is the APY on the users' loan (( borrow amount * borrow APY - active capital * farm apy ) / borrow amount)
    netApy: netApy * 100, // netApy is the APY on the users' overall position ( borrow amount * borrow APY - active capital * farm apy - supply amount * supply APY ) / ( supply amount - borrow amount )
    monthlyLoanPayments: monthlyLoanPayments, // monthlyLoanPayments is the amount of the loan that may be repaid each month
    repaidIn: repaidIn, // repaidIn is the time in milliseconds until the loan is repaid
  };

  let message
  let status = 'success'
  // Perform some sanity checks on APYs and return null if the APY is incorrect
  const boundedApyTypes = ['supplyApy', 'borrowApy', 'farmApy'] as const
  for (let apyType of boundedApyTypes) {
    const apyValue = data[apyType];
    if (apyValue && Math.abs(apyValue) > apyLimit) {
      status = 'error';
      message = `APY out of bounds, this is likely an error with the calculations. Please try again later.\n\n ${apyType}: ${apyValue}`;
      console.error(message);
      data = {
        ...data,
        [apyType]: null
      };
    }
  }

  if (harvestDuration < minHarvestDuration) {
    status = 'error';
    message = "Harvest duration too short for accurate calculations";
    console.error(message);
  }


  return { status, data, message };
};
