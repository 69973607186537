import { AnalyticsInterface } from "../analytics";
import * as Sentry from "@sentry/react";
import { baseUrl } from "../../useApi";
import { useEffect } from "react";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";

export class SentryAnalytics implements AnalyticsInterface {
  constructor() {
    if (!Sentry.isInitialized()) {
      Sentry.init({
        environment: process.env.NODE_ENV,
        dsn: process.env.REACT_APP_SENTRY_DNS,
        tunnel: `${baseUrl}/tunnel`,
        integrations: [
          new Sentry.BrowserProfilingIntegration(),
          new Sentry.BrowserTracing({
            // See docs for support of different versions of variation of react router
            // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
            routingInstrumentation: Sentry.reactRouterV6Instrumentation(
              useEffect,
              useLocation,
              useNavigationType,
              createRoutesFromChildren,
              matchRoutes
            ),
          }),
          new Sentry.Replay(),
          new Sentry.Feedback({
            colorScheme: "dark",
            autoInject: process.env.REACT_APP_SENTRY_ENABLE_FEEDBACK_FORM === 'true'
          }),
        ],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        tracesSampleRate: 1.0,
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/app\.altitude\.fi/, /^https:\/\/(.+)amplifyapp\.com/],
        // Capture Replay for 100% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 1.0,
        replaysOnErrorSampleRate: 1.0,
        normalizeDepth: 5
      });
    }
  }
  identify(id: string, data: object | undefined): void {
    Sentry.setUser({ id, ...data });
  }

  page(data: object | undefined): void {}

  track(eventId: string, data: object | undefined): void {
    Sentry.addBreadcrumb({
      category: "ui.event",
      data,
      level: "info",
    });
  }

}
