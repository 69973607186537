import { useEffect, useMemo } from "react";
import React from "react";

import {
  InfoPopover,
  LoaderInline,
  PercentageFormatter,
} from "../../../../components";

import { RepayingButton } from "./components/repaying-button";
import * as Styles from "./style";
import { useUserPosition } from "../../../../hooks/useUserPosition";
import { useUserVaultInfo, useVault } from "../../../../hooks/useAppSelector";
import * as PopoverStyles from "../../../../components/custom-popover/styles";

let backgroundLoading = false;

const UserStats = () => {
  const {
    data: vault,
    isLoading: vaultLoading
  } = useVault();
  const { data: userVault, isLoading: userVaultLoading } = useUserVaultInfo();
  const { data: userPosition, isLoading: userPositionLoading } = useUserPosition();
  const ltv = userPosition?.current.loanToValue || 0;

  const isLoading = useMemo(() => (
    (
      vaultLoading ||
      userVaultLoading ||
      userPositionLoading
    ) && !backgroundLoading
  ), [
    vaultLoading,
    userVaultLoading,
    userPositionLoading,
  ]);

  useEffect(() => {
    if (!isLoading && !backgroundLoading) {
      backgroundLoading = true;
    }
  }, [isLoading]);

  const renderLoanToValue = () => {
    if (!userVault || !vault) {
      return (
        <>
          <PercentageFormatter percentage={0} fractionDigits={2} />
          <span>%</span>
        </>
      );
    }
    if (isLoading) {
      return <StatLoaderSingle />;
    }

    return (
      <>
        <PercentageFormatter percentage={ltv} fractionDigits={2} />
        <span>%</span>
      </>
    );
  };
  const repayingButton = () => {
    if (!userVault || !vault || !userPosition) {
      return <Styles.RepayingWrapper />;
    }

    return (
      <Styles.RepayingWrapper>
        <Styles.RepayingButton>
          <RepayingButton
            userPosition={userPosition}
            userLTV={ltv / (vault.supplyBasePrice || 1)}
            targetLTV={vault.targetThreshold}
            supplyThreshold={vault.supplyThreshold}
            liquidationThreshold={vault.liquidationThreshold}
          />
        </Styles.RepayingButton>
      </Styles.RepayingWrapper>
    );
  };

  return (
    <Styles.UserStatContainer>
      <Styles.UserStatBox>
        <Styles.UserStatBoxItem>
          <Styles.UserStatBoxHeader color="orangeAlpha">
            <Styles.BoxHeaderSquare color="orangeAlpha" />
            <span>Net APY</span>
            {userPosition && vault && (
              <InfoPopover>
                <PopoverStyles.Paragraph>
                  Net APY is the combined effect of your collateral, loan, and Altitude’s smart repayments on your total net position on Altitude.
                </PopoverStyles.Paragraph>
              </InfoPopover>
            )}
          </Styles.UserStatBoxHeader>
          {!isLoading
            ? (
              <Styles.UserStatBoxValueWrapper>
                <Styles.UserStatBoxValue>
                  <Styles.EllipsisWrapper>
                    <PercentageFormatter
                      percentage={userPosition?.net.netApy || 0}
                      fractionDigits={2}
                    />
                  </Styles.EllipsisWrapper>
                  <Styles.EllipsisWrapper>%</Styles.EllipsisWrapper>
                </Styles.UserStatBoxValue>
                {repayingButton()}
              </Styles.UserStatBoxValueWrapper>
            ) : (
              <StatLoaderDouble />
            )
          }
        </Styles.UserStatBoxItem>
        <Styles.UserStatBoxItem>
          <Styles.BoxHeader color="purpleBeta">
            <Styles.BoxHeaderSquare color="purpleBeta" />
            <span>Loan-To-Value</span>
          </Styles.BoxHeader>
          <Styles.UserStatBoxValueWrapper>
            {isLoading && <StatLoaderSingle />}
            {!isLoading && (
              <Styles.UserStatBoxValue>
                {renderLoanToValue()}
              </Styles.UserStatBoxValue>
            )}
          </Styles.UserStatBoxValueWrapper>
        </Styles.UserStatBoxItem>
      </Styles.UserStatBox>
    </Styles.UserStatContainer>
  );
};

const StatLoaderSingle = () => {
  const rects = [
    {
      y: 2,
      x: 0,
      rx: 3,
      ry: 3,
      width: 80,
      height: 25,
    },
  ];
  return <LoaderInline height={50} viewBoxWidth={156} loaderSettings={rects} />;
};

const StatLoaderDouble = () => {
  const rects = [
    {
      y: 2,
      x: 0,
      rx: 3,
      ry: 3,
      width: 80,
      height: 25,
    },
    {
      y: 36,
      x: 0,
      rx: 3,
      ry: 3,
      width: 100,
      height: 20,
    },
  ];
  return <LoaderInline height={60} viewBoxWidth={156} loaderSettings={rects} />;
};

export default UserStats;
