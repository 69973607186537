import styled from "styled-components";
import { ThemePrimaryText, ThemePrimary, ThemeSecondaryText } from "./theme.styles";
import { GridVars } from "./grid.styles";

const weights = {
  normal: 400,
  strong: 600,
  stronger: 800,
}
const textSizes = {
  normal: "0.8rem",
  large: "1.2rem",
  larger: "2rem",
}
export type TextProps = {
  $weight?: keyof typeof weights
  $size?: keyof typeof textSizes
}
export const Text = styled.div<TextProps>`
  display: inline;
  color: ${ThemeSecondaryText};
  fill: ${ThemeSecondaryText};
  font-size: ${props => textSizes[props.$size || 'normal']};
  font-weight: ${props => weights[props.$weight || 'normal']};
  line-height: 1.4em;
  vertical-align: middle;
`;
export const TextLink = styled.a`
  color: ${ThemePrimary};

  :hover {
    opacity: 0.8;
  }

  &:visited {
    color: ${ThemePrimaryText};
  }
`;

export const TextParagraph = styled(Text)`
  display: block;
  margin-bottom: ${GridVars.margin.m2};
  text-align: justify;
  hyphens: auto;
  word-wrap: break-word;

  &:last-child {
    margin-bottom: 0px;
  }
`;

// Headers
export const TextTitle = styled(TextParagraph)`
  font-size: 4.5rem;
  font-weight: 600;
  margin-bottom: ${GridVars.margin.m2};
`;

export const TextHeader1 = styled(TextParagraph)`
  color: ${ThemePrimaryText};
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 1em;
  margin-bottom: ${GridVars.margin.m2};
`;

export const TextHeader2 = styled(TextParagraph)`
  color: ${ThemePrimaryText};
  font-size: 2rem;
  font-weight: 600;
  line-height: 1em;
  margin-bottom: ${GridVars.margin.m2};
`;

export const TextHeader3 = styled(TextParagraph)`
  color: ${ThemePrimaryText};
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1em;
  margin-bottom: ${GridVars.margin.m2};
`;

// Text labels & values
const labelSizes = {
  small: '0.7rem',
  large: '1.4rem'
}

export type TextLabelProps = {
  size?: keyof typeof labelSizes
}

export const TextLabel = styled(Text).attrs({ $weight: 'strong' })<TextLabelProps>`
  text-overflow: ellipsis;
  color: ${ThemeSecondaryText};
  ${props => props.size && `font-size: ${labelSizes[props.size]}`}
`;

export const TextValue = styled(TextLabel)`
  padding-left: ${GridVars.padding.p1};
  color: ${ThemePrimaryText};
`;
