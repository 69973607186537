import { Loader } from "../../../../components/loader/loader";
import * as Styles from "../../../../components/transaction-status/components/style";

const Processing = () => {
  return (
    <Styles.TransactionContainer>
      <Styles.TransactionHeader>Transaction Pending</Styles.TransactionHeader>
      <Styles.IconContainer>
        <Loader type="loading" />
      </Styles.IconContainer>
    </Styles.TransactionContainer>
  );
};

export default Processing;
