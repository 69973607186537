import { useMemo } from "react";

import { TRANSACTION_STEPS } from "../../utils";

import { Confirm, Failure, Processing, Success } from "./components";
import { transactionStatusCodes } from "./transaction-status.codes";
import { extractError, NOT_ENOUGH_FUNDS, RPC_ERROR, TxError, USER_REJECTED } from "../../utils/txErrors";

interface Props {
  transactionStep: number;
  transactionError: TxError | null;
}

const GetTransactionStatusText = (txError?: TxError | null) => {
  if (txError) {
    const [,, type] = extractError(txError);
    if (type === RPC_ERROR && txError.code) {
      return transactionStatusCodes[txError.code];
    } else if (type === USER_REJECTED) {
      return {
        header: "Transaction Rejected",
        message: "You have rejected the transaction."
      };
    } else if (type === NOT_ENOUGH_FUNDS) {
      return {
        header: "Insufficient Funds",
        message: "There is not enough balance in your account to send this transaction."
      }
    }
  }

  return {
    header: "Transaction Failed",
    message: "Transaction unsuccessful. Please review details and try again.",
  };
};

const TransactionStatus = (props: Props) => {
  const { transactionStep, transactionError } = props;

  const { header, message } = useMemo(() => GetTransactionStatusText(transactionError), [transactionError]);

  const renderSteps = (): JSX.Element | null => {
    switch (transactionStep) {
      case TRANSACTION_STEPS.confirm:
        return <Confirm />;

      case TRANSACTION_STEPS.processing:
        return <Processing />;

      case TRANSACTION_STEPS.success:
        return <Success />;

      case TRANSACTION_STEPS.failure:
        return <Failure header={header} message={message} fullError={transactionError?.message} />;

      default:
        return null;
    }
  };

  return <>{renderSteps()}</>;
};

export default TransactionStatus;
