type LogoAltitudeProps = {
  color?: string;
}

const LogoAltitude = ({ color = 'white' }: LogoAltitudeProps) => {
  return (
    <svg width="113" height="23" viewBox="0 0 113 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3982_688)">
        <g clipPath="url(#clip1_3982_688)">
          <path d="M35.0626 18.1073V22.0904H32.6819C30.9879 22.0904 29.6602 21.6783 28.6987 20.8313C27.7373 19.9844 27.2795 18.6338 27.2795 16.7567V10.6675H25.4024V6.77595H27.2795V3.31934H31.9722V6.75306H35.0397V10.6446H31.9722V16.8024C31.9722 17.2603 32.0867 17.5808 32.2927 17.7868C32.5216 17.9928 32.8879 18.0844 33.3915 18.0844H35.0626V18.1073Z"
                fill={color}/>
          <path d="M8.98914 21.7011C8.05059 22.159 7.06625 22.3879 5.99035 22.3879C5.0518 22.3879 4.20481 22.2047 3.44939 21.8156C2.69397 21.4493 2.09879 20.9228 1.64096 20.2361C1.20602 19.5493 0.977108 18.771 0.977108 17.8782C0.977108 16.459 1.5265 15.3144 2.6253 14.4216C3.72409 13.5289 5.37228 13.0939 7.54697 13.0939H10.9807C10.9349 12.2469 10.706 11.5831 10.2711 11.1024C9.83613 10.6216 9.24095 10.3698 8.50842 10.3698C7.82167 10.3698 7.27227 10.553 6.83734 10.8963C6.4024 11.2397 6.10481 11.7204 5.96746 12.3156H1.2747C1.50361 11.1939 1.93855 10.2096 2.60241 9.31682C3.26626 8.44694 4.11325 7.73731 5.14336 7.23369C6.17348 6.73008 7.34095 6.47827 8.62287 6.47827C9.99637 6.47827 11.2325 6.75297 12.3084 7.27947C13.3843 7.80598 14.2313 8.58429 14.8265 9.56863C15.4217 10.553 15.7421 11.7204 15.7421 13.0481V22.0674H11.0265V19.9156C10.6144 20.6252 9.95058 21.2204 9.01203 21.6782L8.98914 21.7011ZM9.24094 18.4734C9.74456 18.2216 10.1795 17.8782 10.5 17.3975C10.8205 16.9168 10.9807 16.3903 10.9807 15.7951V15.612H8.07348C7.29517 15.612 6.72288 15.7722 6.35661 16.0698C5.99035 16.3674 5.78433 16.7795 5.78433 17.2602C5.78433 17.7409 5.94457 18.1529 6.28794 18.4276C6.63131 18.7023 7.06625 18.8626 7.66143 18.8626C8.21083 18.8626 8.73733 18.7481 9.26384 18.4963L9.24094 18.4734Z"
                fill={color}/>
          <path d="M22.9987 0V22.0903H18.306V4.69276C18.306 2.10602 20.412 0 22.9987 0Z" fill={color}/>
          <path d="M43.1204 6.77588V22.1132H38.4276V6.77588H43.1204Z" fill={color}/>
          <path d="M55.0698 18.1073V22.0904H52.689C50.9951 22.0904 49.6674 21.6783 48.7059 20.8313C47.7445 19.9844 47.2866 18.6338 47.2866 16.7567V10.6675H45.4095V6.77595H47.2866V3.31934H51.9794V6.75306H55.0469V10.6446H51.9794V16.8024C51.9794 17.2603 52.0939 17.5808 52.2999 17.7868C52.5288 17.9928 52.8951 18.0844 53.3987 18.0844H55.0698V18.1073Z"
                fill={color}/>
          <path d="M72.8107 6.77588V22.1132H68.1179V20.0301C67.6372 20.7168 66.9962 21.2433 66.1722 21.6554C65.3481 22.0674 64.4553 22.2734 63.471 22.2734C62.3035 22.2734 61.2734 22.0216 60.3577 21.4951C59.442 20.9686 58.7553 20.2132 58.2746 19.2289C57.771 18.2445 57.5421 17.0771 57.5421 15.7494V6.77588H62.2119V15.1084C62.2119 16.1385 62.4866 16.9397 63.0131 17.4891C63.5396 18.0614 64.2493 18.3361 65.1649 18.3361C66.0806 18.3361 66.8131 18.0614 67.3396 17.4891C67.8661 16.9168 68.1408 16.1156 68.1408 15.1084V6.77588H72.8336H72.8107Z"
                fill={color}/>
          <path d="M75.6721 10.2555C76.2673 9.0651 77.0685 8.14944 78.0986 7.50848C79.1287 6.86751 80.2733 6.54703 81.5324 6.54703C82.5396 6.54703 83.4552 6.75306 84.3022 7.18799C85.1263 7.60004 85.7902 8.17233 86.2709 8.88197V0.0229492C88.8576 0.0229492 90.9637 2.12897 90.9637 4.71571V22.0904H86.2709V19.8928C85.836 20.6253 85.195 21.2205 84.3938 21.6554C83.5926 22.0904 82.6311 22.3193 81.5552 22.3193C80.2962 22.3193 79.1516 21.9988 78.1215 21.3349C77.0914 20.694 76.2902 19.7554 75.695 18.5651C75.0998 17.3747 74.8023 15.9783 74.8023 14.3988C74.8023 12.8193 75.0998 11.4458 75.695 10.2555H75.6721ZM85.2866 11.6518C84.6456 10.9651 83.8444 10.6446 82.9058 10.6446C81.9673 10.6446 81.189 10.988 80.5251 11.6518C79.8842 12.3157 79.5408 13.2314 79.5408 14.4217C79.5408 15.6121 79.8613 16.5277 80.5251 17.2145C81.1661 17.9012 81.9673 18.2446 82.9058 18.2446C83.8444 18.2446 84.6227 17.9012 85.2866 17.2374C85.9275 16.5506 86.2709 15.635 86.2709 14.4675C86.2709 13.3 85.9504 12.3615 85.2866 11.6976V11.6518Z"
                fill={color}/>
          <path d="M108.109 15.5662H97.4878C97.5564 16.5276 97.8769 17.2373 98.4034 17.7409C98.9299 18.2445 99.6167 18.4963 100.395 18.4963C101.562 18.4963 102.387 17.9927 102.844 17.0084H107.835C107.583 18.0156 107.125 18.9312 106.438 19.7324C105.774 20.5336 104.927 21.1746 103.92 21.6324C102.913 22.0903 101.791 22.3192 100.532 22.3192C99.0215 22.3192 97.6938 21.9987 96.5263 21.3577C95.3588 20.7168 94.4432 19.8011 93.7793 18.6108C93.1155 17.4204 92.795 16.024 92.795 14.4445C92.795 12.865 93.1155 11.4686 93.7793 10.2782C94.4203 9.08789 95.3359 8.17223 96.5034 7.53127C97.6709 6.89031 99.0215 6.56982 100.532 6.56982C102.043 6.56982 103.325 6.89031 104.493 7.50838C105.637 8.12645 106.553 9.01922 107.194 10.1638C107.835 11.3084 108.178 12.659 108.178 14.1927C108.178 14.6276 108.155 15.0855 108.087 15.5662H108.109ZM103.394 12.9566C103.394 12.1554 103.119 11.5144 102.57 11.0337C102.02 10.5529 101.334 10.324 100.509 10.324C99.6853 10.324 99.0673 10.5529 98.5179 11.0108C97.9685 11.4686 97.648 12.1096 97.5106 12.9566H103.394Z"
                fill={color}/>
          <path d="M38.4047 0H43.0975C43.0975 2.58674 40.9915 4.69276 38.4047 4.69276V0Z" fill="#4F8BFF"/>
          <path d="M113.26 22.0903H108.567C108.567 19.5036 110.673 17.3976 113.26 17.3976V22.0903Z" fill="#4F8BFF"/>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_3982_688">
          <rect width="112" height="23" fill="white" transform="translate(0.862305)"/>
        </clipPath>
        <clipPath id="clip1_3982_688">
          <rect width="112.26" height="22.3879" fill="white" transform="translate(1)"/>
        </clipPath>
      </defs>
    </svg>
  )
}

export default LogoAltitude;
