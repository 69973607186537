import { useAppSelector } from "../../hooks";
import { selectUser } from "../../store/user/user.slice";

import { SelectedWallet, WalletOptions } from "./components";

const ConnectWallet = () => {
  const userStore = useAppSelector((state) => state.user);
  const { walletConnected } = selectUser(userStore) || {};

  return <div data-testid="connect-wallet-options">{walletConnected ? <SelectedWallet /> : <WalletOptions />}</div>;
};

export default ConnectWallet;
