import { createAsyncThunk } from "@reduxjs/toolkit";
import { approveSpending, isNativeToken } from "../../../services/contracts/base";
import * as store from "../../../store";
import { selectUser } from "../../../store/user/user.slice";
import { selectVault } from "../../../store/vault/vault.slice";
import { TRANSACTION_STEPS } from "../../../utils";
import { sdk } from "../../../services/sdk-provider";

import { fetchUserVaultData } from "./fetchUserVaults";
import { VaultName, TokenName } from "@altitude-fi/altitude-v1-sdk/dist/types";
import { BigNumber } from "ethers";

interface DepositAndBorrowPayload {
  deposit?: BigNumber;
  borrow?: BigNumber;
  selectedVault: VaultName;
}

export const depositAndBorrow = createAsyncThunk<void, DepositAndBorrowPayload>(
  "userVault/depositAndBorrow",
  async (
    { deposit, borrow, selectedVault }: DepositAndBorrowPayload,
    thunkApi
  ) => {
    const { vault, user } = store.default.getState();
    const { userAddress }: any = selectUser(user);
    const { supplyName, supplySymbol }: any =
      selectVault(vault, selectedVault) || {};

    const txSendArgs: any = {
      from: userAddress,
    };

    const vaultContract = sdk.contracts.vaults[selectedVault].vault;
    const tokenContract = sdk.contracts.tokens[supplyName as TokenName];

    if (deposit?.gt(0)) {
      try {
        if (isNativeToken(supplySymbol)) {
          txSendArgs.value = deposit;
        } else {
          await approveSpending(tokenContract, userAddress, vaultContract.address, deposit);
        }
      } catch (e: any) {
        return thunkApi.rejectWithValue(e);
      }
    }

    store.userVaultSetTransactionMode(TRANSACTION_STEPS.processing);
    try {
      if (deposit?.gt(0) && borrow?.gt(0)) {
        await vaultContract.depositAndBorrow(
          deposit,
          borrow,
          txSendArgs
        );
      } else if (deposit?.gt(0)) {
        await vaultContract.deposit(
          deposit,
          userAddress,
          txSendArgs
        );
      } else if (borrow?.gt(0)) {
        await vaultContract.borrow(
          borrow,
          txSendArgs
        );
      }
    } catch (e: any) {
      return thunkApi.rejectWithValue(e);
    }

    thunkApi.dispatch(fetchUserVaultData({ selectedVault }));
  }
);
