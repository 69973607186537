import { useState } from "react";

import {
  LogoAltitude,
  MenuBurger,
  MenuCollapse,
} from "../../assets/images/iconComponents";
import { ConnectWallet, CustomModal } from "../../components";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useNavigate, useLocation } from "react-router-dom";
import { globalSetHeaderModal } from "../../store";
import { getWalletIcon, HEADER_MODAL_STATUS, ROUTES } from "../../utils";

import * as Style from "./style";
import { useAnalytics } from "../../hooks/useAnalytics";
import { useUser } from "../../hooks/useAppSelector";

const Navbar = () => {
  const dispatch = useAppDispatch();
  const { track } = useAnalytics();
  const { headerModal } = useAppSelector((state) => state.global);

  const { data: { selectedWallet, userAddress, walletConnected } = {} } = useUser();

  const [expand, setExpand] = useState(false);

  const navigate = useNavigate();
  const handlePageChange = (path: string) => {
    navigate(path);
    setExpand(false);
  };

  const { pathname } = useLocation();

  const renderConnectBtnOrUserInfo = () => {
    if (walletConnected && selectedWallet && userAddress) {
      return (
        <Style.UserInfoContainer>
          <Style.UserAddress
            data-testid="user-address-modal"
            onClick={() =>
              dispatch(globalSetHeaderModal(HEADER_MODAL_STATUS.connectWallet))
            }
          >
            {getWalletIcon(selectedWallet)}
            <span>{`${userAddress.slice(0, 6)}...${userAddress.slice(
              -4
            )}`}</span>
          </Style.UserAddress>
        </Style.UserInfoContainer>
      );
    }

    return (
      <Style.ConnectButton
        data-testid="connect-wallet-button"
        onClick={() => {
          track('clicked-connect-wallet');
          dispatch(globalSetHeaderModal(HEADER_MODAL_STATUS.connectWallet));
        }}
      >
        Connect Wallet
      </Style.ConnectButton>
    );
  };

  const renderNavbarButtonImage = (): JSX.Element => {
    if (!expand) {
      return <MenuBurger />;
    }

    return <MenuCollapse />;
  };

  const renderResponsiveNavbar = () => {
    if (!walletConnected || !selectedWallet || !userAddress) {
      return (
        <Style.ConnectButtonMobile
          onClick={() =>
            dispatch(globalSetHeaderModal(HEADER_MODAL_STATUS.connectWallet))
          }
        >
          Connect Wallet
        </Style.ConnectButtonMobile>
      );
    }

    return (
      <>
        <Style.ResponsivexportUserInfoContainer>
          <Style.UserAddress
            onClick={() =>
              dispatch(globalSetHeaderModal(HEADER_MODAL_STATUS.connectWallet))
            }
          >
            {getWalletIcon(selectedWallet)}
            <span>{`${userAddress.slice(0, 6)}...${userAddress.slice(
              -4
            )}`}</span>
          </Style.UserAddress>
        </Style.ResponsivexportUserInfoContainer>
      </>
    );
  };

  return (
    <div data-testid="navbar">
      <Style.Nav>
        <Style.NavWrapper>
          <Style.NavContainer>
            <Style.AppLogo onClick={() => handlePageChange(ROUTES.dashboard)}>
              <LogoAltitude />
            </Style.AppLogo>
            <Style.NavLinkBox>
              <Style.NavLinkItem
                onClick={() => handlePageChange(ROUTES.dashboard)}
                $isActive={pathname === ROUTES.dashboard}
              >
                Dashboard
              </Style.NavLinkItem>
              <Style.NavLinkItem
                onClick={() => handlePageChange(ROUTES.vaults)}
                $isActive={pathname === ROUTES.vaults}
              >
                Vaults
              </Style.NavLinkItem>
            </Style.NavLinkBox>
            {renderConnectBtnOrUserInfo()}

            <Style.NavbarButton onClick={() => setExpand(!expand)}>
              {renderNavbarButtonImage()}
            </Style.NavbarButton>
          </Style.NavContainer>
        </Style.NavWrapper>
        <CustomModal
          show={headerModal === HEADER_MODAL_STATUS.connectWallet}
          onClose={() =>
            dispatch(globalSetHeaderModal(HEADER_MODAL_STATUS.closed))
          }
          testId="connect-wallet-modal"
        >
          <ConnectWallet />
        </CustomModal>

        <Style.ExpandedMenu $expand={expand}>
          {renderResponsiveNavbar()}

          <Style.ResponsiveNavbar>
            <Style.ResponsiveNavbarItem
              onClick={() => handlePageChange(ROUTES.dashboard)}
              $isActive={pathname === ROUTES.dashboard}
            >
              Dashboard
            </Style.ResponsiveNavbarItem>
            <Style.ResponsiveNavbarItem
              onClick={() => handlePageChange(ROUTES.vaults)}
              $isActive={pathname === ROUTES.vaults}
            >
              Vaults
            </Style.ResponsiveNavbarItem>
          </Style.ResponsiveNavbar>
        </Style.ExpandedMenu>
      </Style.Nav>
    </div>
  );
};

export default Navbar;
