import React, { FC, useEffect, useMemo, useState } from "react";

import { PercentageFormatter } from "../../../../components";
import { useAppSelector, useMediaQuery } from "../../../../hooks";
import {
  getChartColor,
  getChartValues,
  getLiquidationAmount,
} from "../../../../modules/dashboard/modals/components/bar-chart/bar-chart.logic";
import { selectUser } from "../../../../store/user/user.slice";
import { selectVault } from "../../../../store/vault/vault.slice";
import {
  ThemeChartGray100,
  ThemeChartGray50,
  GridVars,
  hexToRgb,
} from "../../../../utils";

import { CHART_SIZES } from "./borrowing-capacity-chart.constants";
import { CapturedBoxStick } from "./elements/captured-box-stick.element";
import * as Styles from "./style";

interface BorrowingCapacityChartProps {
  balance: number;
  borrowLimit: number;
  liquidation: number;
  collateralInETH: number;
  liquidationThreshold: number;
  supplyThreshold: number;
  supplyTokenName: string;
  borrowTokenName: string;
}

export const BorrowingCapacityChart: FC<BorrowingCapacityChartProps> = ({
  liquidationThreshold,
  collateralInETH,
  borrowLimit,
  liquidation,
  balance,
  supplyThreshold,
  supplyTokenName = "",
  borrowTokenName = "",
}) => {
  const userStore = useAppSelector((state) => state.user);
  const vaultStore = useAppSelector((state) => state.vault);
  const { selectedVault } = useAppSelector((state) => state.global);

  const { theme }: any = selectUser(userStore) || { theme: "dark" };
  const { borrowToUsdRatio }: any =
    selectVault(vaultStore, selectedVault) || {};

  const [borrowLimitPercentage, liquidationPercentage, overallPercentage] =
    useMemo(
      () => getChartValues(borrowLimit, liquidation, balance),
      [borrowLimit, liquidation, balance]
    );
  const [sizes, setSizes] = useState(CHART_SIZES["MEDIUM"]);
  const [borrowColor, liquidationColor] = useMemo(
    () => getChartColor(overallPercentage),
    [overallPercentage]
  );
  const liquidationAmount =
    getLiquidationAmount(balance, liquidationThreshold, collateralInETH) *
    borrowToUsdRatio;
  const [percentage, setPercentage] = useState(0.0);

  const isXXL = useMediaQuery(`(min-width: ${GridVars.breakpoint.xxl})`);
  const isXL = useMediaQuery(
    `(min-width: ${GridVars.breakpoint.xl}) and (max-width: ${GridVars.breakpoint.xxl})`
  );
  const isLG = useMediaQuery(
    `(min-width: ${GridVars.breakpoint.lg}) and (max-width: ${GridVars.breakpoint.xl})`
  );
  const isMD = useMediaQuery(
    `(min-width: ${GridVars.breakpoint.md}) and (max-width: ${GridVars.breakpoint.lg})`
  );
  const isSM = useMediaQuery(
    `(min-width: ${GridVars.breakpoint.sm}) and (max-width: ${GridVars.breakpoint.md})`
  );
  const isXS = useMediaQuery(
    `(min-width: ${GridVars.breakpoint.xs}) and (max-width: ${GridVars.breakpoint.sm})`
  );
  const isXXS = useMediaQuery(
    `(min-width: ${GridVars.breakpoint.xxs}) and (max-width: ${GridVars.breakpoint.xs})`
  );

  useEffect(() => {
    if (isXL || isXXL) {
      setSizes(CHART_SIZES["MEDIUM"]);
    }
    if (isLG) {
      setSizes(CHART_SIZES["LARGE"]);
    }
    if (isSM || isMD) {
      setSizes(CHART_SIZES["LARGE"]);
    }
    if (isXS) {
      setSizes(CHART_SIZES["MEDIUM"]);
    }
    if (isXXS) {
      setSizes(CHART_SIZES["SMALL"]);
    }
  }, [isXXS, isXS, isSM, isMD, isLG, isXL, isXXL]);

  // Increment percentage over time when loading
  const percentageIncrement = 1; // Amount to increment with each step
  const duration = 500; // Increment duration in milliseconds
  const intervalDelay = duration / (overallPercentage / percentageIncrement);
  useEffect(() => {
    const timer = setInterval(() => {
      setPercentage((prevPercentage) => {
        if (prevPercentage >= overallPercentage) {
          clearInterval(timer);
          return overallPercentage;
        }

        return prevPercentage + 1;
      });
    }, intervalDelay);
    return () => clearInterval(timer)
  }, [overallPercentage, intervalDelay]);

  // Chart definitions
  const isLoaded = supplyThreshold > 0 ? true : false;
  const radius = sizes.RADIUS;
  const strokeWidth = sizes.STROKE_WIDTH;
  const roundingGap = sizes.ANGLE_GAP;
  const gaugeWidth = radius * 2;
  const chartHeight = radius + strokeWidth * 0.5;
  const annotationPadding = sizes.ANNOTATIONS_PADDING;
  const chartWidth = gaugeWidth + annotationPadding + 90;
  const grayPrimary = (ThemeChartGray100 as any)[theme];
  const graySecondary = (ThemeChartGray50 as any)[theme];

  // Circle guage math
  const innerRadius = radius - strokeWidth / 2;
  const arcBorrowingLimit = 180 * supplyThreshold;
  const arcLiquidationLimit = 180 - arcBorrowingLimit;

  const circumference = innerRadius * 2 * Math.PI;
  const arc = arcBorrowingLimit
    ? circumference * ((arcBorrowingLimit - roundingGap) / 360)
    : circumference / 2;
  const arcHeight = radius * Math.sin((arcBorrowingLimit * Math.PI) / 180);
  const dashArray = `${arc} ${circumference}`;
  const transform = `rotate(180, ${radius}, ${radius})`;

  const percentNormalized = Math.min(Math.max(borrowLimitPercentage, 0), 100);
  const offset = arc - (percentNormalized / 100) * arc;

  const arcLiquidationLimitTransform =
    360 - arcLiquidationLimit + roundingGap || 0;

  const percentNormalized20 = Math.min(Math.max(liquidationPercentage, 0), 100);
  const arc20 = circumference * ((arcLiquidationLimit - roundingGap) / 360);
  const dashArray20 = `${arc20} ${circumference}`;
  const transform20 = `rotate(${arcLiquidationLimitTransform}, ${radius}, ${radius})`;
  const offset20 = arc20 - (percentNormalized20 / 100) * arc20;

  return (
    <div data-testid="borrowingCapacityChart">
      <svg
        width={chartWidth}
        height={chartHeight}
        viewBox={`0 0 ${chartWidth} ${chartHeight}`}
        overflow="visible"
      >
        {/* Background indicator */}
        <circle
          cx={radius}
          cy={radius}
          fill="transparent"
          r={innerRadius}
          stroke={grayPrimary}
          strokeLinecap="round"
          strokeWidth={strokeWidth}
          strokeDasharray={dashArray}
          transform={transform}
        />
        {/* Risk indicator */}
        <circle
          cx={radius}
          cy={radius}
          fill="transparent"
          r={innerRadius}
          strokeLinecap="round"
          stroke={graySecondary}
          strokeWidth={strokeWidth}
          strokeDasharray={dashArray20}
          transform={transform20}
          style={{
            transition: isLoaded ? "stroke-dashoffset 0.5s" : "none",
            display: isLoaded ? "block" : "none",
          }}
        />
        {/* Borrow indicator */}
        <circle
          cx={radius}
          cy={radius}
          fill="transparent"
          r={innerRadius}
          stroke={borrowColor}
          strokeDasharray={dashArray}
          strokeDashoffset={offset ? offset : 0}
          strokeLinecap="round"
          strokeWidth={strokeWidth}
          style={{
            transition: isLoaded ? "stroke-dashoffset 0.5s" : "none",
            display: isLoaded ? "block" : "none",
          }}
          transform={transform}
        />
        {/* Borrow indicator, risk zone */}
        <circle
          cx={radius}
          cy={radius}
          fill="transparent"
          r={innerRadius}
          strokeLinecap="round"
          strokeDashoffset={offset20 ? offset20 : 0}
          stroke={liquidationColor}
          strokeWidth={strokeWidth}
          strokeDasharray={dashArray20}
          transform={transform20}
          style={{
            transition: isLoaded ? "stroke-dashoffset 0.5s 0.5s" : "none",
            display: isLoaded ? "block" : "none",
          }}
        />
        <CapturedBoxStick
          x={gaugeWidth + annotationPadding}
          y={chartHeight - arcHeight - 5}
          lineY={5}
          lineLength={Math.max(0, arcHeight - annotationPadding - 5)}
          value={Math.floor(borrowLimit)}
          valuePrefix={`${borrowTokenName} `}
          caption={"Borrow limit"}
          stopColor={hexToRgb(grayPrimary)}
          popupMessage={`You can currently borrow up to this amount in ${borrowTokenName}`}
          popupLeft={60}
          visible={isLoaded}
        />
        <CapturedBoxStick
          data-testid="liquidationAmount"
          x={gaugeWidth + annotationPadding}
          y={chartHeight - 30}
          lineY={27}
          lineLength={Math.max(0, annotationPadding - 5)}
          value={Math.floor(liquidationAmount)}
          caption={"Liquidation price"}
          valuePrefix={`${supplyTokenName}: $`}
          stopColor={hexToRgb(grayPrimary)}
          popupMessage={`Your position will be subject to liquidation if the ${supplyTokenName} price falls to this level.`}
          popupLeft={85}
          visible={true}
        />
      </svg>
      <Styles.ChartCaptionsWrapper width={gaugeWidth}>
        <Styles.ChartCaptions bottom={0}>
          <Styles.ChartCaptionsPercentageWrapper>
            <Styles.ChartCaptionsPercentage
              color={borrowColor}
              fontSize={sizes.CAPTIONS_PERCENTAGE_SIZE}
              $lineHeight={sizes.CAPTIONS_PERCENTAGE_HEIGHT}
            >
              <PercentageFormatter percentage={percentage / 100} />
              <Styles.ChartCaptionsPercentageSign
                value={overallPercentage ?? 0}
                fontSize={sizes.CAPTIONS_SIGN_SIZE}
              >
                %
              </Styles.ChartCaptionsPercentageSign>
            </Styles.ChartCaptionsPercentage>
          </Styles.ChartCaptionsPercentageWrapper>
          <Styles.ChartCaptionsText fontSize={sizes.CAPTIONS_TEXT_SIZE}>
            Borrowing Capacity
          </Styles.ChartCaptionsText>
        </Styles.ChartCaptions>
      </Styles.ChartCaptionsWrapper>
    </div>
  );
};
