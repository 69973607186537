import { LoaderInline } from "../../components/index";
import * as store from "../../store";
import { selectVault } from "../../store/vault/vault.slice";

import * as Styles from "./style";
import numbro from "numbro";
import { TokenDisplayType } from "../../models/vault";

const TokenBalance = ({
  amount,
  vaultType,
  displayType,
  isLoading = false,
  backLabel = false,
  showSign = false,
}: {
  amount: number;
  vaultType: string;
  displayType: TokenDisplayType;
  isLoading?: boolean;
  backLabel?: boolean;
  showSign?: boolean;
}) => {
  if (isLoading) {
    return <LoaderInline />;
  }

  amount = amount || 0;
  const { integerPart, decimalPart, tokenType, isBig, sign } = tokenBalanceFormatter({
    amount,
    vaultType,
    displayType,
  });

  return (
    <Styles.TokenBalance type={tokenType} value={amount.toString()}>
      {!backLabel && (
        <Styles.TokenPrefix>
          {tokenType}
          {<span>&nbsp;</span>}
        </Styles.TokenPrefix>
      )}
      <Styles.TokenInteger>
        {showSign && sign === -1 ? '-' : ''}
        {integerPart}
      </Styles.TokenInteger>
      {!!decimalPart && !isBig && <Styles.TokenDecimal>.{decimalPart}</Styles.TokenDecimal>}
      {backLabel && <Styles.TokenDecimal>&nbsp;{tokenType}</Styles.TokenDecimal>}
    </Styles.TokenBalance>
  );
};

export const tokenBalanceFormatter = ({
  amount,
  vaultType,
  displayType,
}: {
  amount: number;
  vaultType: string;
  displayType: string;
}) => {
  const { vault }: any = store.default.getState();
  const selectedVault: any = selectVault(vault, vaultType) || {};

  const decimals = selectedVault[displayType + "Decimals"] || 18;
  const precision = selectedVault[displayType + "Precision"] || 2;
  const tokenType = (selectedVault[displayType + "Symbol"] || "$");

  const isBig = amount > 99999

  const sign = Math.sign(amount)
  const integerPart = numbro(Math.abs(amount).toFixed(precision))
    .format({
      average: isBig,
      mantissa: 0,
      thousandSeparated: true,
      roundingFunction: Math.floor
    });

  const decimalPart = numbro(Math.abs(amount).toFixed(precision))
    .format({
      mantissa: precision,
      roundingFunction: Math.floor
    })
    .split('.')[1]

  return {
    label: `${integerPart}.${decimalPart} ${tokenType}`,
    integerPart,
    decimals,
    decimalPart,
    tokenType,
    isBig,
    sign
  };
};

export default TokenBalance;
