import InfoButton from "./info-button";
import CustomPopover from "../../components/custom-popover";

interface InfoPopoverProps {
  children: React.ReactNode;
}

export const InfoPopover = ({ children }: InfoPopoverProps) => {
  return (
    <CustomPopover marginTop={"0px"} marginLeft={"-20px"} button={<InfoButton />}>
      {children}
    </CustomPopover>
  );
};
