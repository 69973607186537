import { createSlice, EntityState, PayloadAction } from "@reduxjs/toolkit";
import { VaultName } from "@altitude-fi/altitude-v1-sdk/dist/types";

const globalSlice = createSlice<
  {
    headerModal: number;
    selectedVault: VaultName;
  },
  {
    globalSetHeaderModal(state: any, action: PayloadAction<number>): void;
    globalSetSelectedVault(state: any, action: PayloadAction<VaultName>): void;
  },
  "global"
>({
  name: "global",
  initialState: {
    headerModal: 0,
    selectedVault: "wstethUsdc", // "ethUsdc" | "wstethUsdc"
  },
  reducers: {
    globalSetHeaderModal(state, action: PayloadAction<number>) {
      state.headerModal = action.payload;
    },
    globalSetSelectedVault(state, action: PayloadAction<VaultName>) {
      state.selectedVault = action.payload;
    },
  },
});

// Selectors
export const selectGlobal = (
  state: EntityState<{ headerModal: number; selectedVault: VaultName }>
): EntityState<{ headerModal: number; selectedVault: VaultName }> => {
  return state;
};

export default globalSlice;
