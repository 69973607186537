import { TokenName, VaultName } from "@altitude-fi/altitude-v1-sdk/dist/types";
import {BigNumber} from "ethers";

export enum TokenDisplayType {
  supply = "supply",
  borrow = "borrow",
  base = "",
}

export enum StrategyType {
  lender = "lender",
  farm = "farm",
}

export interface BaseStrategyInterface {
  name: string;
  type: StrategyType;
  icon: string;
  risksUrls:  { text: string, url: string }[]
}

export const strategyInfos: {[key: string]: BaseStrategyInterface } = {
  "0x1476F031DAD2354A48a7C37a6128ad7B297a3997": {
    name: "Aave V3",
    type: StrategyType.lender,
    risksUrls: [{
      text: "Aave V3 Risks",
      url: "https://docs.aave.com/risk"
    }],
    icon: "aave",
  },
  "0x7d2768dE32b0b80b7a3454c06BdAc94A69DDc7A9": {
    name: "Aave V2",
    type: StrategyType.lender,
    risksUrls: [{
      text: "Aave V2 Risks",
      url: "https://docs.aave.com/risk"
    }],
    icon: "aave",
  },
  "0x3d9819210A31b4961b30EF54bE2aeD79B9c9Cd3B": {
    name: "Compound Finance",
    type: StrategyType.lender,
    risksUrls: [{
      text: "Compound Risks",
      url: "https://docs.compound.finance/#security"
    }],
    icon: "compound",
  },
  "0x505b5c9e5f625fA96D8091bc502cf3c3D0335027": {
    name: "Convex MIM Pool",
    type: StrategyType.farm,
    risksUrls: [{
      text: "Convex Risks",
      url: "https://docs.convexfinance.com/convexfinance/faq/risks"
    },
    {
      text: "Curve Risks",
      url: "https://classic.curve.fi/risks"
    }],
    icon: "convex",
  },
  "0xA2327f3fB832225f5ff37CDa70F694d51154929D": {
    name: "Convex sUSD-crvUSD Pool",
    type: StrategyType.farm,
    risksUrls: [{
      text: "Convex Risks",
      url: "https://docs.convexfinance.com/convexfinance/faq/risks"
    },
    {
      text: "Curve Risks",
      url: "https://classic.curve.fi/risks"
    }],
    icon: "convex",
  },
  "0xd168b6Ca6548F13f4EAF63191a2Df72633742Fd0": {
    name: "Convex USDV Pool",
    type: StrategyType.farm,
    risksUrls: [{
      text: "Convex Risks",
      url: "https://docs.convexfinance.com/convexfinance/faq/risks"
    },
    {
      text: "Curve Risks",
      url: "https://classic.curve.fi/risks"
    }],
    icon: "convex",
  },
  "0x3B1f0Ac253Ce81A9Dd5623c2DB4fcD079503616c": {
    name: "Convex USDP-crvUSD Pool",
    type: StrategyType.farm,
    risksUrls: [{
      text: "Convex Risks",
      url: "https://docs.convexfinance.com/convexfinance/faq/risks"
    },
    {
      text: "Curve Risks",
      url: "https://classic.curve.fi/risks"
    }],
    icon: "convex",
  },
}

export interface VaultModel {
  id: VaultName;

  supplyName: string;
  borrowName: TokenName;
  supplySymbol: string;
  borrowSymbol: string;
  vaultDecimals: any;
  supplyDecimals: number;
  borrowDecimals: number;
  supplyPrecision: number;
  borrowPrecision: number;
  supplyBasePrice: number;
  supplyBasePriceBn: BigNumber;
  borrowToUsdRatio: number;

  supplyTokenAddress: string;
  borrowTokenAddress: string;
  activeLenderStrategy: string | undefined;
  activeFarmStrategy: string | undefined;

  maxTargetThreshold: number;
  minTargetThreshold: number;
  targetThreshold: number;
  supplyThreshold: number;
  supplyThresholdBn: BigNumber;
  liquidationThreshold: number;
  liquidationThresholdBn: BigNumber;
  liquidationBonus: number;
  reserveFactor: number;

  userMinDepositLimit: number;
  userMaxDepositLimit: number;
  vaultDepositLimit: number;
  harvestsCount: number;
  protocolPaused: boolean;

  lenderStrategyInfo: BaseStrategyInterface;
  farmStrategyInfo: BaseStrategyInterface;
}

export interface VaultStats {
  id: VaultName;
  totalSupply: number;
  totalSupplyInUsd: number;
  totalBorrow: number;
  totalBorrowInUsd: number;
  totalUserBorrow: number;
  totalUserBorrowInUsd: number;
  totalFarm: number;
  totalFarmInUsd: number;
  supplyApy: number;
  borrowApy: number;
  farmApy: number;
}

export interface VaultHarvestModel {
  id: VaultName;
  latestHarvest: any;
  previousHarvest: any;
}

