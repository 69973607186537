import { cssAssets } from "../../../../../assets/images/css";
import styled from "styled-components";
import {
  ThemeGray500,
  ThemeGray100,
  GridVars,
  ThemePrimaryText,
  ThemeSecondaryText,
} from "../../../../../utils";
import {
  TextLabel,
  TextValue,
  Text,
} from '../../../../../utils/styles/text'
import { MoneyFormatter } from "../../../../../components/money-formatter";

interface ChartProps {
  readonly $color: string;
  readonly $proportion: number;
  readonly $completeness: number;
}

interface ChartLiquidationProps extends ChartProps {
  readonly $isUnderChart: boolean;
}

interface PercentageContainerToProps {
  readonly $color: string;
}

interface PercentageContainerProps {
  readonly $isUnderChart: boolean;
}

interface StartStickProps {
  readonly $isUnderChart: boolean;
}

interface ChartLabelItemProps {
  readonly $supplyThreshold?: number;
}

export const ChartWrapper = styled.div`
  margin-bottom: ${GridVars.margin.m3};
  position: relative;
`;

export const ChartAbove = styled.div`
  display: flex;
  right: 0;
  top: -58px;
  position: absolute;
  width: 100%;
`;

export const Chart = styled.div`
  display: flex;
  width: 100%;
`;

export const ChartBelow = styled.div`
  display: flex;
  width: 100%;
  justify-content: right;
  margin-top: ${GridVars.margin.m2};
  height: 40px;
`;

export const ChartLabelItem = styled.div<ChartLabelItemProps>`
  width: 130px;
  position: absolute;
  left: ${(props) => (props.$supplyThreshold ? props.$supplyThreshold : 100)}%;
  transform: translateX(-100%);
`;

export const ChartLabelItemName = styled(TextLabel)<ChartLabelItemProps>`
  display: block;
  text-align: right;
`;

export const ChartLabelItemValue = styled(TextValue)<ChartLabelItemProps>`
  display: block;
  text-align: right;
`;

export const ChartBorrowLimit = styled.div<ChartProps>`
  overflow: hidden;
  position: relative;

  width: ${(props) => props.$proportion}%;
  height: 8px;
  border-radius: 4px;

  background: ${ThemeGray500};

  &:before {
    content: "";
    top: 0;
    left: 0;
    z-index: 1;
    position: absolute;

    width: ${(props) => props.$completeness}%;
    height: 8px;

    border-radius: 4px;
    background: ${(props) => props.$color};

    transition: all 0.3s;
  }
`;

export const ChartLiquidation = styled.div<ChartLiquidationProps>`
  position: relative;

  width: ${(props) => props.$proportion}%;
  height: 8px;
  border-radius: 10px;

  background: ${ThemeGray100};

  &:before {
    content: "";
    top: 0;
    left: 0;
    z-index: 1;
    position: absolute;

    width: ${(props) => props.$completeness}%;
    height: 8px;

    border-radius: 4px;
    background: ${(props) => props.$color};

    ${props => props.$completeness <= 0 ? '' : `
      transition: all 0.3s;
      transition-delay: 0.15s;`
    }
  }

  &:after {
    content: "";
    top: -15px;
    right: -2px;
    position: absolute;
    z-index: 5;

    ${(props) => startStickPosition(props.$isUnderChart)};
    width: 5px;
    height: 22px;
  }
`;

export const StartStick = styled.div<StartStickProps>`
  &:after {
    content: "";

    width: 5px;
    height: 22px;

    ${(props) => startStickPosition(props.$isUnderChart)};
    left: -2px;
    z-index: 5;
    position: absolute;
  }
`;

const startStickPosition = (isUnderChart: boolean): string => {
  if (isUnderChart) {
    return `
        top: 1px;
        background: url('${cssAssets.pin.reversed}');
        `;
  } else {
    return `
        top: -15px;
        background: url('${cssAssets.pin.normal}');
        `;
  }
};

export const PercentageContainer = styled.div<PercentageContainerProps>`
  min-height: 42px;
  color: ${ThemePrimaryText};
  font-size: 0.85rem;

  gap: 16px;
  display: flex;
  align-items: baseline;

  margin-bottom: ${(props) => (props.$isUnderChart ? "8px" : "16px")};
  div {
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
    flex-shrink: 0;
  }
`;

export const PercentageContainerFrom = styled(Text).attrs({ $weight: 'strong' })`
  font-size: 1.1rem;
  text-align: right;
`;

export const PercentageContainerTo = styled(Text).attrs({ $weight: 'strong' })<PercentageContainerToProps>`
  font-size: 32px;
  color: ${(props) => props.$color};
  font-weight: 600;
  flex: 1 1 0;
  min-width: 0;

  span {
    color: ${ThemeSecondaryText};
  }
`;

export const WrapMoneyFormatter = styled(MoneyFormatter)`
  white-space: normal;
`
