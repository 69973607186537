import { transactionStatusCodes } from "../components/transaction-status/transaction-status.codes";

export const NOT_ENOUGH_FUNDS = "NOT_ENOUGH_FUNDS";
export const USER_REJECTED = "USER_REJECTED";
export const RPC_ERROR = "RPC_ERROR";
export const NETWORK_CHANGED = "NETWORK_CHANGED";

type ErrorPattern = { msg?: string; code?: number };

const TX_ERROR_PATTERNS: { [key: string]: ErrorPattern[] } = {
  [NOT_ENOUGH_FUNDS]: [
    { msg: "insufficient funds for gas" },
    { msg: "not enough funds for gas" },
    { msg: "failed to execute call with revert code InsufficientGasFunds" },
  ],
  [USER_REJECTED]: [{ msg: "user rejected transaction" }],
  [NETWORK_CHANGED]: [{ msg: "underlying network changed" }],
  [RPC_ERROR]: Object.keys(transactionStatusCodes).map((code) => ({ code: Number(code) })),
};

export type TxError = {
  message?: string;
  code?: number;
  data?: any;
  reason?: any
};

export function extractError(error: TxError): [
  reason: string | null,
  message: string | null,
  type: (keyof typeof TX_ERROR_PATTERNS) | null,
  data: any,
] {
  if (!error) {
    return [null, null, null, null];
  }

  const reason = error.reason;
  const message = error.data?.error?.message ?? error.data?.message ?? error.message;
  const code = error.code;

  if (!message && !code) {
    return [null, null, null, null];
  }

  for (const [type, patterns] of Object.entries(TX_ERROR_PATTERNS)) {
    for (const pattern of patterns) {
      const matchCode = 'code' in pattern && code === pattern.code;
      const matchMessage = pattern.msg && message && message.includes(pattern.msg);

      if (matchCode || matchMessage) {
        return [reason as string, message as string, type as keyof typeof TX_ERROR_PATTERNS, error.data];
      }
    }
  }

  return [reason as string, message as string, null, error.data];
}
