import styled from "styled-components";
import {ThemePrimary, ThemeSecondaryText} from "../../utils";
import {AnimationDuration} from "../../utils/styles/animation.styles";

export const ToggleContainer = styled.div`
  position: relative;
  width: 25px;
  height: 14px;
`;

export const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${ThemeSecondaryText};
  transition: ${AnimationDuration.short};
  border-radius: 34px;
  
  &:before {
    position: absolute;
    content: "";
    height: 10px;
    width: 10px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: ${AnimationDuration.short};
    border-radius: 50%;
  }
`;

export const Input = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
  
  &:checked + ${Slider} {
    background-color: ${ThemePrimary};
  }

  &:focus + ${Slider} {
    box-shadow: 0 0 1px ${ThemePrimary};
  }

  &:checked + ${Slider}:before {
    transform: translateX(11px);
  }
`;
