import * as Styles from "./styles";

interface LoaderProps {
  type?: string;
}

export const Loader = ({ type = "loading" }: LoaderProps) => {
  switch (type) {
    case "loading":
      return (
        <Styles.AnimationWrapper data-testid={type}>
          <Styles.LoadingPie1 />
          <Styles.LoadingPie2 />
        </Styles.AnimationWrapper>
      );
    case "success":
      return (
        <Styles.AnimationWrapper data-testid={type}>
          <Styles.CompletedPie1 />
          <Styles.CompletedPie2 />
          <Styles.CompletedPie3 />
          <Styles.CompletedPie4 />
          <Styles.CompletedCheckmark />
        </Styles.AnimationWrapper>
      );
    case "failed":
      return (
        <Styles.AnimationWrapper data-testid={type}>
          <Styles.CompletedPie1 />
          <Styles.CompletedPie2 />
          <Styles.CompletedPie3 />
          <Styles.CompletedPie4 />
          <Styles.CompletedCross />
        </Styles.AnimationWrapper>
      );
    default:
      return (
        <Styles.AnimationWrapper data-testid={type}>
          <Styles.LoadingPie1 />
          <Styles.LoadingPie2 />
        </Styles.AnimationWrapper>
      );
  }
};
