import { createAsyncThunk } from "@reduxjs/toolkit";
import { selectVault } from "../../../store/vault/vault.slice";
import { sdk } from "../../../services/sdk-provider";
import { VaultName } from "@altitude-fi/altitude-v1-sdk/dist/types";

export const fetchVaultsSupplyBasePrice = createAsyncThunk(
  "vault/fetchVaultsSupplyBasePrice",
  async (data, thunkApi) => {
    try {
      const { vault }: any = thunkApi.getState();
      const vaultEthUsdcSupplyBasePrice = await getVaultSupplyBasePrice(
        "wstethUsdc",
        vault
      );

      // const vaultWbtcDaiSupplyBasePrice = await getVaultSupplyBasePrice(
      //   "wbtcDai",
      //   vault
      // );

      return [vaultEthUsdcSupplyBasePrice];
      // return [vaultWbtcDaiSupplyBasePrice];
      // return [vaultEthUsdcSupplyBasePrice, vaultWbtcDaiSupplyBasePrice];
    } catch (error: any) {
      const message = error.message;
      return thunkApi.rejectWithValue(message);
    }
  }
);

const getVaultSupplyBasePrice = async (
  vaultType: VaultName,
  vault: any
): Promise<{
  vaultType: VaultName;
  price: number;
  borrowToUsdRatio: number;
}> => {
  const {
    activeLenderStrategy,
    supplyTokenAddress,
    borrowTokenAddress,
    borrowDecimals,
  }: any = selectVault(vault, vaultType) || "";

  const activeStrategyContract = sdk.multi.vaults[vaultType].lendingStrategies.find(strategy => strategy.address === activeLenderStrategy)
  if (!activeStrategyContract) {
    throw new Error(`Active strategy ${activeLenderStrategy} was not found`)
  }
  const [supplyBasePrice, borrowToUsdRatio, usdDecimals] = await sdk.getMultiData([
    activeStrategyContract.getInBase(
      supplyTokenAddress,
      borrowTokenAddress
    ),
    sdk.multi.custom.chainlinkAggregator.latestRoundData(),
    sdk.multi.custom.chainlinkAggregator.decimals(),
  ]);

  return {
    vaultType,
    price: sdk.utils.convertFromWei(supplyBasePrice, borrowDecimals),
    borrowToUsdRatio: sdk.utils.convertFromWei(borrowToUsdRatio[1], usdDecimals),
  };
};
