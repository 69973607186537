import styled, { css } from "styled-components";

import { GridVars } from "./grid.styles";
import {
  ThemePrimaryText,
  ThemePrimary,
  ThemeGray500,
  ThemeSecondaryText,
  ThemeGray800, ThemeGray50,
} from "./theme.styles";
import {
  AnimationDuration,
  AnimationFadeIn,
  AnimationFadeOut,
  AnimationKeyFramesBottomSlideIn
} from "./animation.styles";

interface ModalHeaderTabProps {
  readonly $active?: boolean;
  readonly $disabled?: boolean;
}

interface ModalBackgroundProps {
  readonly $show: boolean;
}

interface ModalContentTabProps {
  readonly $animating?: boolean;
}

export const ModalBackground = styled.div<ModalBackgroundProps>`
  align-items: center;
  justify-content: center;
  display: flex;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: rgba(26, 39, 50, 0.7);
  backdrop-filter: blur(5px);
  ${props => props.$show ? AnimationFadeIn: AnimationFadeOut};


`;

export const ModalWrapper = styled.div`
  margin: ${GridVars.margin.m1};
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 80vh;
  max-width: ${GridVars.modalWidth};
  width: 600px;
`;

export const ModalCloseBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  align-self: flex-end;
  margin: ${GridVars.margin.m1};

  svg {
    color: ${ThemePrimaryText};
  }

  span {
    color: ${ThemePrimaryText};
    font-size: 1rem;
    font-weight: 600;
    padding-right: ${GridVars.padding.p1};
  }
`;

export const ModalContent = styled.div`
  border-radius: 8px;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;

  background: ${ThemeGray800};
  position: relative;
  `;

export const ModalContentTab = styled.div<ModalContentTabProps>`
  ${props => props.$animating ? css`animation: ${AnimationKeyFramesBottomSlideIn} ${AnimationDuration.short} ease-in;` : ''}
`;

export const ModalHeader = styled.div`
  border-radius: 8px 8px 0 0;
  background: ${ThemeGray500};
  height: 56px;
  padding: 0 ${GridVars.padding.p3};
  display: flex;
`;

export const ModalHeaderTab = styled.div<ModalHeaderTabProps>`
  width: 100%;
  height: 56px;
  border-bottom: 2px solid ${(props) => (props.$active ? ThemePrimary : "transparent")};
  color: ${(props) => {
      if (props.$active) {
          return ThemePrimary;
      }
      if (props.$disabled) {
          return ThemeGray50;
      }
      return ThemeSecondaryText;
  }};
  line-height: 56px;
  text-align: center;
  font-weight: 600;
  cursor: ${(props) => props.$disabled ? 'not-allowed' : 'pointer'};
  transition-property: all;
  transition-duration: ${AnimationDuration.short};

  &:hover {
    border-bottom: 2px solid ${(props) => {
      if (props.$active) {
        return ThemePrimary;
      }
      if (props.$disabled) {
        return 'transparent';
      }
      return ThemeSecondaryText;
    }};
  }
`;
