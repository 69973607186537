import { useCallback } from "react";
import {
  useConnect,
  useDisconnect,
  useConnectors
} from "wagmi";
import { useAppDispatch } from "../hooks";
import { disconnectUser } from "../store/user/actions";

export const useWallet = () => {
  const dispatch = useAppDispatch();
  const { connectAsync: connectWagmi } = useConnect();
  const connectors = useConnectors();
  const { disconnectAsync: disconnectWagmi } = useDisconnect();


  const connect = useCallback(
    async (connector: string) => {
      const selectedConnector = connectors.find((c) => c.id === connector || c.type === connector);
      if (!selectedConnector) {
        throw new Error("Wallet type not supported");
      }

      await connectWagmi({ connector: selectedConnector });
    },
    [connectWagmi, connectors]
  );

  const disconnect = useCallback(async () => {
    await disconnectWagmi();
    dispatch(disconnectUser());
  }, [dispatch, disconnectWagmi]);

  return {
    connect,
    disconnect,
  };
};
