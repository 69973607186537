export const CHART_SIZES = {
  LARGE: {
    RADIUS: 140,
    STROKE_WIDTH: 12,
    ANGLE_GAP: 3,
    ANNOTATIONS_PADDING: 15,
    CAPTIONS_PERCENTAGE_SIZE: 46,
    CAPTIONS_PERCENTAGE_HEIGHT: 48,
    CAPTIONS_SIGN_SIZE: 24,
    CAPTIONS_TEXT_SIZE: 15,
  },
  MEDIUM: {
    RADIUS: 110,
    STROKE_WIDTH: 10,
    ANGLE_GAP: 3,
    ANNOTATIONS_PADDING: 15,
    CAPTIONS_PERCENTAGE_SIZE: 42,
    CAPTIONS_PERCENTAGE_HEIGHT: 46,
    CAPTIONS_SIGN_SIZE: 20,
    CAPTIONS_TEXT_SIZE: 14,
  },
  SMALL: {
    RADIUS: 90,
    STROKE_WIDTH: 8,
    ANGLE_GAP: 3,
    ANNOTATIONS_PADDING: 15,
    CAPTIONS_PERCENTAGE_SIZE: 36,
    CAPTIONS_PERCENTAGE_HEIGHT: 36,
    CAPTIONS_SIGN_SIZE: 12,
    CAPTIONS_TEXT_SIZE: 12,
  },
};
