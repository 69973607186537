import {useMemo, useEffect, useCallback} from "react";
// import { DateTime } from "luxon";

import { LoaderInline } from "../../../../components";
import TokenBalance from "../../../../components/token-balance";
import { PROJECT_NAME } from "../../../../config";
import { useAppSelector } from "../../../../hooks";
import { TokenDisplayType } from "../../../../models/vault";
import { selectUserVault } from "../../../../store/userVault/userVault.slice";
import { TextParagraph } from "../../../../utils/styles/text";
import ClaimModal from "../../../../components/claim-modal";
import * as Styles from "./style";
import { useUserPosition } from "../../../../hooks/useUserPosition";
import { usePopupManager } from "react-popup-manager";
import { Button } from "../../../../utils/styles/button";

let backgroundLoading = false;

const SmartRepayments = () => {
  const userVaultStore = useAppSelector((state) => state.userVault);
  const { selectedVault } = useAppSelector((state) => state.global);
  const popupManager = usePopupManager();

  const { claimableRewards }: any =
    selectUserVault(userVaultStore, selectedVault) || {};
  const { data: userPosition, isLoading: userPositionLoading } = useUserPosition();

  const isLoading = useMemo(() => (
    userPositionLoading && !backgroundLoading
  ), [ userPositionLoading ]);

  useEffect(() => {
    if (!isLoading && !backgroundLoading) {
      backgroundLoading = true;
    }
  }, [isLoading]);

  // const renderLoanRepaid = () => {
  //
  //   if (isLoading) {
  //     return <ValueLoader />;
  //   }
  //
  //   if (userPosition?.projected && userPosition.projected.repaidBy > 0) {
  //     const repaidByDateInMs = new Date().getTime() + userPosition.projected.repaidBy
  //     if (repaidByDateInMs > Number.MAX_SAFE_INTEGER) {
  //       return <Styles.SmartValueValue>&infin;</Styles.SmartValueValue>;
  //     }
  //     const repaidByDate = DateTime.fromISO(new Date(repaidByDateInMs).toISOString());
  //     const now = DateTime.now();
  //     const diff = repaidByDate.diff(now, ["years", "months", "days", "hours"]);
  //     return (
  //       <Styles.SmartValueValue>
  //         <span>around</span> {diff.years} {diff.years > 1 ? "years" : "year"},{" "}
  //         {diff.months} {diff.months > 1 ? "months" : "month"}
  //       </Styles.SmartValueValue>
  //     );
  //   }
  //
  //   return <Styles.SmartValueValue>n/a</Styles.SmartValueValue>;
  // };

  const openClaimEarningsPopup = useCallback(() => {
    popupManager.open<any>(ClaimModal, {
      title: "ClaimEarningsModal",
      onClose: () => {},
    });
  }, [popupManager]);

  return (
    <Styles.SmartRepaymentContainer>
      <Styles.SmartRepaymentWrapper>
        <Styles.SmartHeader>Smart Repayments</Styles.SmartHeader>
        <Styles.SmartDesc>
          <TextParagraph>
            {PROJECT_NAME} puts un-utilized borrowing capacity to work by generating yield, which is used
            to automatically repay your loan.
          </TextParagraph>
          {/* <TextParagraph>
            The amount below is the total amount that has been automatically
            repaid to date.
          </TextParagraph> */}
        </Styles.SmartDesc>
        <Styles.SmartLine />
        <Styles.SmartValueBoxContainer>
          {/*<Styles.SmartValueBox>*/}
          {/*  <Styles.SmartValueLabel>*/}
          {/*    Estimated Monthly Repayments*/}
          {/*  </Styles.SmartValueLabel>*/}
          {/*  {userPosition?.projected && userPosition?.projected.monthlyRepayments > 0 ? (*/}
          {/*    <Styles.SmartValueValue>*/}
          {/*      <span>around</span>{" "}*/}
          {/*      <Styles.SmartValueAmount>*/}
          {/*        <TokenBalance*/}
          {/*          displayType={TokenDisplayType.borrow}*/}
          {/*          vaultType={selectedVault}*/}
          {/*          amount={userPosition?.projected.monthlyRepayments || 0}*/}
          {/*        />*/}
          {/*      </Styles.SmartValueAmount>*/}
          {/*    </Styles.SmartValueValue>*/}
          {/*  ) : (*/}
          {/*    <Styles.SmartValueValue>*/}
          {/*      {isLoading ? (<ValueLoader />) : "n/a"}*/}
          {/*    </Styles.SmartValueValue>*/}
          {/*  )}*/}
          {/*</Styles.SmartValueBox>*/}
          {/*<Styles.SmartValueBox>*/}
          {/*  <Styles.SmartValueLabel>*/}
          {/*    Expected Full Loan Repayment*/}
          {/*  </Styles.SmartValueLabel>*/}
          {/*  <Styles.SmartValueValue>*/}
          {/*    {renderLoanRepaid()}*/}
          {/*  </Styles.SmartValueValue>*/}
          {/*</Styles.SmartValueBox>*/}

          <Styles.SmartValueBox>
            <Styles.SmartValueLabel>
              Earnings Pending Harvest
            </Styles.SmartValueLabel>
            <Styles.SmartValueContainer>
              <Styles.SmartValueValue>
              {userPosition?.projected && userPosition?.currentHarvest.currentHarvestEarnings ? (
                <>
                  <span>around</span>{" "}
                  <Styles.SmartValueAmount>
                    <TokenBalance
                      displayType={TokenDisplayType.borrow}
                      vaultType={selectedVault}
                      amount={userPosition?.currentHarvest.currentHarvestEarnings}
                    />
                  </Styles.SmartValueAmount>
                </>
              ) : (
                isLoading ? <ValueLoader /> : "n/a"
              )}
              </Styles.SmartValueValue>
            </Styles.SmartValueContainer>
          </Styles.SmartValueBox>
          {claimableRewards > 0 && (
          <Styles.SmartValueBox>
            <Styles.SmartValueLabel>
              Claimable Earnings
            </Styles.SmartValueLabel>
            <Styles.SmartValueContainer>
              <Styles.SmartValueValue>
                <TokenBalance
                  displayType={TokenDisplayType.borrow}
                  vaultType={selectedVault}
                  amount={claimableRewards}
                />
              </Styles.SmartValueValue>
              <Button
                $variant='tertiary'
                onClick={openClaimEarningsPopup}
                $size="inline">
                <span>claim</span>
              </Button>
            </Styles.SmartValueContainer>
          </Styles.SmartValueBox>
          )}
        </Styles.SmartValueBoxContainer>
      </Styles.SmartRepaymentWrapper>
    </Styles.SmartRepaymentContainer>
  );
};

const ValueLoader = () => {
  const rects = [
    {
      y: 0,
      x: 0,
      rx: 4,
      ry: 4,
      width: 100,
      height: 15,
    }
  ];
  return <LoaderInline height={15} viewBoxWidth={100} loaderSettings={rects} />;
};

export default SmartRepayments;
