import * as React from "react";
import { SVGProps } from "react";
const SvgMenuDots = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>Menu Dots</title>
    <circle cx={13} cy={19.5} r={2.5} fill="currentColor" />
    <circle cx={20} cy={19.5} r={2.5} fill="currentColor" />
    <circle cx={27} cy={19.5} r={2.5} fill="currentColor" />
  </svg>
);
export default SvgMenuDots;
