import { ThemePrimaryText } from "../../../utils";
import { useTheme } from "styled-components";

const ReadOnlyOption = () => {
  const theme = useTheme();
  return (
    <svg width="17" height="17" viewBox="0 0 445 451" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M221.501 430.002C334.444 430.002 426.002 338.444 426.002 225.501C426.002 112.558 334.444 21 221.501 21C108.558 21 17 112.558 17 225.501C17 338.444 108.558 430.002 221.501 430.002ZM365 225.5C365 245.5 301 324 222 324C143 324 79 240.5 79 225.5C79 210.5 141 127 222 127C303 127 365 205.5 365 225.5ZM280.667 225.798C280.667 258.016 254.548 284.135 222.33 284.135C190.111 284.135 163.993 258.016 163.993 225.798C163.993 193.579 190.111 167.461 222.33 167.461C254.548 167.461 280.667 193.579 280.667 225.798Z"
            fill={ThemePrimaryText({ theme })} />
    </svg>
  )
};

export default ReadOnlyOption;
