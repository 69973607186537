import { useSelector } from "react-redux";
import type { TypedUseSelectorHook } from "react-redux";
import { RootState, userSet } from "../store";
import { selectVaultHarvests } from "../store/vaultHarvest/vaultHarvest.slice";
import { selectAllVaults, selectVault } from "../store/vault/vault.slice";
import { selectUser } from "../store/user/user.slice";
import { selectUserVault } from "../store/userVault/userVault.slice";
import { fetchVaultsData, fetchVaultsSupplyBasePrice } from "../store/vault/actions";
import { fetchUserVaultData } from "../store/userVault/actions";
import { useAppDispatch } from "./useAppDispatch";
import { useSdk } from "../services/sdk-provider";
import useSWR from "swr";
import { fetchVaultHarvestData } from "../store/vaultHarvest/vaultHarvest.actions";
import { selectVaultStats } from "../store/vaultStats/vaultStats.slice";
import { fetchVaultStats } from "../store/vaultStats/actions";
import { VaultModel } from "../models";
import { useAnalytics } from "./useAnalytics";
import { useEffect } from "react";
import { CONNECT_WALLET, sendNotificationIfNetworkUnsupported } from "../utils";
import { useAccount, useConnectorClient } from "wagmi";
import { impersonator } from "../utils/impersonator";

const USER_DATA_REFRESH_INTERVAL = 10000;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useVaultHarvests = () => {
  const dispatch = useAppDispatch();
  const { selectedVault } = useAppSelector((state) => state.global);
  const { data: { id: vaultId} = {} } = useVault();
  const { data: { network } = {} } = useUser();
  const vaultHarvestStore = useAppSelector((state) => state.vaultHarvest);
  const vaultStore = useAppSelector((state) => state.vault);
  const { previousHarvest, latestHarvest } = selectVaultHarvests(vaultHarvestStore, selectedVault) || {};
  const { harvestsCount } = selectVault(vaultStore, selectedVault) || {};

  useSWR((!isNaN(Number(harvestsCount)) && vaultId) ? [network, vaultId] : null, ([, vaultId]) => {
    dispatch(fetchVaultHarvestData({ selectedVault: vaultId }));
  }, { refreshInterval: USER_DATA_REFRESH_INTERVAL })

  return { data: { id: selectedVault, previousHarvest, latestHarvest }, isLoading: vaultHarvestStore.vaultHarvestLoading }
}

export const useVault = () => {
  const dispatch = useAppDispatch();
  const { selectedVault } = useAppSelector((state) => state.global);
  const vaultStore = useAppSelector((state) => state.vault);
  const { isInitialised } = useSdk();

  useSWR(isInitialised ? ['Vault'] : null, () => {
    dispatch(fetchVaultsData());
  }, { refreshInterval: USER_DATA_REFRESH_INTERVAL });

  return { data: selectVault(vaultStore, selectedVault), isLoading: vaultStore.vaultLoading};
}

export const useVaults = () => {
  const dispatch = useAppDispatch();
  const vaultStore = useAppSelector((state) => state.vault);
  const vaults = selectAllVaults(vaultStore);
  const { isInitialised } = useSdk();

  useSWR(isInitialised ? ['Vaults'] : null, () => {
    dispatch(fetchVaultsData());
  });

  return { data: vaults }
}

export const useVaultStats = (vault: VaultModel) => {
  const vaultStatsStore = useAppSelector((state) => state.vaultStats);
  const dispatch = useAppDispatch();

  useSWR(vault ? ['VaultStats', vault] : null, ([, vault]) => {
    dispatch(fetchVaultStats({ vault }));
  });
  const stats = selectVaultStats(vaultStatsStore, vault.id);
  return { data: stats, isLoading: vaultStatsStore.loading }
}

export const useUser = () => {
  const dispatch = useAppDispatch();
  const userStore = useAppSelector((state) => state.user);
  const { chain, chainId: connectorChainId, address, connector: activeConnector } = useAccount();
  const { data: walletClient } = useConnectorClient();

  const { identify } = useAnalytics();
  useEffect(() => {
    const setUserData = async () => {
      if (address && connectorChainId && activeConnector && walletClient) {
        sendNotificationIfNetworkUnsupported(connectorChainId);

        dispatch(
          userSet({
            value: {
              userAddress: address,
              selectedWallet: activeConnector.id,
              isReadOnly: activeConnector.type === impersonator.type,
              network: connectorChainId,
              walletConnected: true,
              connectionStatus: CONNECT_WALLET.connected,
              theme: "dark",
            },
          })
        );
        identify(address, {
          name: address,
          wallet: activeConnector.id,
          network: connectorChainId
        })
      }
    };
    setUserData();
  }, [dispatch, address, chain, activeConnector, walletClient, connectorChainId, identify]);
  return { data: selectUser(userStore), isLoading: userStore.userLoading };
}

export const useUserVaultInfo = () => {
  const dispatch = useAppDispatch();
  const { data: { userAddress, network } = {} } = useUser()
  const userVaultStore = useAppSelector((state) => state.userVault);
  const { selectedVault } = useAppSelector((state) => state.global);
  const { data: { id: vaultId} = {} } = useVault();
  const { isInitialised } = useSdk();

  useSWR((vaultId && isInitialised) ? ['VaultSupplyBasePrice', vaultId, isInitialised, network] : null, () => {
    dispatch(fetchVaultsSupplyBasePrice());
  }, { refreshInterval: USER_DATA_REFRESH_INTERVAL });

  useSWR((userAddress && vaultId) ? ['UserVaultInfo', vaultId, network] : null, ([, vaultId]) => {
    dispatch(fetchUserVaultData({ selectedVault: vaultId }));
  }, { refreshInterval: USER_DATA_REFRESH_INTERVAL });

  return { data: selectUserVault(userVaultStore, selectedVault), isLoading: userVaultStore.userVaultLoading };
}
