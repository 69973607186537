import styled from "styled-components";
import {
  AnimationFadeIn,
  Box,
  BoxHeader,
  BoxHeaderSquare,
  GridVars,
} from "../../../../utils";
import { TextLabel, TextLabelProps } from "../../../../utils/styles/text";

export const UserStatContainer = styled(Box)`
  grid-area: userStats;
`;

export const UserStatBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: ${GridVars.gap};
  position: relative;

  @media (max-width: ${GridVars.breakpoint.sm}) {
    flex-direction: column;
    grid-template-columns: 1fr;
    grid-row-gap: ${GridVars.margin.m4};
  }
`;

export const UserStatBoxItem = styled.div``;

export const UserStatBoxHeader = styled(BoxHeader)``;

export const UserStatBoxValueWrapper = styled.div`
  @media (max-width: ${GridVars.breakpoint.sm}) {
    display: flex;
    justify-content: space-between;
  }
`;

export const UserStatBoxValue = styled(TextLabel).attrs<TextLabelProps>({ size: 'large' })``;

export const RepayingWrapper = styled.div`
  margin-top: ${GridVars.margin.m1};
  min-height: 22px;
  div:first-child {
    margin: 0px;
  }

  @media (max-width: ${GridVars.breakpoint.sm}) {
    margin-top: 0;
  }
`;

export const EllipsisWrapper = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 5ch;
  display: inline-block;
`;

export const RepayingButton = styled.div`
  ${AnimationFadeIn}
`;

export { BoxHeader, BoxHeaderSquare };
