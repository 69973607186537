import { createConnector } from "wagmi";
import { mock } from "wagmi/connectors";

let impersonatedAccounts: any[] = []

impersonator.id = 'impersonator'
impersonator.type = 'impersonator'

export function impersonator() {
  return createConnector((config) => {
    const connector = mock({ accounts: impersonatedAccounts as any })(config);
    const getProvider = connector.getProvider
    connector.getProvider = async () => {
      const provider = await getProvider();
      return {
        ...provider,
        request: async (args: any): Promise<any> => {
          if (args.method === 'eth_accounts' || args.method === 'eth_requestAccounts') {
            return impersonatedAccounts
          }
          return provider.request(args)
        }
      }
    }
    return {
      ...connector,
      name: 'Impersonator',
      id: impersonator.id,
      type: impersonator.type
    };
  }) as any
}

export function setImpersonatedAccounts (accounts: any) {
  if (!Array.isArray(accounts)) {
    accounts = [accounts];
  }
  impersonatedAccounts = accounts;
}
