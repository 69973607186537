import { createGlobalStyle } from "styled-components";
import { ThemePrimary, ThemePrimaryText } from "./theme.styles";

export const GlobalStyle = createGlobalStyle`
  .react-tiny-popover-container {
    z-index: 2000 !important;
  }
  *::selection {
      background: ${ThemePrimary};
      color: ${ThemePrimaryText};
  }
`;
