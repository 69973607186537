import styled from "styled-components";
import {
  GridVars,
  Box,
  ElementHR,
  LayoutSpaceBetweenRow,
  ThemePrimaryText,
  ThemeSecondaryText,
  LayoutInlineFlexRow
} from "../../../../utils";
import {
  TextLabel,
  TextValue,
  TextParagraph,
  TextHeader2,
} from '../../../../utils/styles/text'

export const SmartRepaymentContainer = styled.div`
  grid-area: smartRepayments;
`;

export const SmartHeader = styled(TextHeader2)``;

export const SmartDesc = styled(TextParagraph)``;

export const SmartValue = styled(TextHeader2)`
  margin-top: 32px;
  color: ${ThemePrimaryText};
`;

export const SmartLine = styled(ElementHR)``;

export const SmartValueBoxContainer = styled.div`
  margin-top: ${GridVars.margin.m2};
  display: flex;
  flex-direction: column;
  gap: ${GridVars.gap};
`;

export const SmartRepaymentWrapper = styled(Box)`
  height: 100%;
  @media (min-width: ${GridVars.breakpoint.xl}) {
    height: auto;
  }
`;

export const SmartValueBox = styled(LayoutSpaceBetweenRow)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const SmartValueContainer = styled(LayoutInlineFlexRow)``

export const SmartValueLabel = styled(TextLabel)`
  color: ${ThemePrimaryText};
`;

export const SmartValueValue = styled(TextValue)`
  text-align: right;
  span {
    color: ${ThemeSecondaryText};
  }
`;

export const SmartValueAmount = styled.span`
  span {
    color: ${ThemePrimaryText};
  }
`;
