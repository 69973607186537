import * as React from "react";
import { SVGProps } from "react";
const SvgInfo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>Info</title>
    <path
      opacity={1}
      d="M6 12A6 6 0 1 1 6 0a6 6 0 0 1 0 12Zm0-1.2a4.8 4.8 0 1 0 0-9.6 4.8 4.8 0 0 0 0 9.6ZM5.4 3h1.2v1.2H5.4V3Zm0 2.4h1.2V9H5.4V5.4Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgInfo;
