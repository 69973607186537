import { Loader } from "../../../../components/loader/loader";
import * as Styles from "../../../../components/transaction-status/components/style";
import {useState} from "react";

interface FailureProps {
  header?: string;
  message?: string;
  fullError?: string;
}

const Failure = ({
  header = "Transaction Failed",
  message = "",
  fullError = ""
}: FailureProps) => {
  const [showMore, setShowMore] = useState(false)
  return (
    <Styles.TransactionContainer>
      <Styles.TransactionHeader>{header}</Styles.TransactionHeader>
      <Styles.TransactionDesc>{message}</Styles.TransactionDesc>
      {fullError && (
        <Styles.ErrorContainer>
          <Styles.ShowMore onClick={() => setShowMore(prev => !prev)}>Show {showMore ? `less` : `more`}</Styles.ShowMore>
          {showMore && <Styles.ErrorDetails>{fullError}</Styles.ErrorDetails>}
        </Styles.ErrorContainer>
      )}
      <Styles.IconContainer>
        <Loader type="failed" />
      </Styles.IconContainer>
    </Styles.TransactionContainer>
  );
};

export default Failure;
