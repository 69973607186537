import * as React from "react";
import { SVGProps } from "react";
const SvgCheckboxChecked = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    {...props}
  >
    <title>Checkbox Checked</title>
    <path
      fill="currentColor"
      d="M7.475 12.975 14.7 5.75l-1.075-1.075-6.15 6.15L4.5 7.85 3.425 8.925l4.05 4.05ZM1.5 18c-.4 0-.75-.15-1.05-.45-.3-.3-.45-.65-.45-1.05v-15C0 1.1.15.75.45.45.75.15 1.1 0 1.5 0h15c.4 0 .75.15 1.05.45.3.3.45.65.45 1.05v15c0 .4-.15.75-.45 1.05-.3.3-.65.45-1.05.45h-15Zm0-1.5h15v-15h-15v15Zm0-15v15-15Z"
    />
  </svg>
);
export default SvgCheckboxChecked;
