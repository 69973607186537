export interface AnalyticsInterface {
  identify: (id: string, data?: object) => void
  track: (eventId: string, data?: object) => void
  page: (data?: object) => void
}

export class CombinedAnalytics implements AnalyticsInterface {
  private analytics: AnalyticsInterface[] = []
  constructor(analytics: AnalyticsInterface[]) {
    this.analytics = analytics;
  }

  // use arrow functions to ensure `this` context is not undefined
  identify = (id: string, data: object | undefined): void => {
    for (const instance of this.analytics) {
      instance.identify(id, data);
    }
  }

  page = (data: object | undefined): void => {
    for (const instance of this.analytics) {
      instance.page(data);
    }
  }

  track = (eventId: string, data: object | undefined): void => {
    for (const instance of this.analytics) {
      instance.track(eventId, data);
    }
  }
}

export class DoNotTrack implements AnalyticsInterface {
  identify(id: string, data: object = {}) {}
  track(eventId: string, data: object = {}) {}
  page(data: object = {}) {}
}

