import React, {useCallback, useEffect, useMemo, useState} from "react";
import { usePopupManager } from "react-popup-manager";

import {ClaimModal, InfoPopover, LoaderInline, MoneyFormatter} from "../../../../components";
import DropDownMenu from "../../../../components/drop-down-menu";
import TokenBalance from "../../../../components/token-balance";
import { TokenDisplayType } from "../../../../models/vault";
import BorrowRepaymentModal from "../../../../modules/dashboard/modals/borrow-repayment";
import DepositWithdrawModal from "../../../../modules/dashboard/modals/deposit-withdraw";
import { TakeLoanModal } from "../../modals/take-loan/take-loan.modal";

import * as Styles from "./style";
import Toggle from "../../../../components/toggle/toggle";
import * as PopoverStyles from "../../../../components/custom-popover/styles";
import { useUser, useUserVaultInfo, useVault } from "../../../../hooks/useAppSelector";
import { UserPosition, useUserPosition}  from "../../../../hooks/useUserPosition";

let backgroundLoading = false

const UserLoanStats = () => {
  const popupManager = usePopupManager();
  const { data: { walletConnected } = {}, isLoading: userLoading } = useUser();
  const { data: { id: selectedVault, supplyBasePrice = 0, borrowSymbol } = {}, isLoading: vaultLoading } = useVault();
  const { data: currentPosition, isLoading: userPositionLoading } = useUserPosition();
  const { currentHarvest: { currentHarvestEarnings: projectedEarnings } } = currentPosition as UserPosition;
  const {
    data: {
      id: vaultId,
      debtTokenAmount = 0,
      collateralTokenAmount = 0,
      isActionsByAllowlistBlocked,
      claimableRewards
    } = {},
    isLoading: userVaultLoading
  } = useUserVaultInfo();

  const [includeProjected, setIncludeProjected] = useState<boolean>(true);

  const isLoading = useMemo(() => (userVaultLoading || vaultLoading || userLoading || userPositionLoading) && !backgroundLoading, [userVaultLoading, vaultLoading, userLoading, userPositionLoading]);

  useEffect(() => {
    if (!isLoading && !backgroundLoading) {
      backgroundLoading = true;
    }
  }, [isLoading]);
  const isDataReady = useMemo(() => walletConnected && vaultId && !isLoading, [walletConnected, vaultId, isLoading]);

  const loanAmount = useMemo(() => {
    // Check to make sure projectedEarnings is not NaN
    const calculatedProjectedEarnings = isNaN(projectedEarnings) ? 0 : projectedEarnings;
    if (includeProjected && debtTokenAmount > 0) { 
        // Including projected for users who have debt
        if (debtTokenAmount > calculatedProjectedEarnings) {
          // If the debt is larger than projected earnings, return remaining debt
          return debtTokenAmount - calculatedProjectedEarnings;
        } else {
          // Return 0 as debt repaid by projected earnings
          return 0
        }
    }
    return debtTokenAmount;
  }, [debtTokenAmount, projectedEarnings, includeProjected]);

  const openBorrowRepayPopup = useCallback(() => {
    popupManager.open<any>(BorrowRepaymentModal, {
      title: "BorrowRepaymentModal",
      onClose: () => {},
    });
  }, [popupManager]);

  const openDepositWithdrawPopup = useCallback(() => {
    popupManager.open<any>(DepositWithdrawModal, {
      title: "DepositWithdrawModal",
      onClose: () => {},
    });
  }, [popupManager]);

  const openTakeLoanPopup = useCallback(() => {
    popupManager.open<any>(TakeLoanModal, {
      title: "TakeLoanModal",
      onClose: () => {},
    });
  }, [popupManager]);

  const openClaimRewards = useCallback(() => {
    popupManager.open<any>(ClaimModal, {
      title: "ClaimRewardsModal",
      onClose: () => {},
    });
  }, [popupManager]);

  const menuItems = useMemo(
    () => [
      {
        disabled: !isDataReady || !!isActionsByAllowlistBlocked,
        label: "Deposit and Borrow",
        callback: openTakeLoanPopup,
      },
      {
        disabled: !isDataReady || !claimableRewards,
        label: "Claim Earnings",
        callback: openClaimRewards,
      },
    ],
    [openTakeLoanPopup, claimableRewards, openClaimRewards, isDataReady, isActionsByAllowlistBlocked]
  );

  const collateralAmountInBorrow = useMemo(
    () => supplyBasePrice * collateralTokenAmount,
    [supplyBasePrice, collateralTokenAmount]
  );

  const updateLoanButtonDisabled = useMemo(
    () =>
      !isDataReady ||
      (!collateralTokenAmount && debtTokenAmount <= 0) ||
      isActionsByAllowlistBlocked,
    [
      collateralTokenAmount,
      debtTokenAmount,
      isActionsByAllowlistBlocked,
      isDataReady
    ]
  );

  const updateCollateralButtonDisabled = useMemo(
    () => !isDataReady || isActionsByAllowlistBlocked,
    [isActionsByAllowlistBlocked, isDataReady]
  );

  return (
    <Styles.UserLoanStatContainer>
      <Styles.UserStatBox>
        <Styles.UserStatBoxItem>
          <Styles.UserStatBoxValueWrapper>
            <Styles.BoxHeader color="purpleAlpha">
              <Styles.BoxHeaderSquare color="purpleAlpha" />
              <span>Collateral</span>
            </Styles.BoxHeader>
            {selectedVault && !isLoading ? (
              <>
                <Styles.UserStatBoxValue>
                  <Styles.UserStatBoxTokenAmount data-testid="supply-token-balance">
                    <TokenBalance
                      displayType={TokenDisplayType.supply}
                      vaultType={selectedVault}
                      amount={collateralTokenAmount}
                    />
                  </Styles.UserStatBoxTokenAmount>
                </Styles.UserStatBoxValue>
                <Styles.UserStatBoxToken>
                  <TokenBalance
                    displayType={TokenDisplayType.borrow}
                    vaultType={selectedVault}
                    amount={collateralAmountInBorrow}
                  />
                </Styles.UserStatBoxToken>
              </>
            ) : (
              <StatLoader />
            )}
          </Styles.UserStatBoxValueWrapper>

          <Styles.UpdateButtonWrapper>
            <Styles.UpdateButton
              disabled={updateCollateralButtonDisabled}
              onClick={openDepositWithdrawPopup}
              data-testid="update-collateral"
            >
              Update Collateral
            </Styles.UpdateButton>
          </Styles.UpdateButtonWrapper>
        </Styles.UserStatBoxItem>
        <Styles.UserStatBoxItem>
          <Styles.UserStatBoxValueWrapper>
            <Styles.BoxHeader color="yellowAlpha">
              <Styles.BoxHeaderSquare color="yellowAlpha" />
              <span>Loan</span>
              <InfoPopover>
                <PopoverStyles.Paragraph>
                  <PopoverStyles.PopoverStrong>Loan overview</PopoverStyles.PopoverStrong>
                </PopoverStyles.Paragraph>
                <PopoverStyles.Row>
                  <PopoverStyles.RowLabel>Current Loan</PopoverStyles.RowLabel>
                  <PopoverStyles.RowValue><MoneyFormatter value={debtTokenAmount} prefix={borrowSymbol + " "} /></PopoverStyles.RowValue>
                </PopoverStyles.Row>
                <PopoverStyles.Row>
                  <PopoverStyles.RowLabel>Pending Harvest</PopoverStyles.RowLabel>
                  <PopoverStyles.RowValue><MoneyFormatter value={projectedEarnings} prefix={`around ${borrowSymbol} `} /></PopoverStyles.RowValue>
                </PopoverStyles.Row>
                <PopoverStyles.Paragraph />

                <PopoverStyles.Paragraph>
                  <b>Pending harvest earnings</b> are subject to change
                  and will reset if you update your position
                </PopoverStyles.Paragraph>
              </InfoPopover>
            </Styles.BoxHeader>
            {selectedVault && !isLoading ? (
              <>
                <Styles.UserStatBoxValue>
                  <Styles.UserStatBoxTokenAmount data-testid="borrow-token-balance">
                    <TokenBalance
                      displayType={TokenDisplayType.borrow}
                      vaultType={selectedVault}
                      amount={loanAmount}
                    />
                  </Styles.UserStatBoxTokenAmount>
                </Styles.UserStatBoxValue>
                <Styles.UserStatBoxToken>
                  <Styles.Projection>Pending harvest</Styles.Projection>
                  <Toggle value={includeProjected} onChange={setIncludeProjected} />
                </Styles.UserStatBoxToken>
              </>
            ) : (
              <StatLoader />
            )}
          </Styles.UserStatBoxValueWrapper>

          <Styles.UpdateButtonWrapper>
            <Styles.UpdateButton
              disabled={updateLoanButtonDisabled}
              onClick={openBorrowRepayPopup}
              data-testid="update-debt"
            >
              Update Loan
            </Styles.UpdateButton>
          </Styles.UpdateButtonWrapper>
        </Styles.UserStatBoxItem>
        <DropDownMenu items={menuItems} />
      </Styles.UserStatBox>
    </Styles.UserLoanStatContainer>
  );
};

const StatLoader = () => {
  const rects = [
    {
      y: 2,
      x: 0,
      rx: 3,
      ry: 3,
      width: 50,
      height: 25,
    },
    {
      y: 2,
      x: 55,
      rx: 3,
      ry: 3,
      width: 100,
      height: 25,
    },
    {
      y: 32,
      x: 0,
      rx: 3,
      ry: 3,
      width: 80,
      height: 16,
    },
  ];
  return <LoaderInline height={50} viewBoxWidth={156} loaderSettings={rects} />;
};

export default UserLoanStats;
