import styled from "styled-components";
import {
  GridVars,
  ModalContent,
  ThemeGray500,
  ThemeGray200, ThemePrimaryText, ThemeError,
} from "../../../../utils";
import {
  Text,
  TextHeader2,
  TextParagraph,
} from "../../../../utils/styles/text"

export const WalletOptionsContainer = styled(ModalContent)`
  padding: ${GridVars.margin.m5} 36px 30px 36px;
`;

export const WalletHeader = styled(TextHeader2)`
  text-align: center;
`;

export const WalletSubHeader = styled(TextParagraph)`
  text-align: center;
`;

export const WalletOptionsListItem = styled(Text).attrs({ $weight: 'strong' })`
  width: 100%;
  height: 66px;
  padding: 0 ${GridVars.padding.p3};
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${ThemeGray500};
  border-radius: 4px;
  cursor: pointer;
  img {
    width: 30px;
    border-radius: 6px;
  }

  :hover {
    background: ${ThemeGray200};
  }
`;

type InputProps = {
  $error: boolean
}
export const ImpersonatorInput = styled.input<InputProps>`
  width: 100%;
  height: 32px;
  background: ${ThemeGray200};
  color: ${ThemePrimaryText};
  border-radius: 6px;
  padding: ${GridVars.padding.p1};
  margin-right: ${GridVars.margin.m3};
  display: flex;
  align-items: center;
  border-width: 2px;
  border-style: solid;
  border-color: ${props => props.$error ? `${ThemeError}` : 'transparent'};
  transition: 300ms all;
`;

export const WalletLogo = styled.div`
  font-size: 2.5rem;
  &:hover {
    background: transparent;
  }
  svg, img {
    width: 32px;
    height: 32px;
    &:hover {
        background: transparent;
    }
  }
`;

export const WalletOptionsList = styled.div`
  margin-top: ${GridVars.margin.m4};

  ${WalletOptionsListItem}:not(:first-child) {
    margin-top: ${GridVars.margin.m1};
  }
`;

export const CheckBoxContainer = styled.div`
  margin-top: ${GridVars.margin.m3};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CheckBoxContainerText = styled(Text)`
  user-select: none;

  span {
    font-weight: 600;
    opacity: 1;
    cursor: pointer;
  }
`;
