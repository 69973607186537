import {
  createSlice,
  PayloadAction,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import { VaultHarvestModel } from "../../models";

import { fetchVaultHarvestData } from "./vaultHarvest.actions";

const vaultHarvestAdapter = createEntityAdapter<VaultHarvestModel>({
  selectId: (vaultHarvest) => vaultHarvest.id,
});

const vaultHarvestSlice = createSlice({
  name: "vaultHarvest",
  initialState: {
    ...vaultHarvestAdapter.getInitialState(),
    vaultHarvestLoading: false,
  },
  reducers: {
    vaultHarvestSet: vaultHarvestAdapter.setOne,
    vaultHarvestUpdate: vaultHarvestAdapter.updateOne,
  },
  extraReducers(builder) {
    builder
      .addCase(fetchVaultHarvestData.pending, (state, action) => {
        state.vaultHarvestLoading = true;
      })
      .addCase(
        fetchVaultHarvestData.fulfilled,
        (state, action: PayloadAction<VaultHarvestModel[]>) => {
          state.vaultHarvestLoading = false;
          vaultHarvestAdapter.setAll(state, action.payload);
        }
      )
      .addCase(
        fetchVaultHarvestData.rejected,
        (state, action: PayloadAction<any>) => {
          state.vaultHarvestLoading = false;
        }
      );
  },
});

// Selectors
const { selectAll, selectById } = vaultHarvestAdapter.getSelectors();
export const selectAllVaultsHarvests = selectAll;
export const selectVaultHarvests = selectById;

export default vaultHarvestSlice;
