import { Loader } from "../../../../components/loader/loader";
import * as Styles from "../../../../components/transaction-status/components/style";

const Success = () => {
  return (
    <Styles.TransactionContainer>
      <Styles.TransactionHeader>
        Transaction Successful
      </Styles.TransactionHeader>
      {/*<Styles.TransactionDesc>*/}
      {/*  Quis autem vel eum iure reprehenderit qui in ea <br />*/}
      {/*  voluptate velit esse quam nihil*/}
      {/*</Styles.TransactionDesc>*/}
      <Styles.IconContainer>
        <Loader type="success" />
      </Styles.IconContainer>
    </Styles.TransactionContainer>
  );
};

export default Success;
