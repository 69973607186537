import { SVGProps } from "react";

const IconAltitude = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="256"
      height="256"
      viewBox="0 0 256 256"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_5673_1722)">
        <g clipPath="url(#clip1_5673_1722)" filter="url(#filter0_d_5673_1722)">
          <g filter="url(#filter1_dddddd_5673_1722)">
            <circle cx="128" cy="128" r="124" fill="white" />
          </g>
          <g clipPath="url(#clip2_5673_1722)">
            <path
              d="M105.792 178.384C98.0625 182.131 89.9563 184.004 81.0961 184.004C73.367 184.004 66.3919 182.505 60.1709 179.321C53.9498 176.324 49.0484 172.016 45.2781 166.396C41.6963 160.776 39.8112 154.407 39.8112 147.102C39.8112 135.488 44.3355 126.122 53.3843 118.817C62.433 111.511 76.0062 107.952 93.9152 107.952H122.193C121.815 101.021 119.93 95.589 116.349 91.6553C112.767 87.7215 107.865 85.661 101.833 85.661C96.1773 85.661 91.653 87.1596 88.0712 89.9694C84.4894 92.7792 82.0387 96.7129 80.9076 101.583H42.2619C44.147 92.4045 47.7288 84.3498 53.1958 77.0443C58.6627 69.9262 65.6378 64.1192 74.121 59.9982C82.6042 55.8772 92.2185 53.8167 102.775 53.8167C114.086 53.8167 124.266 56.0645 133.126 60.3728C141.987 64.6812 148.962 71.0501 153.863 79.1048C158.765 87.1596 161.404 96.7129 161.404 107.577V181.382H122.57V163.773C119.176 169.58 113.709 174.451 105.98 178.197L105.792 178.384ZM107.865 151.972C112.013 149.912 115.594 147.102 118.234 143.168C120.873 139.235 122.193 134.926 122.193 130.056V128.557H98.251C91.8415 128.557 87.1286 129.869 84.1123 132.304C81.0961 134.739 79.3995 138.111 79.3995 142.044C79.3995 145.978 80.7191 149.35 83.5468 151.598C86.3745 153.845 89.9563 155.157 94.8577 155.157C99.3821 155.157 103.718 154.22 108.054 152.16L107.865 151.972Z"
              fill="black" />
            <path d="M207.206 181.374H168.56C168.56 160.206 185.904 142.973 207.206 142.973V181.374Z"
                  fill="#4F8BFF" />
          </g>
        </g>
      </g>
      <defs>
        <filter id="filter0_d_5673_1722" x="-4" y="0" width="264" height="264" filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                         result="hardAlpha" />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5673_1722" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5673_1722" result="shape" />
        </filter>
        <filter id="filter1_dddddd_5673_1722" x="-11" y="-11.75" width="278" height="278" filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                         result="hardAlpha" />
          <feOffset dy="-0.0207544" />
          <feGaussianBlur stdDeviation="0.207544" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5673_1722" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                         result="hardAlpha" />
          <feOffset dy="-0.0498758" />
          <feGaussianBlur stdDeviation="0.498758" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0503198 0" />
          <feBlend mode="normal" in2="effect1_dropShadow_5673_1722" result="effect2_dropShadow_5673_1722" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                         result="hardAlpha" />
          <feOffset dy="-0.0939116" />
          <feGaussianBlur stdDeviation="0.939116" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0417275 0" />
          <feBlend mode="normal" in2="effect2_dropShadow_5673_1722" result="effect3_dropShadow_5673_1722" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                         result="hardAlpha" />
          <feOffset dy="-0.167522" />
          <feGaussianBlur stdDeviation="1.67522" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.035 0" />
          <feBlend mode="normal" in2="effect3_dropShadow_5673_1722" result="effect4_dropShadow_5673_1722" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                         result="hardAlpha" />
          <feOffset dy="-0.313332" />
          <feGaussianBlur stdDeviation="3.13332" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0282725 0" />
          <feBlend mode="normal" in2="effect4_dropShadow_5673_1722" result="effect5_dropShadow_5673_1722" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                         result="hardAlpha" />
          <feOffset dy="-0.75" />
          <feGaussianBlur stdDeviation="7.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0196802 0" />
          <feBlend mode="normal" in2="effect5_dropShadow_5673_1722" result="effect6_dropShadow_5673_1722" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect6_dropShadow_5673_1722" result="shape" />
        </filter>
        <clipPath id="clip0_5673_1722">
          <rect width="256" height="256" fill="white" />
        </clipPath>
        <clipPath id="clip1_5673_1722">
          <rect width="256" height="256" fill="white" />
        </clipPath>
        <clipPath id="clip2_5673_1722">
          <rect width="174" height="130" fill="white" transform="translate(40 54)" />
        </clipPath>
      </defs>
    </svg>

  );
};

export default IconAltitude;
