export const mainnetContracts = {
  multicallAddress: "0xeefba1e63905ef1d7acba5a8513c70307c1ce441",
  usdcToken: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
  ethToken: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
  daiToken: "0x6B175474E89094C44Da98b954EedeAC495271d0F",
  wbtcToken: "0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599",
  chainlinkAggregator: "0x986b5e1e1755e3c2440e960477f25201b0a8bbd4",
  chainlinkAggregatorUsdcUsd: "0x8fffffd4afb6115b954bd326cbe7b4ba576818f6",
  chainlinkAggregatorDaiUsd: "0xaed0c38402a5d19df6e4c03f4e2dced6e29c1ee9",
  chainlinkAggregatorUsdEur: "0xb49f677943bc038e9857d61e7d053caa2c1734c1",
};
