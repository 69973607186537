import { NumericFormat } from "react-number-format";
import styled, { css } from "styled-components";

import { GridVars } from "./grid.styles";
import { Text, TextLabel } from "./text";
import {
  ThemeError,
  ThemeGray400,
  ThemeGray600,
  ThemePrimary,
  ThemePrimaryText,
  ThemeSecondaryText, ThemeWarning,
} from "./theme.styles";

export const FormInputBox = styled.div`
  width: 100%;
  height: 50px;
  background: ${ThemeGray600};
  border: 1px solid ${ThemeGray600};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${GridVars.padding.p2};
`;

export const FormInputText = styled(Text)`
  font-size: 1rem;
`;

export const FormLabel = styled(TextLabel)`
  display: flex;
  justify-content: space-between;
`;
export const FormLabelLeft = styled.div``;

export const FormLabelRight = styled.div`
    display: flex;
    gap: ${GridVars.margin.m1};
`;

export const FormLabelBalance = styled.div`
  font-weight: 400;
  font-size: 12px;
`;

export const FormLabelMax = styled.div`
  color: ${ThemePrimary};
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;

type FormHelperProps = {
  $variant: 'error' | 'warning'
}
const helperTextColors = {
  error: ThemeError,
  warning: ThemeWarning
}
export const FormHelperText = styled.div<FormHelperProps>`
  color: ${props => helperTextColors[props.$variant]};
  margin-top: 4px;
  font-size: 12px;
  line-height: 24px;
  font-weight: 400;
`;

interface FormInputContainerProps {
  error: string | undefined;
  warning: string | undefined;
}

export const FormInputContainer = styled.div<FormInputContainerProps>`
  margin: ${GridVars.margin.m1} 0 0 0;
  background: ${ThemeGray400};
  border-radius: 8px;
  padding: ${GridVars.padding.p1} ${GridVars.padding.p2};
  display: flex;
  align-items: center;
  border-width: 2px;
  border-style: solid;
  border-color: transparent;
  transition: 300ms all;
  ${(props) => {
    const color = (props.error && ThemeError) || (props.warning && ThemeWarning);
    return color && css`border-color: ${color};`
  }}

  :focus-within {
    border-color: ${ThemePrimary};
    ${(props) => {
      const color = (props.error && ThemeError) || (props.warning && ThemeWarning);
      return color && css`border-color: ${color};`
    }}
  }
`;

export const InputTokenLogo = styled.div`
  display: flex;
  align-items: center;
  font-size: 2rem;

  svg {
    height: 22px;
    width: 22px;
  }

  span {
    font-size: 1rem;
    margin-left: ${GridVars.margin.m1};
    color: ${ThemePrimaryText};
    position: relative;
  }
`;

export const FormInputWrapper = styled.div`
  margin-left: ${GridVars.margin.m1};
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const FormInputField = styled(NumericFormat)`
  width: 100%;
  border: 0;
  background-color: transparent;
  font-weight: 600;
  font-size: 1rem;
  line-height: 23px;
  color: ${ThemePrimaryText};
  text-align: right;

  ::placeholder {
    color: ${ThemePrimaryText};
  }
`;

export const FormFieldText = styled(NumericFormat)`
  width: 210px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
  margin-top: 5px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  color: ${ThemeSecondaryText};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  word-break: break-all;

  @media (max-width: 600px) {
    width: 180px;
  }
`;
