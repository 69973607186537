import styled from "styled-components";

interface RepayingButtonProps {
  $maxWidth: number;
  $background: string;
}

interface RepayTextProps {
  $color: string;
}

export const RepayingButton = styled.div<RepayingButtonProps>`
  margin-left: 0px;
  padding: 4px 6px;

  background: ${(props) => props.$background};
  border-radius: 4px;

  justify-content: center;
  align-content: center;
  display: flex;

  gap: 5px;

  max-width: ${(props) => `${props.$maxWidth}px`};

  cursor: pointer;
`;

export const RepayText = styled.div<RepayTextProps>`
  font-size: 11px;
  line-height: 14px;
  color: ${(props) => props.$color};
  font-weight: 600;
`;
