import { VaultName } from "@altitude-fi/altitude-v1-sdk/dist/types";
import moneyFormatterLogic from "../../../components/money-formatter/money-formatter.logic";
import { tokenBalanceFormatter } from "../../../components/token-balance/token-balance";
import { TokenDisplayType } from "../../../models/vault";
import { Messages } from "../../../utils";
import {BigNumber} from "ethers";
import {formatUnits} from "ethers/lib/utils";

export const depositInputValidator = (
  supplyToken: string,
  balance: number,
  value: number | null,
  max: number,
  maxDeposit: number,
  minDeposit: number,
  vaultSupplyBalance: number,
  vaultDepositLimit: number,
  vaultType: VaultName
) => {
  if (value && (balance + value) < minDeposit) {
    return `To protect users and the protocol Altitude requires your collateral balance to be ${minDeposit} ${supplyToken} or more`;
  }

  if (value && value > max) {
    return Messages.greaterThan("deposit", "you have on the account");
  }

  if (value && value > maxDeposit) {
    const formattedValue = tokenBalanceFormatter({
      displayType: TokenDisplayType.supply,
      vaultType,
      amount: maxDeposit,
    });
    return `You have reached your deposit limit and cannot deposit more than ${formattedValue.label}`;
  }

  if (value && (value + vaultSupplyBalance) > vaultDepositLimit) {
    const formattedValue = tokenBalanceFormatter({
      displayType: TokenDisplayType.supply,
      vaultType,
      amount: vaultDepositLimit - vaultSupplyBalance,
    });
    return `The vault has reached its deposit limit, you cannot deposit more than ${formattedValue.label}`;
  }

  return "";
};
export const borrowInputValidator = (value: BigNumber, max: BigNumber) => {
  if (value.gt(max)) {
    return Messages.greaterThan("borrow", "Borrowing limit");
  }

  return "";
};
export const withdrawInputValidator = (value: BigNumber, max: BigNumber, vaultType: VaultName, tokenDecimals: number) => {
  if (value.gt(max)) {
    const formattedValue = tokenBalanceFormatter({
      displayType: TokenDisplayType.supply,
      vaultType,
      amount: +formatUnits(max, tokenDecimals),
    });
    return `You need collateral to cover your loan. ${Messages.greaterThan("withdraw", formattedValue.label)}`;
  }

  return "";
};
export const repayInputValidator = (
  value: BigNumber,
  max: BigNumber,
  balance: BigNumber,
  tokenLabel: string,
  tokenDecimals: number
) => {
  if (value.gt(max)) {
    return Messages.greaterThan("repay", "you borrowed");
  }

  if (value.gt(balance)) {
    const formattedBalance = moneyFormatterLogic({
      value: formatUnits(balance, tokenDecimals),
      postfix: ` ${tokenLabel}`,
    });
    return Messages.greaterThan("repay", `your balance (${formattedBalance})`);
  }

  return "";
};
export const claimInputValidator = (
  value: BigNumber,
  max: BigNumber
) => {
  if (value && value.gt(max)) {
    return Messages.greaterThan("claim", "your balance");
  }
  return "";
}
