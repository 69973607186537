import styled from "styled-components";
import { Info } from "../../assets/images/iconComponents";

const Button = styled.button`
  all: unset;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
`

const InfoButton = () => (
  <Button type="button">
    <Info />
  </Button>
)

export default InfoButton
