import styled from "styled-components";
import {
  Box,
  GridVars,
  ThemePrimaryText, ThemeSecondaryText,
} from "../../../../utils";
import { Text } from '../../../../utils/styles/text'
import { Button } from "../../../../utils/styles/button";
import {Paragraph} from "../../../../components/custom-popover/styles";

export const VaultCompositionContainer = styled(Box)`
  grid-area: vaultComposition;

  display: flex;
  justify-content: space-between;
  gap: ${GridVars.gap};

  @media (max-width: ${GridVars.breakpoint.xl}) {
    flex-direction: column;
    justify-content: flex-start;
    gap: calc(${GridVars.gap} * 2);
  }

  @media (max-width: ${GridVars.breakpoint.lg}) {
    flex-direction: row;
    justify-content: space-between;
  }

  @media (max-width: ${GridVars.breakpoint.md}) {
    flex-direction: column;
    justify-content: flex-start;
  }
`;

export const VaultCompositionItemCol = styled(Text)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${GridVars.padding.p2};

  img.vaultLogo {
    width: 40px;
    height: 40px;
  }
`;
export const VaultCompositionItemRow = styled(Text)`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const VaultCompositionItemRowStrategies = styled(VaultCompositionItemRow)`
  gap: calc(${GridVars.gap} * 4);
  @media (max-width: ${GridVars.breakpoint.lg}) {
    gap: ${GridVars.gap};
  }
  @media (max-width: ${GridVars.breakpoint.md}) {
    justify-content: space-between;
  }
  @media (max-width: ${GridVars.breakpoint.sm}) {
    flex-direction: column;
    align-items: start;
  }
`;

export const VaultCompositionText = styled(Text).attrs({ $weight: 'strong', $size: 'large' })`
  color: ${ThemePrimaryText};
  padding: ${GridVars.padding.p1} 0 ${GridVars.padding.p1}
    ${GridVars.padding.p1};
  margin-bottom: ${GridVars.margin.m0};
  display: flex;
`;

export const VaultCompositionHeader = styled(Text).attrs({ $weight: 'normal' })`
  display: flex;
  padding: 0 ${GridVars.padding.p1};
`;

export const VaultCompositionSubHeader = styled(Text).attrs({ $weight: 'strong' })`
  padding: 0 ${GridVars.padding.p1};
  display: flex;
  color: ${ThemePrimaryText};
`;

export const VaultCompositionRisks = styled(Button).attrs({
  $variant: 'tertiary'
})`
  margin-top: ${GridVars.margin.m1};
`

export const PopoverContent = styled(Paragraph)`
  a {
    color: ${ThemeSecondaryText};
  }
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    li a {
      font-weight: bold;
    }
  }
  p {
    margin: ${GridVars.margin.m2} 0;
  }
`
