import { configureStore } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";

import globalSlice from "./global/global.slice";
import toastSlice from "./toast/toast.slice";
import userSlice from "./user/user.slice";
import userVaultSlice from "./userVault/userVault.slice";
import vaultSlice from "./vault/vault.slice";
import vaultHarvestSlice from "./vaultHarvest/vaultHarvest.slice";
import vaultStatsSlice from "./vaultStats/vaultStats.slice";

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

const store = configureStore({
  reducer: {
    global: globalSlice.reducer,
    vault: vaultSlice.reducer,
    user: userSlice.reducer,
    userVault: userVaultSlice.reducer,
    vaultHarvest: vaultHarvestSlice.reducer,
    vaultStats: vaultStatsSlice.reducer,
    toast: toastSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
  enhancers: (defaultEnhancers) => defaultEnhancers.concat(sentryReduxEnhancer),
});

export const { userVaultSet, userVaultUpdate, userVaultSetTransactionMode, userVaultClear } =
  userVaultSlice.actions;
export const { globalSetHeaderModal } = globalSlice.actions;
export const { vaultSet, vaultUpdate } = vaultSlice.actions;
export const { userSet, userUpdate, userDelete, optInUpdate } = userSlice.actions;
export const { vaultHarvestSet, vaultHarvestUpdate } =
  vaultHarvestSlice.actions;
export const { toastAdd, toastRemove } = toastSlice.actions;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
