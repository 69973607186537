import styled from "styled-components";
import { ThemePrimaryText, ThemeSecondaryText } from "../../utils";

interface TypeProps {
  type: string;
  value: string;
}

export const TokenBalance = styled.span<TypeProps>`
  display: inline;
  align-items: center;
`;
export const TokenInteger = styled.span`
  color: ${ThemePrimaryText};
`;
export const TokenDecimal = styled.span`
  color: ${ThemeSecondaryText};
`;

export const TokenPrefix = styled.span`
  color: ${ThemeSecondaryText};
  margin-right: 0px !important;
`;
