import React from "react";
import { useCallback, useMemo, useState } from "react";

import { TokenInput } from "../../../../../components";
import { useAppDispatch } from "../../../../../hooks";
import { useAppSelector } from "../../../../../hooks";
import {
  UserVaultModel,
  VaultModel,
} from "../../../../../models";
import {
  BarChart,
  repayInputValidator,
  StatisticsRow,
  ModalStyles,
} from "../../../../../modules/dashboard/modals";
import { repay } from "../../../../../store/userVault/actions";
import { selectUserVault } from "../../../../../store/userVault/userVault.slice";
import { selectVault } from "../../../../../store/vault/vault.slice";
import { useAnalytics } from "../../../../../hooks/useAnalytics";
import {UserPosition, useUserPosition} from "../../../../../hooks/useUserPosition";
import { useUser } from "../../../../../hooks/useAppSelector";
import ReadOnlyWarning from "../../components/read-only-warning";
import { formatUnits, parseEther } from "ethers/lib/utils";
import { ValidatorFunction } from "../../../../../components/token-input/token-input";
import { parseValue } from "../../../../../utils";
import {BigNumber, constants} from "ethers";

// To ensure the loan is fully paid back we sligltly overpay the debt.
// This margin is used to calculate additional repayment in %.
const MAX_MARGIN = parseEther('0.00004')

export const RepayTab = () => {
  const dispatch = useAppDispatch();
  const { track } = useAnalytics();
  const { data: { isReadOnly } = {} } = useUser();

  const vaultStore = useAppSelector((state) => state.vault);
  const userVaultStore = useAppSelector((state) => state.userVault);
  const [isValid, setIsValid] = useState(false);
  const { selectedVault } = useAppSelector((state) => state.global);

  const {
    borrowToUsdRatio,
    supplyThreshold,
    liquidationThreshold,
    borrowSymbol,
    borrowDecimals,
    supplySymbol,
    borrowPrecision
  }: VaultModel = selectVault(vaultStore, selectedVault) || ({} as VaultModel);
  const {
    collateralTokenAmount: collateralTokenAmountNumber,
    debtTokenAmount: debtTokenAmountNumber,
    borrowTokenAmountBn: borrowTokenAmount,
    collateralTokenAmountBn: collateralTokenAmount,
    debtTokenAmountBn: debtTokenAmount,
  }: UserVaultModel =
    selectUserVault(userVaultStore, selectedVault) || ({} as UserVaultModel);


  const [isTouched, setIsTouched] = useState(false);
  const [repayAmount, setRepayAmount] = useState(constants.Zero);
  const newLoanAmount = debtTokenAmount.sub(repayAmount);

  const { data: currentPosition } = useUserPosition();
  const {
    net: { netApy },
    current: { borrowLimit, liquidationLimit, borrowingCapacity },
    projected: { monthlyRepayments }
  } = currentPosition as UserPosition;
  const { data: futurePosition } = useUserPosition(newLoanAmount, collateralTokenAmount);
  const {
    net: { netApy: futureNetApy },
    current: {
      borrowingCapacity: futureBorrowingCapacityPercentage,
    },
    projected: { monthlyRepayments: futureMonthlyRepayments }
  } = futurePosition as UserPosition;

  const repayHandler = useCallback(() => {
    track('click-repay', { repayAmount: repayAmount.toString(), selectedVault });
    dispatch(repay({ repay: repayAmount, selectedVault }));
  }, [track, repayAmount, selectedVault, dispatch])

  const inputChangeHandler = useCallback((value?: BigNumber) => {
    setIsTouched(!!value);
    setRepayAmount(value || constants.Zero);
  }, []);

  // slightly overpay the debt when "max" is clicked to ensure the borrow position is fully closed
  const maxRepayValue = useMemo(() => {
    const overPayValue = debtTokenAmount.mul(MAX_MARGIN).div(parseEther('1'));
    return debtTokenAmount.add(overPayValue)
  }, [debtTokenAmount])

  const repayValidator = useCallback<ValidatorFunction>(
    (value) => {
      const valueBn = parseValue(value, borrowDecimals);
      const error = repayInputValidator(
        valueBn,
        maxRepayValue,
        borrowTokenAmount,
        borrowSymbol,
        borrowDecimals
      );
      setIsValid(!error);
      return error;
    },
    [maxRepayValue, borrowTokenAmount, borrowSymbol, borrowDecimals]
  );

  const maxValue = useMemo(() =>
    borrowTokenAmount.gt(maxRepayValue) ? maxRepayValue : borrowTokenAmount,
    [maxRepayValue, borrowTokenAmount]
  );

  const isRepayButtonDisabled = useMemo(() => isReadOnly || !isValid || repayAmount.lte(0), [isReadOnly, isValid, repayAmount])

  return (
    <ModalStyles.TabWrapper data-testid="repay-tab">
      <ModalStyles.AmountContainer data-testid="repay-amount-input">
        <TokenInput
          decimals={borrowDecimals}
          precision={borrowPrecision}
          label={"Repay Amount"}
          tokenName={borrowSymbol}
          name={borrowSymbol + "Amount"}
          validator={repayValidator}
          onValueChange={inputChangeHandler}
          currencyExchangeRate={borrowToUsdRatio}
          balanceValue={borrowTokenAmount}
          maxValue={maxValue}
        />
      </ModalStyles.AmountContainer>
      <ModalStyles.StatisticsContainer>
        <BarChart
          to={futureBorrowingCapacityPercentage }
          from={borrowingCapacity}
          balance={+formatUnits(newLoanAmount, borrowDecimals)}
          collateral={collateralTokenAmountNumber}
          borrowLimit={borrowLimit}
          liquidation={liquidationLimit}
          liquidationThreshold={liquidationThreshold}
          supplyThreshold={supplyThreshold}
          borrowToUsdRatio={borrowToUsdRatio}
          borrowName={borrowSymbol + " "}
          supplySymbol={supplySymbol}
        />
        <StatisticsRow
          touched={isTouched}
          from={debtTokenAmountNumber}
          to={+formatUnits(newLoanAmount, borrowDecimals)}
          label={"Borrowed Amount"}
          prefix={borrowSymbol + " "}
        />
        <ModalStyles.StatisticsRowDivider />
        <StatisticsRow
          touched={isTouched}
          label={"Net APY"}
          from={netApy || 0}
          to={futureNetApy || 0}
          postfix={"%"}
          prefix={""}
        />
        <StatisticsRow
          touched={isTouched}
          label={"Estimated Monthly Repayments"}
          from={monthlyRepayments || 0}
          to={futureMonthlyRepayments}
          prefix={borrowSymbol + " "}
        />
        <ModalStyles.TakeLoanBtn
          onClick={() => repayHandler()}
          disabled={isRepayButtonDisabled}
          data-testid="repay-action-btn"
        >
          Repay
        </ModalStyles.TakeLoanBtn>
        <ReadOnlyWarning />
      </ModalStyles.StatisticsContainer>
    </ModalStyles.TabWrapper>
  );
};
