import AnalyticsContext from "./analytics.context"
import { useEffect, ReactNode } from "react";
import { AnalyticsInterface } from "./analytics";
import { useLocation } from "react-router-dom";

type ProviderProps = {
  instance: AnalyticsInterface,
  children: ReactNode
}

const AnalyticsProvider = ({ children, instance }: ProviderProps) => {
  const location = useLocation();
  useEffect(() => {
    instance.page();
  }, [location, instance]);
  return (
    <AnalyticsContext.Provider
      value={instance}
      children={children || null}
    />
  )
}

export default AnalyticsProvider;
