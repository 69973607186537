import React from "react";
import { useMemo, useState } from "react";

import { ArrowRight } from "../../../../../assets/images/iconComponents";
import { MoneyFormatter, PercentageFormatter } from "../../../../../components";
import { useMediaQuery } from "../../../../../hooks";
import { ElementHR, GridVars } from "../../../../../utils";

import {getChartColor, getChartValues, getLiquidationAmount} from "./bar-chart.logic";
import * as Styles from "./styles";

interface BarChartProps {
  to: number;
  from: number;
  balance: number;
  borrowLimit: number;
  liquidation: number;
  collateral: number;
  liquidationThreshold: number;
  supplyThreshold: number;
  borrowToUsdRatio: number;
  borrowName: string;
  supplySymbol: string;
}

export const BarChart = ({
  to,
  from,
  balance,
  borrowLimit,
  collateral,
  liquidation,
  liquidationThreshold,
  supplyThreshold,
  borrowToUsdRatio,
  borrowName,
  supplySymbol,
  ...rest
}: BarChartProps) => {
  const [borrowLimitPercentage, liquidationPercentage, overallPercentage] =
    useMemo(
      () => getChartValues(borrowLimit, liquidation, balance),
      [borrowLimit, liquidation, balance]
    );
  const [borrowColor, liquidationColor] = useMemo(
    () => getChartColor(overallPercentage),
    [overallPercentage]
  );
  const isToEqualFrom = from === to;

  const [borrowingLimit] = useState<number>(supplyThreshold * 100);
  const [liquidationLimit] = useState<number>(100 - borrowingLimit);

  const isPercentageContainerToRendered = !isNaN(to);
  const isPercentageContainerFromRendered = !isToEqualFrom && !isNaN(from);

  const isSM = useMediaQuery(`(max-width: ${GridVars.breakpoint.sm})`);

  const liquidationAmount =
    getLiquidationAmount(balance, liquidationThreshold, collateral) *
    borrowToUsdRatio;

  // DEV: If supplyThreshold is above 75% - 80% this part of the chart will need redesign
  const supplyThresholdBreakpoint = 0.7;
  const isUnderChart = isSM || supplyThreshold < supplyThresholdBreakpoint;

  return (
    <>
      <Styles.PercentageContainer
        $isUnderChart={isUnderChart}
        data-testid="bar-chart-container"
      >
        {isPercentageContainerFromRendered && (
          <>
            <Styles.PercentageContainerFrom data-testid="bar-chart-percentage-from">
              <PercentageFormatter percentage={from} /> <span>%</span>
            </Styles.PercentageContainerFrom>
            <ArrowRight />
          </>
        )}
        {isPercentageContainerToRendered && (
          <Styles.PercentageContainerTo
            data-testid="bar-chart-percentage-to"
            $color={borrowColor}
          >
            <PercentageFormatter percentage={to} /> <span>%</span>
          </Styles.PercentageContainerTo>
        )}
      </Styles.PercentageContainer>
      <Styles.ChartWrapper>
        {!isUnderChart && (
          <Styles.ChartAbove>
            <Styles.ChartLabelItem $supplyThreshold={supplyThreshold * 100}>
              <Styles.ChartLabelItemName>
                Borrow Limit
              </Styles.ChartLabelItemName>
              <Styles.ChartLabelItemValue>
                <MoneyFormatter value={borrowLimit} prefix={borrowName} />
              </Styles.ChartLabelItemValue>
            </Styles.ChartLabelItem>
            <Styles.ChartLabelItem>
              <Styles.ChartLabelItemName>Liquidation Price</Styles.ChartLabelItemName>
              <Styles.ChartLabelItemValue>
                <MoneyFormatter value={liquidationAmount} prefix={"$"} />
              </Styles.ChartLabelItemValue>
            </Styles.ChartLabelItem>
          </Styles.ChartAbove>
        )}
        <Styles.Chart>
          <Styles.ChartBorrowLimit
            $color={borrowColor}
            $proportion={borrowingLimit}
            $completeness={borrowLimitPercentage}
          >
            <Styles.StartStick $isUnderChart={isUnderChart} />
          </Styles.ChartBorrowLimit>
          <Styles.ChartLiquidation
            $color={liquidationColor}
            $proportion={liquidationLimit}
            $completeness={Math.min(Math.max(liquidationPercentage, 0), 100)}
            $isUnderChart={isUnderChart}
          >
            <Styles.StartStick $isUnderChart={isUnderChart} />
          </Styles.ChartLiquidation>
        </Styles.Chart>
        {isUnderChart && (
          <Styles.ChartBelow>
            <Styles.ChartLabelItem $supplyThreshold={supplyThreshold * 100}>
              <Styles.ChartLabelItemName>
                Borrow Limit
              </Styles.ChartLabelItemName>
              <Styles.ChartLabelItemValue>
                <MoneyFormatter value={borrowLimit} prefix={borrowName} />
              </Styles.ChartLabelItemValue>
            </Styles.ChartLabelItem>
            <Styles.ChartLabelItem>
              <Styles.ChartLabelItemName>Liquidation Price</Styles.ChartLabelItemName>
              <Styles.ChartLabelItemValue>
                <Styles.WrapMoneyFormatter
                  value={liquidationAmount}
                  prefix={`${supplySymbol}: $`}
                />
              </Styles.ChartLabelItemValue>
            </Styles.ChartLabelItem>
          </Styles.ChartBelow>
        )}
      </Styles.ChartWrapper>
      {isUnderChart && <ElementHR />}
    </>
  );
};
