import {
  VaultContainer,
  VaultDetails,
  VaultSummary,
  VaultTitle,
  VaultTitleStats,
  TokenIcons,
} from "./styles";
import React, { useState, useRef } from "react";
import VaultStats from "./stats";
import { VaultModel } from "../../../../models";
import { ReactComponent as AccordionArrow } from "../../../../assets/images/icons/accordionArrow.svg";
import { TokenLogoMapping } from "../../../../utils/icons.mapping";

type VaultProps = {
  model: VaultModel
  canToggle?: boolean
  startExpanded?: boolean
}

const Vault = ({ model, canToggle = true, startExpanded = false }: VaultProps) => {
  const detailsRef = useRef<HTMLDivElement>(null);
  const [isExpanded, setIsExpanded] = useState(startExpanded);

  return (
    <VaultContainer>
      <VaultSummary>
        <VaultTitle>{model.supplySymbol}-{model.borrowSymbol} Vault <TokenIcons>{TokenLogoMapping[model.supplyName.toUpperCase()]} {TokenLogoMapping[model.borrowName.toUpperCase()]}</TokenIcons></VaultTitle>
        {canToggle && (
          <VaultTitleStats $open={isExpanded} onClick={() => setIsExpanded(prev => !prev)}>
            <AccordionArrow />
          </VaultTitleStats>
        )}
      </VaultSummary>
      <VaultDetails $expanded={isExpanded} $height={detailsRef.current?.scrollHeight || 1200} ref={detailsRef}>
        <VaultStats vault={model} />
        {/*<VaultAPYChart />*/}
      </VaultDetails>
    </VaultContainer>
  );
}

export default Vault;
