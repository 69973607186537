import * as React from "react";
import { SVGProps } from "react";
const SvgMenuBurger = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>Menu</title>
    <path
      fill="currentColor"
      d="M11 14h18v2H11zM11 19h18v2H11zM11 24h18v2H11z"
    />
  </svg>
);
export default SvgMenuBurger;
