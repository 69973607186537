import { Toast, ToastTypes } from "../../models";

import * as Styles from "./style";

interface TopToastBarItemProps {
  toasts: Toast[];
  type: ToastTypes;
}

const TopToastBarItem = ({ type, toasts }: TopToastBarItemProps) => {
  if (!toasts.length) {
    return <></>;
  }

  return (
    <Styles.TopToastBarItemContainer type={type}>
      {toasts.map((toast: Toast) => (
        <Styles.TopToastBarItemText key={toast.id}>
          {toast.message}
        </Styles.TopToastBarItemText>
      ))}
    </Styles.TopToastBarItemContainer>
  );
};

export default TopToastBarItem;
