import {
  createSlice,
  PayloadAction,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import { UserVaultModel } from "../../models";
import { TRANSACTION_STEPS } from "../../utils";

import {
  depositAndBorrow,
  fetchUserVaultData,
  repay,
  withdraw,
  claimRewards,
} from "./actions";

const userVaultAdapter = createEntityAdapter<UserVaultModel>({
  selectId: (userVault) => userVault.id,
});

const userVaultSlice = createSlice({
  name: "userVault",
  initialState: {
    ...userVaultAdapter.getInitialState(),
    userVaultLoading: false,
    transactionMode: 0,
    transactionError: undefined,
  },
  reducers: {
    userVaultSet: userVaultAdapter.setOne,
    userVaultUpdate: userVaultAdapter.updateOne,
    userVaultClear: userVaultAdapter.removeAll,
    userVaultSetTransactionMode: (state, action) => {
      state.transactionMode = action.payload;
      state.transactionError = undefined;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchUserVaultData.pending, (state) => {
        state.userVaultLoading = true;
      })
      .addCase(
        fetchUserVaultData.fulfilled,
        (state, action: PayloadAction<UserVaultModel[]>) => {
          state.userVaultLoading = false;
          userVaultAdapter.setAll(state, action.payload);
        }
      )
      .addCase(
        fetchUserVaultData.rejected,
        (state, action: PayloadAction<any>) => {
          state.userVaultLoading = false;
        }
      )
      .addCase(depositAndBorrow.pending, confirmReducer)
      .addCase(depositAndBorrow.fulfilled, successReducer)
      .addCase(depositAndBorrow.rejected, rejectedReducer)
      .addCase(withdraw.pending, confirmReducer)
      .addCase(withdraw.fulfilled, successReducer)
      .addCase(withdraw.rejected, rejectedReducer)
      .addCase(repay.pending, confirmReducer)
      .addCase(repay.fulfilled, successReducer)
      .addCase(repay.rejected, rejectedReducer)
      .addCase(claimRewards.pending, confirmReducer)
      .addCase(claimRewards.fulfilled, successReducer)
      .addCase(claimRewards.rejected, rejectedReducer);
  },
});

const confirmReducer = (state: any, action: any) => {
  state.transactionMode = TRANSACTION_STEPS.confirm;
};
const successReducer = (state: any, action: any) => {
  state.transactionMode = TRANSACTION_STEPS.success;
};
const rejectedReducer = (state: any, action: any) => {
  state.transactionMode = TRANSACTION_STEPS.failure;
  state.transactionError = action.payload;
};

// Selectors
const { selectAll, selectById } = userVaultAdapter.getSelectors();
export const selectAllUserVaults = selectAll;
export const selectUserVault = selectById;

export default userVaultSlice;
