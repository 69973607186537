import { keyframes, css } from "styled-components";

// Animation durations in milliseconds
export const AnimationDurationMs = {
  extrashort: 100,
  short: 300,
  medium: 500,
  long: 700,
  extraLong: 6000
}

type AnimationDurationKey = keyof typeof AnimationDurationMs
type AnimationDurationType = { [key in AnimationDurationKey]: string }

// Animation durations with "ms" suffix
export const AnimationDuration = Object.keys(AnimationDurationMs)
  .reduce<AnimationDurationType>((acc, key) => ({
    ...acc,
    [key]: `${AnimationDurationMs[key as AnimationDurationKey]}ms`
  }), {} as AnimationDurationType)

export const AnimationFadeInKeyframes = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

export const AnimationKeyFramesBottomSlideIn = keyframes`
  0% {
    transform: translateY(1%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
`

export const AnimationFadeIn = css`
  animation: ${AnimationFadeInKeyframes} ${AnimationDuration.short} ease-in-out forwards;
`;

export const AnimationFadeOutKeyframes = keyframes`
  0% { opacity: 1; }
  100% { opacity: 0; }
`;

export const AnimationFadeOut = css`
  animation: ${AnimationFadeOutKeyframes} ${AnimationDuration.short} ease-in-out forwards;
`;
