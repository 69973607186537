import {createContext, useEffect, PropsWithChildren, useContext, useState, useMemo} from 'react';
import { AltitudeSDK } from "@altitude-fi/altitude-v1-sdk";
import {useAccount, useClient, useConnectorClient} from "wagmi";
import { mainnetContracts, publicClientToProvider, walletClientToSigner } from "../utils";
import chainlinkAggregator from "../utils/abis/chainlinkAggregator.json";

interface SdkInterface {
  sdk: AltitudeSDK;
  isInitialised: boolean;
}
export const sdk = new AltitudeSDK();
const setChainlinkAggregator = () => {
  // set chainlink aggregator contract. needs to be called after setting provider/signer
  try {
    sdk.setCustomContract(
      "chainlinkAggregator",
      mainnetContracts.chainlinkAggregatorUsdcUsd,
      chainlinkAggregator
    );
  } catch (error) {
    console.log("error", error);
  }
}
export const SdkContext = createContext<SdkInterface>({ sdk, isInitialised: false });

export const SdkProvider = (props: PropsWithChildren) => {
  const publicClient = useClient();
  const { data: walletClient } = useConnectorClient();
  const { chain } = useAccount();
  const [isInitialised, setIsInitialised] = useState(false);

  useEffect(() => {
    if (publicClient) {
      sdk.setProvider(publicClientToProvider(publicClient));
      setChainlinkAggregator();
      setIsInitialised(true);
    }
  }, [publicClient, setIsInitialised]);

  useEffect(() => {
    if (walletClient) {
      const ethersSigner = walletClientToSigner(walletClient, chain);
      // set sdk provider
      sdk.setSigner(ethersSigner);
      sdk.setProvider(ethersSigner.provider);
      setChainlinkAggregator();
      setIsInitialised(true);
    }
  }, [walletClient, chain]);

  const sdkValue = useMemo(() => ({ sdk, isInitialised }), [isInitialised])
  return (
    <SdkContext.Provider value={sdkValue}>
      {props.children}
    </SdkContext.Provider>
  )
}

export const useSdk = () => {
  return useContext(SdkContext);
}
