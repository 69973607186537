import { useEffect, useState } from "react";

import { useAppSelector } from "../../hooks";
import { Toast, ToastTypes } from "../../models";
import { selectAllToasts } from "../../store/toast/toast.slice";

import * as Styles from "./style";
import TopToastBarItem from "./top-toast-bar-item";

const TopToastBar = () => {
  const [types] = useState<ToastTypes[]>(["error", "warn", "info"]);
  const toastStore = useAppSelector((state) => state.toast);
  const toasts: Array<Toast> = selectAllToasts(toastStore);
  const [groupedToasts, setGroupedToasts] = useState<any>({});

  useEffect(() => {
    types.forEach((type) => {
      const items: Toast[] = toasts.filter((t: any) => t.type === type);
      setGroupedToasts((prevState: any) => {
        return {
          ...prevState,
          [type]: items,
        };
      });
    });
  }, [toasts, types]);
  return (
    <Styles.TopToastBarContainer>
      {groupedToasts["error"] &&
        types.map((type) => (
          <TopToastBarItem
            key={`${type}_bar`}
            toasts={groupedToasts[type]}
            type={type}
          />
        ))}
    </Styles.TopToastBarContainer>
  );
};

export default TopToastBar;
