import { createConfig, fallback, http, unstable_connector } from "wagmi";
import { localhost, mainnet } from "wagmi/chains";
import { ApolloClient, InMemoryCache } from "@apollo/client";
import { mainnetFork, NETWORKS } from "./utils";
import { coinbaseWallet, injected, walletConnect, metaMask } from "wagmi/connectors";
import { impersonator } from "./utils/impersonator";

export const CONFIG_NETWORK: string = "testnet";

export const PROJECT_NAME = "Altitude";

interface ConfigValues {
  infuraId: string;
  harvestApi: string;
}

interface Config {
  [config: string]: ConfigValues;
}

export const CONFIG: Config = {
  testnet: {
    infuraId: "0309f32e701f477da59c900a00286fe2",
    harvestApi: "https://wissg5tmbg.eu-west-1.awsapprunner.com/api/info/getYeildApy",
  },
  mainnet: {
    infuraId: "0309f32e701f477da59c900a00286fe2",
    harvestApi: "https://wissg5tmbg.eu-west-1.awsapprunner.com/api/info/getYeildApy",
  },
};

const MAINNET_RPC_URLS = [
  'https://eth.llamarpc.com',
  'https://rpc.ankr.com/eth',
  'https://cloudflare-eth.com',
  'https://ethereum-rpc.publicnode.com',
  'https://eth-pokt.nodies.app'
]

export const getConfig = (): ConfigValues => {
  return CONFIG[CONFIG_NETWORK];
};

// localStorage keys
export const riskWarningToken = "RiskWarningToken";
export const userOptInTimestamp = "UserOptInTimestamp";

export const wagmiConfig = createConfig({
  chains: [mainnet, mainnetFork, localhost],
  connectors: [
    injected(),
    impersonator(),
    metaMask({
      infuraAPIKey: getConfig().infuraId,
      dappMetadata: {
        name: PROJECT_NAME,
        url: window.location.origin
      },
    }),
    coinbaseWallet({
      appName: PROJECT_NAME,
      jsonRpcUrl: `https://mainnet.infura.io/v3/${getConfig().infuraId}`
    }),
    walletConnect({
      projectId: 'ea60ea61c03da9b79f85d670a694ba13',
      qrModalOptions: {
        themeMode: 'dark', // TODO update this to support theme switching
        themeVariables: {
          // @ts-ignore TS types are incorrect in the connector. --wcm-z-index is the correct variable but according to TS types it should be --w3m-z-index
          "--wcm-z-index": "1500" // show wallet connect modal on top of our modals
        }
      }
    })
  ],
  transports: {
    [mainnet.id]: fallback([
      unstable_connector(metaMask),
      unstable_connector(injected),
      ...MAINNET_RPC_URLS.map(url => http(url))
    ]),
    [mainnetFork.id]: fallback([
      unstable_connector(metaMask),
      unstable_connector(injected),
      http(process.env.REACT_APP_MAINNET_FORK_RPC_URL)
    ]),
    [localhost.id]: fallback([
      unstable_connector(injected),
      http('http://localhost:8545')
    ]),
  },
});

const SUBGRAPH_URLS = {
  [NETWORKS.mainnet]: process.env.REACT_APP_GRAPHQL_ENDPOINT,
  [NETWORKS.mainnetfork]: process.env.REACT_APP_GRAPHQL_ENDPOINT,
  [NETWORKS.localhost]: process.env.REACT_APP_GRAPHQL_ENDPOINT,
}

export function createSubgraph(chainId: keyof typeof SUBGRAPH_URLS = NETWORKS.mainnet) {
  const uri = SUBGRAPH_URLS[chainId];

  return new ApolloClient({
    uri,
    cache: new InMemoryCache(),
    defaultOptions: {
      query: {
        fetchPolicy: "network-only",
      },
    },
  });
}

