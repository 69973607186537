import styled from "styled-components";
import {
  ThemeGray100,
  ThemeGray600,
  GridVars,
  ThemePrimary,
  ThemePrimaryText,
  ThemeGray800, ThemeSecondaryText,
} from "../../utils";
import { Button } from "../../utils/styles/button";
import {
  Text,
  TextValue,
} from "../../utils/styles/text";
import { AnimationDuration } from "../../utils/styles/animation.styles";

export const Nav = styled.div`
  flex-direction: column;
  align-items: center;
  display: flex;
  background: ${ThemeGray600};

  @media (max-width: ${GridVars.breakpoint.md}) {
    border-radius: 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 50;
  }
`;

export const NavWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: calc(${GridVars.breakpoint.xxl} + ${GridVars.margin.m3} * 2);
  @media (max-width: ${GridVars.breakpoint.md}) {
    border-radius: 0;
    top: 0;
    left: 0;
    right: 0;
    z-index: 50;
  }
`;

export const NavContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  margin: 0 ${GridVars.margin.m3};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const AppLogo = styled.div`
  height: 80px;
  padding: 27px 0;
  cursor: pointer;

  @media (max-width: ${GridVars.breakpoint.md}) {
    padding: 11px 0;
    height: unset;
  }
`;

interface NavLinkItemProps {
  readonly $isActive: boolean;
}

export const NavLinkItem = styled.div<NavLinkItemProps>`
  padding: 0 ${GridVars.padding.p3};
  display: flex;
  align-items: center;
  height: 100%;
  color: ${(props) => props.$isActive ? ThemePrimary : ThemeSecondaryText};
  border-bottom-width: 2px;
  border-bottom-color: ${(props) => props.$isActive ? ThemePrimary : "transparent"};
  border-bottom-style: solid;
  font-weight: ${(props) => (props.$isActive ? "600" : "400")};
  cursor: pointer;
  user-select: none;

  :hover {
    background: rgba(255, 255, 255, 0.1);
  }
`;

export const NavLinkBox = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  @media (max-width: ${GridVars.breakpoint.lg}) {
    margin-left: 20%;
  }

  @media (max-width: ${GridVars.breakpoint.md}) {
    display: none;
  }
`;

export const UserInfoContainer = styled.div`
  border: 1px solid ${ThemeGray100};
  border-radius: 8px;
  display: flex;
  align-items: stretch;
  cursor: pointer;

  img {
    width: 17px;
  }

  @media (max-width: ${GridVars.breakpoint.md}) {
    display: none;
  }
`;

export const ClaimableBalance = styled(Text)`
  padding: ${GridVars.padding.p1} 0;
`;

export const UserAddress = styled(TextValue)`
  font-weight: 400;
  display: flex;
  align-items: center;
  padding: ${GridVars.padding.p1};
  background: ${ThemeGray100};
  border-radius: 4px;

  span {
    margin-left: 10px;
  }
`;

export const ConnectButton = styled(Button).attrs({
  $variant: 'primary',
  $size: 'compact'
})`
  max-width: 160px;

  @media (max-width: ${GridVars.breakpoint.md}) {
    display: none;
  }
`;

export const NavbarButton = styled.div`
  color: ${ThemePrimaryText};
  font-size: 2.4rem;
  margin-left: auto;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  display: none;

  @media (max-width: ${GridVars.breakpoint.md}) {
    display: flex;
  }
`;

export const ConnectButtonMobile = styled(Button).attrs({
  $variant: 'primary',
  $size: 'compact'
})`
  margin: ${GridVars.margin.m2} auto 0 auto;
  width: 90%;
`;

export const ResponsivexportUserInfoContainer = styled(UserInfoContainer)`
  margin: ${GridVars.margin.m2} ${GridVars.margin.m1};
  display: none;

  @media (max-width: ${GridVars.breakpoint.md}) {
    display: flex;
    justify-content: space-between;
  }
`;

export const ResponsiveNavbarItem = styled(NavLinkItem)<NavLinkItemProps>`
  height: 48px;
  display: flex;
  align-items: center;
  padding-left: ${GridVars.padding.p3};
  border-left-width: 2px;
  border-left-color: ${(props) => (props.$isActive ? "#4F8BFF" : "transparent")};
  border-left-style: solid;
  border-bottom: none;

  cursor: pointer;

  :hover {
    background: rgba(255, 255, 255, 0.1);
  }
`;

export const ResponsiveNavbar = styled.div`
  margin-top: ${GridVars.margin.m4};

  ${ResponsiveNavbarItem}:not(:first-child) {
    margin-top: ${GridVars.margin.m2};
  }
`;

interface ExpandedProps {
  readonly $expand: boolean;
}

export const ExpandedMenu = styled.div<ExpandedProps>`
  display: none;
  position: fixed;
  top: ${(props) => (props.$expand ? "49px" : "0px")};
  opacity: ${(props) => (props.$expand ? "1" : "0")};
  bottom: 0px;
  right: 0px;
  left: 0px;
  background-color: red;
  z-index: ${(props) => (props.$expand ? "50" : "-50")};
  transform: ${(props) =>
    props.$expand ? "translateY(0)" : "translateY(-100%)"};
  transition: ${AnimationDuration.short} all ease-in-out;
  background: ${ThemeGray800};

  @media (max-width: ${GridVars.breakpoint.md}) {
    display: block;
  }
`;
