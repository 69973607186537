import React from "react";

import * as PopoverStyles from "../../../../../components/custom-popover/styles";

export const BorrowingCapacityPopoverPopoverElement = ({
  header,
  message,
}: PopoverElementProps) => {
  return (
    <>
      {header && <PopoverStyles.Header>{header}</PopoverStyles.Header>}
      <PopoverStyles.Paragraph>{message}</PopoverStyles.Paragraph>
    </>
  );
};

interface PopoverElementProps {
  header?: string;
  message: string;
}
