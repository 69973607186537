import * as React from "react";
import { SVGProps } from "react";
const SvgCopyIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>Copy</title>
    <path
      d="M3.692 0a2.46 2.46 0 0 0-2.461 2.462v4.307h1.23V2.462c0-.677.554-1.231 1.231-1.231h9.846c.677 0 1.231.554 1.231 1.23v9.847c0 .677-.554 1.23-1.23 1.23H9.79a2.929 2.929 0 0 1-.542 1.231h4.29A2.46 2.46 0 0 0 16 12.308V2.462A2.46 2.46 0 0 0 13.539 0H3.692ZM1.798 8A1.8 1.8 0 0 0 0 9.798v3.173c0 .99.807 1.798 1.798 1.798h5.02a1.8 1.8 0 0 0 1.797-1.798V9.798A1.8 1.8 0 0 0 6.817 8H1.798Zm0 1.23h5.02c.312 0 .567.255.567.568v3.173a.568.568 0 0 1-.568.568H1.798a.568.568 0 0 1-.567-.568V9.798c0-.313.254-.567.567-.567Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgCopyIcon;
