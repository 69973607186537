import * as React from "react";
import { SVGProps } from "react";
const SvgArrowRight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>Arrow Right</title>
    <path
      d="M1 6h11M7 1l2.5 2.5L12 6l-5 5"
      stroke="currentColor"
      strokeLinecap="round"
    />
  </svg>
);
export default SvgArrowRight;
