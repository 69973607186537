import React, { useEffect, useState } from "react";

import {
  CheckboxUnchecked,
  CheckboxChecked,
} from "../../assets/images/iconComponents";

import * as Styles from "./styles";

interface CheckboxProps {
  value?: boolean;
  onChange: (checked: boolean) => void;
}

export const Checkbox = ({ value, onChange }: CheckboxProps) => {
  const [on, setOn] = useState<boolean>(false);

  useEffect(() => {
    if (typeof value === "undefined") {
      return;
    }
    setOn(value);
  }, [value]);

  const onClick = () => {
    const nextTick = !on;
    setOn(nextTick);
    onChange(nextTick);
  };

  return (
    <Styles.CheckboxWrapper onClick={onClick}>
      {getCheckbox(on)}
    </Styles.CheckboxWrapper>
  );
};

const getCheckbox = (on: boolean) => {
  if (on) {
    return <CheckboxChecked />;
  }

  return (
    <>
      <CheckboxUnchecked />
    </>
  );
};
