import mixpanel from "mixpanel-browser";
import { isDevelopment } from "../../../utils";
import { AnalyticsInterface } from "../analytics";

export class MixpanelAnalytics implements AnalyticsInterface {
  constructor() {
    mixpanel.init('c217c7f69a1c26c94a838d60cbe3deca', {
      debug: isDevelopment(),
      track_pageview: true,
      persistence: 'localStorage'
    });
  }
  identify(id: string, data: object = {}) {
    mixpanel.identify(id);
    mixpanel.people.set({ ...data });
  }
  track(eventId: string, data: object = {}) {
    mixpanel.track(eventId, data);
  }
  page(data: object = {}) {
    mixpanel.track_pageview();
  }
}
