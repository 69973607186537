import { createAsyncThunk } from "@reduxjs/toolkit";
import * as store from "../../../store";
import { parseValue, TRANSACTION_STEPS } from "../../../utils";
import { sdk } from "../../../services/sdk-provider";

import { fetchUserVaultData } from "./fetchUserVaults";
import { VaultName } from "@altitude-fi/altitude-v1-sdk/dist/types";
import { selectVault } from "../../vault/vault.slice";

export const claimRewards = createAsyncThunk<void, any>(
  "userVault/claimRewards",
  async ({ selectedVault, claimAmount }: { selectedVault: VaultName, claimAmount: number }, thunkApi) => {

    store.userVaultSetTransactionMode(TRANSACTION_STEPS.processing);
    const { vault } = store.default.getState();
    const { borrowDecimals } = selectVault(vault, selectedVault) || {};
    try {
      await sdk.contracts.vaults[selectedVault].vault.claimRewards(parseValue(claimAmount, borrowDecimals));
    } catch (e: any) {
      return thunkApi.rejectWithValue(e);
    }

    thunkApi.dispatch(fetchUserVaultData({ selectedVault }));
  }
);
