import { createContext } from "react"
import { DoNotTrack } from "./analytics";

const defaultAnalytics = new DoNotTrack();

const createNamedContext = (name: string) => {
  const context = createContext(defaultAnalytics)
  context.displayName = name

  return context
}

const context = createNamedContext('Analytics')
export default context
