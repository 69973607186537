import normal from "../../assets/images/pin.svg";
import reversed from "../../assets/images/pin_reversed.svg";
import left from "../../assets/images/bubbles_left.png";
import right from "../../assets/images/bubbles_right.png";
import top from "../../assets/images/bubbles_top.png";

export const cssAssets = {
  pin: {
    normal,
    reversed,
  },
  bubbles: {
    left,
    right,
    top,
  },
};
