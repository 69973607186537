import React from "react";
import { useCallback, useEffect, useMemo } from "react";

import { CustomModal, TransactionStatus } from "../../components";
import { riskWarningToken } from "../../config";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { Toast } from "../../models";
import { StartLoanBanner } from "../../modules/dashboard/components";
import { RISK_APPROVAL_TIMEOUT, RiskWarningModal } from "../../modules/dashboard/modals/risk-warning/risk-warning.modal";
import { usePopupManager } from "react-popup-manager";
import { popupInstance } from "react-popup-manager/dist/src/popupsDef";
import { userVaultSetTransactionMode } from "../../store";
import { selectAllToasts } from "../../store/toast/toast.slice";
import { selectUserVault } from "../../store/userVault/userVault.slice";
import { TRANSACTION_STEPS } from "../../utils";

import BorrowingCapacity from "./components/borrowing-capacity";
import SmartRepayments from "./components/smart-repayments";
import UserLoanStats from "./components/user-loan-stats";
import UserStats from "./components/user-stats";
import VaultComposition from "./components/vault-composition/vault-composition";
import * as Styles from "./styles";

const POPUP_WINDOW_CLOSE_TIMEOUT = 3000;

let modal: popupInstance | null = null;

const Dashboard = () => {
  const dispatch = useAppDispatch();
  const popupManager = usePopupManager();

  const userVaultStore = useAppSelector((state) => state.userVault);
  const { selectedVault } = useAppSelector((state) => state.global);

  const { collateralTokenAmount }: any = selectUserVault(userVaultStore, selectedVault) || {};

  const transactionMode = useMemo(
    () => userVaultStore.transactionMode,
    [userVaultStore.transactionMode]
  );
  const transactionError: any = useMemo(
    () => userVaultStore.transactionError,
    [userVaultStore.transactionError]
  );

  const toggleModal = useCallback(
    () => dispatch(userVaultSetTransactionMode(TRANSACTION_STEPS.none)),
    [dispatch]
  );

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined
    if (transactionMode === TRANSACTION_STEPS.success) {
      timeout = setTimeout(() => {
        dispatch(userVaultSetTransactionMode(TRANSACTION_STEPS.confirm));
        toggleModal();
        popupManager.closeAll();
      }, POPUP_WINDOW_CLOSE_TIMEOUT);
    }
    return () => clearTimeout(timeout)
  }, [dispatch, transactionMode, toggleModal, popupManager]);

  useEffect(() => {
    const timeStamp = Number(localStorage.getItem(riskWarningToken));
    if ((!timeStamp || timeStamp < Date.now() - RISK_APPROVAL_TIMEOUT) && !modal) {
      modal = popupManager.open(RiskWarningModal);
    }
  }, [popupManager]);

  // TODO: Is there a better place to manage this?
  // Tracking if we should add navbar margin when menubar is in 'fixed' position.
  const toastStore = useAppSelector((state) => state.toast);
  const toasts: Array<Toast> = selectAllToasts(toastStore);
  const toastBarEnabled = useMemo(() => !!toasts.length, [toasts]);

  // Show banner if user has no collateral and loan
  const showBanner = useMemo(
    () => !isNaN(collateralTokenAmount) && collateralTokenAmount <= 0,
    [collateralTokenAmount]
  );

  return (
    <Styles.DashboardPage
      data-testid="dashboard"
      $toastBarEnabled={toastBarEnabled}
    >
      <Styles.DashboardGrid $banner={showBanner}>
        {showBanner ? <StartLoanBanner /> : <></>}
        <BorrowingCapacity />
        <UserStats />
        <UserLoanStats />
        <SmartRepayments />
        <VaultComposition />
      </Styles.DashboardGrid>

      <CustomModal
        onClose={toggleModal}
        show={transactionMode !== 0}
        testId="deposit-modal"
      >
        <TransactionStatus
          transactionStep={transactionMode}
          transactionError={transactionError || null}
        />
      </CustomModal>
    </Styles.DashboardPage>
  );
};

export default Dashboard;
