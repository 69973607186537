import styled, { keyframes } from "styled-components";
import { ThemePrimary, ThemePrimaryText } from "../../utils";

export const AnimationWrapper = styled.div`
  position: relative;
  width: 100px;
  height: 100px;
`;

export const Pie = styled.div`
  position: absolute;
  width: 50%;
  height: 50%;
  border-radius: 100% 0 0 0;
  overflow: hidden;
  background-color: ${ThemePrimary};
`;

export const Checkmark = styled.div`
  opacity: 1;

  &:before {
    content: "";
    position: absolute;
    width: 15%;
    height: 57%;
    background-color: ${ThemePrimaryText};
    transform: rotate(45deg);
    left: 53%;
    top: 25%;
  }

  &:after {
    content: "";
    position: absolute;
    width: 15%;
    height: 38%;
    background-color: ${ThemePrimaryText};
    transform: rotate(-45deg);
    left: 29%;
    top: 42%;
  }
`;

export const Cross = styled.div`
  opacity: 1;

  &:before {
    content: "";
    position: absolute;
    width: 12%;
    height: 60%;
    background-color: ${ThemePrimaryText};
    transform: rotate(45deg);
    left: 45%;
    top: 20%;
  }

  &:after {
    content: "";
    position: absolute;
    width: 12%;
    height: 60%;
    background-color: ${ThemePrimaryText};
    transform: rotate(-45deg);
    left: 45%;
    top: 20%;
  }
`;

// Loading Animation
export const LoadingAnimationPie1 = keyframes`
  0% {transform: rotate(180deg); right: 50%; bottom: 50%;}
  33% {transform: rotate(360deg); right: 0; bottom: 50%;}
  66% {transform: rotate(180deg);right: 0; bottom: 50%;}
  100% {transform: rotate(360deg); right: 0; bottom: 0%;}
`;
export const LoadingAnimationPie2 = keyframes`
  0% {transform: rotate(0deg); right: 0; bottom: 0%;}
  33% {transform: rotate(-180deg); right: 50%; bottom: 0%;}
  66% {transform: rotate(0deg); right: 50%; bottom: 0%;}
  100% {transform: rotate(-180deg); right: 50%; bottom: 50%;}
`;

export const LoadingPie1 = styled(Pie)`
  animation: ${LoadingAnimationPie1} 4s ease infinite;
`;

export const LoadingPie2 = styled(Pie)`
  animation: ${LoadingAnimationPie2} 4s ease infinite;
`;

// Success animation
export const CompletedAnimationPie1 = keyframes`
  0% {transform: rotate(180deg); right: 50%; bottom: 50%;}
  33% {transform: rotate(450deg); right: 0; bottom: 50%;}
  100% {transform: rotate(450deg); right: 0; bottom: 50%;}
`;

export const CompletedAnimationPie2 = keyframes`
0% {transform: rotate(0deg); right: 0; bottom: 0%;}
33% {transform: rotate(-90deg); right: 50%; bottom: 0%;}
100% {transform: rotate(-90deg); right: 50%; bottom: 0%;}
`;

export const CompletedAnimationPie3 = keyframes`
  0% {transform: rotate(450deg); opacity: 0 }
  10% {transform: rotate(450deg); opacity: 0}
  33% {transform: rotate(540deg);  opacity: 1 }
  100% {transform: rotate(540deg);  opacity: 1 }
`;

export const CompletedAnimationPie4 = keyframes`
  0% {transform: rotate(-90deg);  opacity: 0 }
  10% {transform: rotate(-90deg); opacity: 0}
  33% {transform: rotate(0deg);  opacity: 1 }
  100% {transform: rotate(0deg);  opacity: 1 }
`;

export const CompletedAnimationIndicator = keyframes`
  0% {opacity: 0}
  10% {opacity: 0}
  50% {opacity: 1}
  100% {opacity: 1}
`;

export const CompletedPie1 = styled(Pie)`
  transform: rotate(450deg);
  right: 0;
  bottom: 50%;
  animation: ${CompletedAnimationPie1} 3s ease;
`;

export const CompletedPie2 = styled(Pie)`
  transform: rotate(-90deg);
  right: 50%;
  bottom: 0%;
  animation: ${CompletedAnimationPie2} 3s ease;
`;

export const CompletedPie3 = styled(Pie)`
  transform: rotate(540deg);
  right: 50%;
  top: 0;
  animation: spin3 3s infinite;
  transform-origin: bottom right;
  animation: ${CompletedAnimationPie3} 3s ease;
`;

export const CompletedPie4 = styled(Pie)`
  transform: rotate(0deg);
  left: 0;
  top: 0;
  animation: spin4 3s infinite;
  transform-origin: bottom right;
  animation: ${CompletedAnimationPie4} 3s ease;
`;

export const CompletedCheckmark = styled(Checkmark)`
  animation: ${CompletedAnimationIndicator} 3s;
`;

export const CompletedCross = styled(Cross)`
  animation: ${CompletedAnimationIndicator} 3s;
`;
