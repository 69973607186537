import React from "react";
import { useState } from "react";

import * as ModalStyles from "../../../../../utils/styles/modal.styles";

type TabOption = { disabled: boolean, name: string };
type TabItem = string | TabOption;

interface ModalHeaderProps {
  tabs: Record<string, TabItem>;
  onTabChanged: (tabKey: string) => void;
}

function isTabOption(tabItem: TabItem): tabItem is TabOption {
  return (tabItem as TabOption).name !== undefined;
}

export const ModalHeader = ({ tabs, onTabChanged }: ModalHeaderProps) => {
  const firstTab = Object.values(tabs)[0];
  const defaultTab = isTabOption(firstTab) ? firstTab.name : firstTab;
  const [activeTab, setActiveTab] = useState<string>(defaultTab);

  const tabChangeHandler = (tab: string) => {
    setActiveTab(tab);
    onTabChanged(tab);
  };

  return (
    <ModalStyles.ModalHeader data-testid="modal-header">
      {Object.keys(tabs).map((tabKey) => {
        const tab = tabs[tabKey];
        const tabName = isTabOption(tab) ? tab.name : tab;
        const disabled = isTabOption(tab) ? tab.disabled : false;
        return (
          <ModalStyles.ModalHeaderTab
            data-testid={"tab-" + tabName}
            key={tabKey}
            $active={activeTab === tabName}
            $disabled={disabled}
            onClick={() => !disabled && tabChangeHandler(tabName)}
          >
            {tabName}
          </ModalStyles.ModalHeaderTab>
        )
      })}
    </ModalStyles.ModalHeader>
  );
};
