import { createAsyncThunk } from "@reduxjs/toolkit";
import { UserModel } from "../../../models";
import * as store from "../../../store";
import { selectUser } from "../../../store/user/user.slice";
import { TRANSACTION_STEPS } from "../../../utils";
import { sdk } from "../../../services/sdk-provider";

import { fetchUserVaultData } from "./fetchUserVaults";
import { VaultName } from "@altitude-fi/altitude-v1-sdk/dist/types";
import {BigNumber} from "ethers";

export const withdraw = createAsyncThunk<void, any>(
  "userVault/withdraw",
  async (
    { withdraw, selectedVault }: { withdraw: BigNumber; selectedVault: VaultName },
    thunkApi
  ) => {
    const { user } = store.default.getState();
    const { userAddress }: UserModel = selectUser(user);

    const txSendArgs: any = {
      from: userAddress,
    };

    store.userVaultSetTransactionMode(TRANSACTION_STEPS.processing);

    try {
      await sdk.contracts.vaults[selectedVault].vault.withdraw(
        withdraw,
        userAddress,
        txSendArgs
      );
    } catch (e: any) {
      return thunkApi.rejectWithValue(e);
    }

    thunkApi.dispatch(fetchUserVaultData({ selectedVault }));
  }
);
