import * as React from "react";
import { SVGProps } from "react";
const SvgMenuCollapse = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>Menu Collapse</title>
    <path
      d="m28.167 13.479-1.645-1.645L20 18.355l-6.522-6.521-1.645 1.645L18.355 20l-6.522 6.522 1.645 1.645L20 21.645l6.522 6.522 1.645-1.645L21.645 20l6.522-6.521Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgMenuCollapse;
