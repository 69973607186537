import { createRef, useState } from "react";

import { MenuDots } from "../../assets/images/iconComponents";
import { useOutside } from "../../hooks";

import * as Styles from "./style";
import { AnimationDurationMs } from "../../utils";

interface DropDownMenuProps {
  items: { label: string; disabled: boolean; callback: () => void }[];
}

export const DropDownMenu = ({ items }: DropDownMenuProps) => {
  const ref = createRef<HTMLDivElement>();
  const [isOpen, setIsOpen] = useState(false);
  const [contentVisible, setContentVisible] = useState(false);

  const toggle = () => isOpen ? handleClose() : handleOpen();

  const handleClose = () => {
    setContentVisible(false);
    setTimeout(() => {
      setIsOpen(false);
    }, AnimationDurationMs.short); // Duration of fadeOut animation
  };

  const handleOpen = () => {
    setIsOpen(true);
    setContentVisible(true);
  };

  useOutside(ref, () => handleClose());

  return (
    <div ref={ref}>
      <Styles.DropDownMenuIcon onClick={toggle}>
        <MenuDots />
      </Styles.DropDownMenuIcon>
      <Styles.DropDownMenuWrapper $show={contentVisible}>
        {items.map((item, index) => (
          <Styles.DropDownMenuItem
            disabled={item.disabled}
            key={`dropDownMenu${index}`}
            onClick={() => item.callback()}
          >
            {item.label}
          </Styles.DropDownMenuItem>
        ))}
      </Styles.DropDownMenuWrapper>
    </div>
  );
};
