import {
  createSlice,
  PayloadAction,
  createEntityAdapter,
  EntityState,
} from "@reduxjs/toolkit";
import { UserModel } from "../../models";
import { userOptInTimestamp } from "../../config";
import { OptInStatus } from "./actions";

const userAdapter = createEntityAdapter<UserModel>({
  selectId: (user) => user.userAddress,
});

let analyticsOptIn = null;
try {
  const storageItem = localStorage.getItem(userOptInTimestamp)
  analyticsOptIn = storageItem && JSON.parse(storageItem);
} catch (e) {}

const userSlice = createSlice({
  name: "user",
  initialState: { ...userAdapter.getInitialState(), userLoading: false, analyticsOptIn },
  reducers: {
    userSet(
      state,
      { payload: { value } }: PayloadAction<{ value: UserModel }>
    ) {
      userAdapter.setAll(state, [value]);
    },
    userUpsert(state, action: PayloadAction<UserModel>) {
      userAdapter.upsertOne(state, action);
    },
    userUpdate(state, action: PayloadAction<Partial<UserModel>>) {
      const currentUser = selectUser(state);
      userAdapter.updateOne(state, {
        payload: { id: currentUser.userAddress, changes: action.payload },
        type: action.type,
      });
    },
    userDelete(state) {
      userAdapter.removeAll(state);
    },
    optInUpdate(state, action: PayloadAction<OptInStatus>) {
      state.analyticsOptIn = {
        ...(state.analyticsOptIn || {}),
        ...action.payload
      }
    },
  },
});

// Selectors
const { selectAll } = userAdapter.getSelectors();
export const selectUser = (state: EntityState<UserModel>) => {
  return selectAll(state)[0];
};

export default userSlice;
