import * as React from "react";
const LogoWbtcPNG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    viewBox="0 0 500.29 500.29"
    width="30px"
    height="auto"
    {...props}
  >
    <defs>
      <style>{".cls-1{fill:#5a5564}"}</style>
    </defs>
    <title>{"wrapped-bitcoin-wbtc"}</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1-2">
        <g id="Page-1">
          <g id="wbtc_colour" data-name="wbtc colour">
            <path
              id="Shape"
              d="m416.09 107.09-14 14a198.37 198.37 0 0 1 0 267.71l14 14a218.4 218.4 0 0 0 0-296Z"
              className="cls-1"
            />
            <path
              id="Shape-2"
              d="M121.43 108.31a198.35 198.35 0 0 1 267.71 0l14-14a218.39 218.39 0 0 0-296.06 0Z"
              className="cls-1"
            />
            <path
              id="Shape-3"
              d="M108.31 389a198.34 198.34 0 0 1 0-267.57l-14-14a218.39 218.39 0 0 0 0 296.06Z"
              className="cls-1"
            />
            <path
              id="Shape-4"
              d="M389 401.89a198.35 198.35 0 0 1-267.71 0l-14 14a218.39 218.39 0 0 0 296.06 0Z"
              className="cls-1"
            />
            <path
              id="Shape-5"
              d="M343.6 208.39c-2.8-29.23-28-39-59.92-42V126.1H259v39.51h-19.69V126.1h-24.47v40.54h-50V193s18.21-.33 17.93 0a12.75 12.75 0 0 1 14 10.83v111a8.78 8.78 0 0 1-9.34 8.18c.33.28-17.93 0-17.93 0l-4.67 29.47h49.55v41.19H239v-40.59h19.71v40.4h24.71v-40.72c41.66-2.53 70.71-12.8 74.35-51.8 2.94-31.38-11.82-45.4-35.4-51 14.37-7.1 23.24-20.18 21.23-41.57ZM309 296.1c0 30.64-52.49 27.14-69.21 27.14v-54.36c16.76.04 69.21-4.77 69.21 27.22Zm-11.4-76.59c0 28-43.81 24.61-57.73 24.61v-49.41c13.92 0 57.73-4.39 57.73 24.8Z"
              style={{
                fill: "#f09242",
              }}
            />
            <path
              id="Shape-6"
              d="M255.1 510.29C114.19 510.26 0 396 0 255.1S114.28 0 255.19 0s255.07 114.22 255.1 255.1-114.19 255.16-255.1 255.19Zm0-490.39c-129.85.1-235 105.44-234.92 235.29s105.44 235 235.29 234.92 234.87-105.28 234.92-235S385.22 20 255.38 19.9Z"
              style={{
                fill: "#282138",
              }}
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default LogoWbtcPNG;
