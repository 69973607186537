import numbro from "numbro";

interface PercentageFormatterProps {
  percentage: number;
  fractionDigits?: number;
}

const PercentageFormatter = ({
  percentage,
  fractionDigits = 1,
}: PercentageFormatterProps) => {
  if (isNaN(percentage)) {
    return <>0.00</>;
  }
  if (!isFinite(percentage)) {
    return <>&infin;</>
  }

  percentage = percentage * 100;
  return numbro(percentage).format({
    average: true,
    mantissa: fractionDigits
  });
};

export default PercentageFormatter;
